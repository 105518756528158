var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { EventStatusType } from '../definitions/constants/event-status-type.constant';
var FinishedStatus = (_a = {},
    _a[EventStatusType.fullTime] = true,
    _a[EventStatusType.finishedWithExtraTime] = true,
    _a[EventStatusType.finishedWithPenalty] = true,
    _a[EventStatusType.cancelled] = true,
    _a[EventStatusType.postponed] = true,
    _a);
var CancelAndPostponed = (_b = {},
    _b[EventStatusType.cancelled] = true,
    _b[EventStatusType.postponed] = true,
    _b);
var BasketballFirstHalfStatus = (_c = {},
    _c[EventStatusType.firstPeriod] = true,
    _c[EventStatusType.secondPeriod] = true,
    _c);
var BasketballSecondHalfStatus = (_d = {},
    _d[EventStatusType.thirdPeriod] = true,
    _d[EventStatusType.fourthPeriod] = true,
    _d);
var FinishedExtraTimeStatus = (_e = {},
    _e[EventStatusType.finishedWithExtraTime] = true,
    _e[EventStatusType.finishedWithPenalty] = true,
    _e);
var ExtraTimeStopStatus = (_f = {},
    _f[EventStatusType.extraTimeHalfTime] = true,
    _f[EventStatusType.extraTimeToBegin] = true,
    _f[EventStatusType.penaltiesToBegin] = true,
    _f);
var StopStatus = Object.assign((_g = {},
    _g[EventStatusType.halfTime] = true,
    _g[EventStatusType.stopped] = true,
    _g), ExtraTimeStopStatus);
var ContinuesStatus = (_h = {},
    _h[EventStatusType.firstHalf] = true,
    _h[EventStatusType.secondHalf] = true,
    _h[EventStatusType.extraTimeFirstHalf] = true,
    _h[EventStatusType.extraTimeSecondHalf] = true,
    _h[EventStatusType.penalties] = true,
    _h[EventStatusType.firstPeriod] = true,
    _h[EventStatusType.secondPeriod] = true,
    _h[EventStatusType.thirdPeriod] = true,
    _h[EventStatusType.fourthPeriod] = true,
    _h[EventStatusType.thirdExtraTime] = true,
    _h[EventStatusType.fourthExtraTime] = true,
    _h[EventStatusType.fifthExtraTime] = true,
    _h[EventStatusType.sixthExtraTime] = true,
    _h[EventStatusType.seventhExtraTime] = true,
    _h[EventStatusType.eighthExtraTime] = true,
    _h[EventStatusType.ninthExtraTime] = true,
    _h[EventStatusType.continues] = true,
    _h[EventStatusType.firstSet] = true,
    _h[EventStatusType.secondSet] = true,
    _h[EventStatusType.thirdSet] = true,
    _h[EventStatusType.fourthSet] = true,
    _h[EventStatusType.fifthSet] = true,
    _h[EventStatusType.fifthPeriod] = true,
    _h[EventStatusType.sixthPeriod] = true,
    _h[EventStatusType.seventhPeriod] = true,
    _h[EventStatusType.eighthPeriod] = true,
    _h[EventStatusType.ninthPeriod] = true,
    _h);
var TimerStatus = (_j = {},
    _j[EventStatusType.firstHalf] = true,
    _j[EventStatusType.secondHalf] = true,
    _j[EventStatusType.extraTimeFirstHalf] = true,
    _j[EventStatusType.extraTimeSecondHalf] = true,
    _j[EventStatusType.thirdExtraTime] = true,
    _j[EventStatusType.fourthExtraTime] = true,
    _j[EventStatusType.fifthExtraTime] = true,
    _j[EventStatusType.sixthExtraTime] = true,
    _j[EventStatusType.seventhExtraTime] = true,
    _j[EventStatusType.eighthExtraTime] = true,
    _j[EventStatusType.ninthExtraTime] = true,
    _j);
var ContinuesExtraTimeStatus = (_k = {},
    _k[EventStatusType.extraTimeFirstHalf] = true,
    _k[EventStatusType.extraTimeSecondHalf] = true,
    _k[EventStatusType.thirdExtraTime] = true,
    _k[EventStatusType.fourthExtraTime] = true,
    _k[EventStatusType.fifthExtraTime] = true,
    _k[EventStatusType.sixthExtraTime] = true,
    _k[EventStatusType.seventhExtraTime] = true,
    _k[EventStatusType.eighthExtraTime] = true,
    _k[EventStatusType.ninthExtraTime] = true,
    _k[EventStatusType.penalties] = true,
    _k);
var ExtraTimeStatus = Object.assign(ExtraTimeStopStatus, ContinuesExtraTimeStatus);
var EventStatusManager = /** @class */ (function () {
    function EventStatusManager() {
    }
    EventStatusManager.checkIsNotStarted = function (statusId) {
        return Object.is(EventStatusType.scheduled, statusId);
    };
    EventStatusManager.checkIsCancelled = function (statusId) {
        return Object.is(EventStatusType.cancelled, statusId);
    };
    EventStatusManager.checkIsPostponed = function (statusId) {
        return Object.is(EventStatusType.postponed, statusId);
    };
    EventStatusManager.checkIsFinished = function (statusId) {
        return FinishedStatus.hasOwnProperty(statusId);
    };
    EventStatusManager.checkIsFinishedExtraTime = function (statusId) {
        return FinishedExtraTimeStatus.hasOwnProperty(statusId);
    };
    EventStatusManager.checkIsStopped = function (statusId) {
        return StopStatus.hasOwnProperty(statusId);
    };
    EventStatusManager.checkIsOnlyStopped = function (statusId) {
        return Object.is(EventStatusType.stopped, statusId);
    };
    EventStatusManager.checkIsExtraTimeStopped = function (statusId) {
        return ExtraTimeStopStatus.hasOwnProperty(statusId);
    };
    EventStatusManager.checkIsContinued = function (statusId) {
        return ContinuesStatus.hasOwnProperty(statusId);
    };
    EventStatusManager.checkIsLive = function (statusId) {
        return (this.checkIsContinued(statusId) || this.checkIsStopped(statusId));
    };
    EventStatusManager.checkIsFirstHalf = function (statusId) {
        return Object.is(EventStatusType.firstHalf, statusId);
    };
    EventStatusManager.checkIsHalfTime = function (statusId) {
        return Object.is(EventStatusType.halfTime, statusId);
    };
    EventStatusManager.checkIsExtraTimeHalfTime = function (statusId) {
        return Object.is(EventStatusType.extraTimeHalfTime, statusId);
    };
    EventStatusManager.checkIsSecondHalf = function (statusId) {
        return Object.is(EventStatusType.secondHalf, statusId);
    };
    EventStatusManager.checkIsExtraTimeFirstHalf = function (statusId) {
        return Object.is(EventStatusType.extraTimeFirstHalf, statusId);
    };
    EventStatusManager.checkIsExtraTimeSecondHalf = function (statusId) {
        return Object.is(EventStatusType.extraTimeSecondHalf, statusId);
    };
    EventStatusManager.checkIsExtraTimeThirdHalf = function (statusId) {
        return Object.is(EventStatusType.thirdExtraTime, statusId);
    };
    EventStatusManager.checkIsExtraTimeFourthHalf = function (statusId) {
        return Object.is(EventStatusType.fourthExtraTime, statusId);
    };
    EventStatusManager.checkIsExtraTimeFifthHalf = function (statusId) {
        return Object.is(EventStatusType.fifthExtraTime, statusId);
    };
    EventStatusManager.checkIsExtraTimeSixthHalf = function (statusId) {
        return Object.is(EventStatusType.sixthExtraTime, statusId);
    };
    EventStatusManager.checkIsExtraTimeSeventhHalf = function (statusId) {
        return Object.is(EventStatusType.seventhExtraTime, statusId);
    };
    EventStatusManager.checkIsExtraTimeEighthHalf = function (statusId) {
        return Object.is(EventStatusType.eighthExtraTime, statusId);
    };
    EventStatusManager.checkIsExtraTimeNinethHalf = function (statusId) {
        return Object.is(EventStatusType.ninthExtraTime, statusId);
    };
    EventStatusManager.checkIsPenalty = function (statusId) {
        return Object.is(EventStatusType.penalties, statusId);
    };
    EventStatusManager.checkIsPenaltyToBegin = function (statusId) {
        return Object.is(EventStatusType.penaltiesToBegin, statusId);
    };
    EventStatusManager.checkIsContinuedExtraTime = function (statusId) {
        // @ts-ignore
        return ContinuesExtraTimeStatus.hasOwnProperty(statusId);
    };
    EventStatusManager.checkIsContinuedOnlyExtraTime = function (statusId) {
        // @ts-ignore
        return (!this.checkIsPenalty(statusId) && ContinuesExtraTimeStatus.hasOwnProperty(statusId));
    };
    EventStatusManager.checkIsExtraTime = function (statusId) {
        return ExtraTimeStatus.hasOwnProperty(statusId);
    };
    EventStatusManager.checkIsOnlyExtraTime = function (statusId) {
        return (!this.checkIsPenalty(statusId) && ExtraTimeStatus.hasOwnProperty(statusId));
    };
    EventStatusManager.checkIsTimer = function (statusId) {
        // @ts-ignore
        return TimerStatus.hasOwnProperty(statusId);
    };
    EventStatusManager.checkIsFinishedFirstHalf = function (statusId) {
        return (!this.checkIsFirstHalf(statusId) && !this.checkIsBasketballFirstHalf(statusId) && (this.checkIsContinued(statusId) || this.checkIsHalfTime(statusId) || this.checkIsFinished(statusId) || this.checkIsExtraTime(statusId)));
    };
    EventStatusManager.checkIsFinishedSecondHalf = function (statusId) {
        return (this.checkIsFinished(statusId) || this.checkIsExtraTime(statusId));
    };
    EventStatusManager.checkIsFinishedExtraTimeFirstHalf = function (statusId) {
        return (this.checkIsFinished(statusId) || this.checkIsExtraTimeHalfTime(statusId) || this.checkIsExtraTimeSecondHalf(statusId) || this.checkIsPenaltyToBegin(statusId) || this.checkIsPenalty(statusId));
    };
    EventStatusManager.checkIsFinishedOnlyExtraTime = function (statusId) {
        return (this.checkIsFinished(statusId) || this.checkIsPenaltyToBegin(statusId) || this.checkIsPenalty(statusId));
    };
    EventStatusManager.checkIsFinishedPenalty = function (statusId) {
        return Object.is(EventStatusType.finishedWithPenalty, statusId);
    };
    EventStatusManager.checkIsExtraTimeToBegin = function (statusId) {
        return Object.is(EventStatusType.extraTimeToBegin, statusId);
    };
    EventStatusManager.checkIsCancelAndPostponed = function (statusId) {
        return CancelAndPostponed.hasOwnProperty(statusId);
    };
    //Basketball
    EventStatusManager.checkIsBasketballFirstHalf = function (statusId) {
        return BasketballFirstHalfStatus.hasOwnProperty(statusId);
    };
    EventStatusManager.checkIsBasketballSecondHalf = function (statusId) {
        return BasketballSecondHalfStatus.hasOwnProperty(statusId);
    };
    EventStatusManager.checkIsFirstPeriod = function (statusId) {
        return Object.is(EventStatusType.firstPeriod, statusId);
    };
    EventStatusManager.checkIsSecondPeriod = function (statusId) {
        return Object.is(EventStatusType.secondPeriod, statusId);
    };
    EventStatusManager.checkIsThirdPeriod = function (statusId) {
        return Object.is(EventStatusType.thirdPeriod, statusId);
    };
    EventStatusManager.checkIsFourthPeriod = function (statusId) {
        return Object.is(EventStatusType.fourthPeriod, statusId);
    };
    //volleyball
    EventStatusManager.checkIsFirstSet = function (statusId) {
        return Object.is(EventStatusType.firstSet, statusId);
    };
    EventStatusManager.checkIsSecondSet = function (statusId) {
        return Object.is(EventStatusType.secondSet, statusId);
    };
    EventStatusManager.checkIsThirdSet = function (statusId) {
        return Object.is(EventStatusType.thirdSet, statusId);
    };
    EventStatusManager.checkIsFourthSet = function (statusId) {
        return Object.is(EventStatusType.fourthSet, statusId);
    };
    EventStatusManager.checkIsFifthSet = function (statusId) {
        return Object.is(EventStatusType.fifthSet, statusId);
    };
    return EventStatusManager;
}());
export default EventStatusManager;
