import { ApiService } from '../../lib/http.service';
import { env } from "../../definitions/global.vars";
export var Api = new ApiService();
export var AnnouncementsApi = (function () {
    return {
        announcements: function () {
            return Api.start('get', env.misc_api + '/misc/api/news/', null, false);
        },
    };
})();
