var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { myHighLightedMarketGroups } from "../../../services/sportsbook.services";
import Carousel from "react-elastic-carousel";
import { isMobileView } from "../../../app";
import { highlightedEvents } from "../../../store/sportsbook.store";
import { dateTimeFormat } from "../../../lib/misc.functions";
import { HighlightedMarketsCard } from "../../sportsbook/components/highlightedmarkets";
import { useNavigate } from "react-router-dom";
export default function HighLightedEvents() {
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var navigate = useNavigate();
    var base = '/' + i18n.resolvedLanguage;
    // @ts-ignore
    var marketGroup = myHighLightedMarketGroups['futbol'];
    var carouselRef = useRef(null);
    var _b = useState(0), sliderIndex = _b[0], setSliderIndex = _b[1];
    var breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 520, itemsToShow: 1 },
        { width: 750, itemsToShow: 3 },
        { width: 1200, itemsToShow: 3 },
    ];
    console.log(highlightedEvents);
    return (_jsx("div", __assign({ className: "hm-row-bc", style: { gridTemplateColumns: "12fr" } }, { children: _jsxs("div", __assign({ className: "ftr-g-content-bc " }, { children: [_jsx("div", __assign({ className: "page-view-more" }, { children: _jsx("h3", __assign({ className: "view-title-more ellipsis" }, { children: t('home.highlightedEvents.title') }), void 0) }), void 0), _jsx("div", { children: _jsxs("div", __assign({ className: "carousel" }, { children: [_jsx("div", __assign({ className: "carousel-viewport" }, { children: _jsx(Carousel, __assign({ ref: carouselRef, autoPlaySpeed: 5000, isRTL: false, enableAutoPlay: true, itemsToShow: isMobileView ? 1 : 3, itemsToScroll: isMobileView ? 1 : 3, onChange: function (currentItem, pageIndex) {
                                        setSliderIndex(pageIndex);
                                    }, pagination: false, showArrows: false }, { children: highlightedEvents.map(function (event, key) {
                                        return _jsxs("div", __assign({ style: { width: "100%" }, className: "ftr-game-item-bc Soccer" }, { children: [_jsxs("div", __assign({ className: "ftr-g-i-head-bc" }, { children: [_jsx("i", { className: "ftr-g-icon-bc bc-i-prematch" }, void 0), _jsx("div", __assign({ className: "ftr-g-i-b-time-block" }, { children: _jsx("span", __assign({ className: "ftr-g-i-b-time-bc" }, { children: _jsxs("time", __assign({ className: "" }, { children: [" ", dateTimeFormat(event.eventDate)] }), void 0) }), void 0) }), void 0), _jsxs("span", __assign({ className: "ftr-g-icon-bc" }, { children: ["+", event.marketOutcomeCount] }), void 0), _jsx("i", { className: "ftr-g-icon-bc bc-i-favorite " }, void 0)] }), void 0), _jsxs("div", __assign({ className: "ftr-g-i-body-bc", onClick: function () {
                                                        navigate(base + '/sports/' + event.sportSlug + '/tumu/' + event.competitionId + '/' + event.eventId);
                                                    } }, { children: [_jsxs("div", __assign({ className: "ftr-g-title-bc" }, { children: [_jsx("i", { className: "ftr-g-i-type-icon-bc bc-i-".concat(event.sportSlug) }, void 0), _jsx("i", { className: "ftr-flag-bc flag-bc ".concat(event.competitionGroupName === "IIIskoçya" ? 'scotland' : event.countryName.toLowerCase()) }, void 0), _jsx("span", __assign({ className: "ftr-g-i-title-bc ellipsis" }, { children: event.competitionName }), void 0)] }), void 0), _jsxs("div", __assign({ className: "ftr-g-i-b-opp-t-holder-bc" }, { children: [_jsxs("div", __assign({ className: "ftr-g-i-b-opp-title-bc" }, { children: [_jsx("img", { className: "ftr-g-i-b-opp-logo-bc", src: "https://statistics.bcapps.org/images/e/s/0/944.png", alt: "" }, void 0), _jsxs("span", { children: [event.eventName.split('-')[0], " "] }, void 0)] }), void 0), _jsxs("div", __assign({ className: "ftr-g-i-b-opp-title-bc" }, { children: [_jsx("img", { className: "ftr-g-i-b-opp-logo-bc", src: "https://statistics.bcapps.org/images/e/s/0/396.png", alt: "" }, void 0), _jsx("span", { children: event.eventName.split('-')[1] }, void 0)] }), void 0)] }), void 0)] }), void 0), _jsx(HighlightedMarketsCard, { event: event, highlightedMarkets: event.highlightedMarkets }, void 0)] }), void 0);
                                    }) }), void 0) }), void 0), _jsxs("div", __assign({ className: "carousel-count-arrow-container with-count" }, { children: [_jsx("i", { className: "carousel-arrow-left bc-i-small-arrow-left ", onClick: function () {
                                            carouselRef.current.slidePrev();
                                        } }, void 0), _jsxs("div", __assign({ className: "carousel-count-arrow" }, { children: [sliderIndex + 1, " / ", Math.ceil(highlightedEvents.length / 3)] }), void 0), _jsx("i", { className: "carousel-arrow-right bc-i-small-arrow-right ", onClick: function () {
                                            carouselRef.current.slideNext();
                                        } }, void 0)] }), void 0)] }), void 0) }, void 0)] }), void 0) }), void 0));
    /*return (
        <div className="widget highlighted card ml-1 mr-1">
          <div className="header">
            <h2>
              Öne Çıkan Karşılaşmalar
            </h2>
          </div>
          <div className="body">
            <div className="event-card list header hidden-wd">
              <div className="event-card ">
                <div className="participants-labels fw-bold fs-small  hidden-sm hidden-detail">
                  Karşılaşma Adı
                </div>
                <div className="highlighted-odds" style={{marginTop: 0}}>
                  <div style={{display: 'flex', marginRight: '50px'}}>
                    {marketGroup.map((el: any, key: number) => {
                      return (
                          <div key={`highlighted-${key}`}
                               className={"group " + (key === 2 ? 'last-group hidden-detail' : (key === 1 ? 'middle-group' : ''))}>
                            <div className="text-center p-0 w-100">{el.name}</div>
                          </div>)
                    })}

                  </div>
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="clearfix"></div>
            </div>

            {highlightedEvents.map((event: SportEvent, key: any) => {
              return (<div key={`highlightedEvents-${key}-${event.eventId}`}>
                <div className={"event-card list " + ((key %= 2) === 0 ? ' dark ' : '')}>
                  <Event className="event-card" fromOutSource={true} event={event} eventType={'tumu'}></Event>
                </div>
              </div>)
            })}

          </div>
        </div>
    )*/
}
