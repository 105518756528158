import { ApiService } from '../../../../lib/http.service';
import { env } from "../../../../definitions/global.vars";
export var Api = new ApiService();
export var SupportApi = (function () {
    return {
        supports: function (request) {
            return Api.start('post', env.misc_api + '/support/api/support-tickets/', request, true);
        },
        supportDetail: function (request) {
            return Api.start('post', env.misc_api + '/support/api/support-tickets/', request, true);
        },
        supportDetailResponse: function (request) {
            return Api.start('post', env.misc_api + '/support/api/support-ticket-responses/', request, true);
        }
    };
})();
