var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Storage } from "../../../../lib/localstorege.service";
import { member } from "../../../../store/login.store";
import { Link, useNavigate } from "react-router-dom";
import { dateTimeFormat, initialLeftMenu, sportsMenu } from "../../../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../../../store/betslip.store";
import ArrowRight from "../../../../assets/feather/arrow-right.svg";
import { Api } from "../gameOperations/game.operations.api";
import { env } from "../../../../definitions/global.vars";
import { modalServiceSubject } from "../../../../components/modal/modal";
import { Subject } from "rxjs";
import SupportDetail from "../../widgets/modal/support.detail";
import { useTranslation } from "react-i18next";
export var supportDetailSubject = new Subject();
var supportStatus = 0;
var paramStates = {
    "beklemede": 0,
    "isleme-alinan": 1,
    "sonuclanan": 3
};
var states = {
    0: 'beklemede',
    1: 'işleme alınan',
    3: 'sonuçlanan'
};
export default function SupportRequests(props) {
    var navigate = useNavigate();
    var params = props.params;
    var i18n = useTranslation().i18n;
    var base = '/' + i18n.resolvedLanguage;
    supportStatus = paramStates[params.couponId];
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    return (_jsxs("div", __assign({ className: "widget card tickets p-3" }, { children: [_jsxs("div", __assign({ className: "header" }, { children: [_jsxs("div", { children: [(member) ? (_jsx(Link, __assign({ to: "/bize-yazin", className: "btn btn-small btn-success float-right" }, { children: "Destek Talebi Olu\u015Ftur" }), void 0)) : null, _jsx("h2", { children: "Destek Taleplerim" }, void 0)] }, void 0), _jsxs("ul", __assign({ className: "tabs clearfix" }, { children: [_jsx("li", __assign({ className: "cursor-pointer tab " + (supportStatus === 0 ? 'active' : ''), onClick: function () { return navigate(base + '/hesabim/destek-taleplerim/beklemede'); } }, { children: "Beklemede" }), void 0), _jsx("li", __assign({ className: "cursor-pointer tab " + (supportStatus === 1 ? 'active' : ''), onClick: function () { return navigate(base + '/hesabim/destek-taleplerim/isleme-alinan'); } }, { children: "\u0130\u015Fleme Al\u0131nan" }), void 0), _jsx("li", __assign({ className: "cursor-pointer tab " + (supportStatus === 3 ? 'active' : ''), onClick: function () { return navigate(base + '/hesabim/destek-taleplerim/sonuclanan'); } }, { children: "Sonu\u00E7lanan" }), void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "body" }, { children: _jsxs("div", { children: [_jsx(ListSupport, { params: params }, void 0), _jsx(SupportDetail, {}, void 0)] }, void 0) }), void 0)] }), void 0));
}
export function ListSupport(props) {
    var params = props.params;
    supportStatus = paramStates[params.couponId];
    var _a = useState([]), supportData = _a[0], setSupportData = _a[1];
    var openCouponDetail = function (data) {
        supportDetailSubject.next(data);
    };
    var fetchData = function () {
        var data = {
            token: Storage.get('token'),
            status: supportStatus
        };
        Api.start('post', env.misc_api + '/support/api/support-tickets/', data, true).then(function (result) {
            if (result.status) {
                setSupportData(result.data);
                // supportData = supportData.concat(result.data);
            }
            else {
                modalServiceSubject.next({
                    title: 'beklenmedik bir sorun',
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 3000,
                });
            }
        });
    };
    useEffect(function () {
        fetchData();
    }, [params.couponId]);
    var Title = function () {
        return (_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "text-center" }, { children: "Ticket Id" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Tarih" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Konu" }), void 0), _jsx("th", __assign({ className: "text-center hidden-sm" }, { children: "Durum" }), void 0), _jsx("th", __assign({ className: "text-center hidden-sm" }, { children: "Detay" }), void 0)] }, void 0) }, void 0));
    };
    return (_jsxs(_Fragment, { children: [_jsxs("table", __assign({ className: "table" }, { children: [_jsx(Title, {}, void 0), _jsx("tbody", { children: supportData.map(function (data, key) {
                            return (_jsxs("tr", __assign({ className: "cursor-pointer", onClick: function () {
                                    openCouponDetail(data);
                                } }, { children: [_jsxs("td", __assign({ className: "text-center" }, { children: ["#", data.id] }), void 0), _jsx("td", __assign({ className: "text-center" }, { children: dateTimeFormat(data.create_date) }), void 0), _jsx("td", __assign({ className: "text-center" }, { children: data.get_subject_display }), void 0), _jsx("td", __assign({ className: "text-center hidden-sm" }, { children: data.get_subject_display }), void 0), _jsx("td", __assign({ className: "text-center hidden-sm" }, { children: _jsx("img", { src: ArrowRight, width: 16 }, void 0) }), void 0)] }), "accountSupport" + key));
                        }) }, void 0)] }), void 0), supportData.length == 0 ? _jsx(_Fragment, { children: _jsxs("p", __assign({ className: "text-center py-5" }, { children: ["Listelenecek ", states[supportStatus], " destek talebiniz yoktur."] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }, void 0));
}
