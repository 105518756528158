var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Storage } from "../../../../lib/localstorege.service";
import { useNavigate, useParams } from "react-router-dom";
import { dateTimeFormat, initialLeftMenu, sportsMenu } from "../../../../lib/misc.functions";
import { SupportApi } from './support.api';
import { Link } from "react-router-dom";
import { betSlipVisibleSubject } from "../../../../store/betslip.store";
import ExitIcon from "../../../../assets/feather/x-circle.svg";
import { modalServiceSubject } from '../../../../components/modal/modal';
import { useTranslation } from "react-i18next";
var SupportDetail = function () {
    var token = Storage.get('token');
    var _a = useState(null), coupon = _a[0], setCoupon = _a[1];
    var _b = useState(null), couponResponse = _b[0], setCouponResponse = _b[1];
    var params = useParams();
    var navigate = useNavigate();
    var i18n = useTranslation().i18n;
    var base = '/' + i18n.resolvedLanguage;
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    var getCouponDetail = function () {
        var data = {
            token: token,
            id: params.couponId,
        };
        SupportApi.supportDetail(data).then(function (result) {
            if (result.status) {
                setCoupon(result.data[0]);
            }
            else {
                modalServiceSubject.next({
                    title: "Hata!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
        supportResponse();
    };
    useEffect(function () {
        getCouponDetail();
    }, []);
    if (!coupon) {
        return _jsx(_Fragment, {}, void 0);
    }
    function supportResponse() {
        var data = {
            token: token,
            ticket: params.couponId,
            ordering: 'create_date'
        };
        SupportApi.supportDetailResponse(data).then(function (result) {
            if (result.status) {
                setCouponResponse(result.data[0]);
            }
            else {
                modalServiceSubject.next({
                    title: "Hata!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    }
    return (_jsxs("div", __assign({ className: "card" }, { children: [_jsxs("div", __assign({ className: "card-title pl-md-4 pl-3" }, { children: [_jsxs("h3", { children: [coupon.id, " Numaral\u0131 Destek Talebi"] }, void 0), _jsx("img", { src: ExitIcon, width: 16, className: "float-right invert-color", onClick: function () { return navigate(base + '/hesabim/destek-taleplerim'); } }, void 0)] }), void 0), _jsxs("div", __assign({ className: "card-body pt-0" }, { children: [_jsxs("h4", __assign({ className: "justify-content-start pl-1" }, { children: ["Konu: ", coupon.get_subject_display] }), void 0), _jsxs("div", __assign({ className: "coupon mt-0" }, { children: [_jsx("div", __assign({ className: "header" }, { children: _jsxs("div", __assign({ className: "desc w-100" }, { children: [_jsxs("div", __assign({ className: "font-weight-bold" }, { children: ["~ ", coupon.display_label.split(":")[0]] }), void 0), _jsxs("div", { children: [" ", coupon.message, " "] }, void 0), _jsx("div", __assign({ className: "approve-date float-right" }, { children: dateTimeFormat(coupon.create_date) }), void 0)] }), void 0) }), void 0), couponResponse ? (_jsx("div", __assign({ className: "header" }, { children: _jsxs("div", __assign({ className: "desc my-2 w-100" }, { children: [_jsx("div", __assign({ className: "font-weight-bold" }, { children: "~ x.com" }), void 0), _jsx("div", { dangerouslySetInnerHTML: { __html: couponResponse.message } }, void 0), _jsx("div", __assign({ className: "approve-date float-right" }, { children: dateTimeFormat(couponResponse.create_date) }), void 0)] }), void 0) }), void 0)) : ('')] }), void 0), _jsxs("div", { children: [_jsx("br", {}, void 0), _jsx(Link, __assign({ to: "/bize-yazin", className: "btn btn-small btn-primary float-right" }, { children: "Destek Talebi Olu\u015Ftur" }), void 0)] }, void 0)] }), void 0)] }), void 0));
};
export default SupportDetail;
