import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import promiseMiddleware from '../app/promiseMiddleware';
import rootReducer from './reducers/root.reducer';
// Store
var store = createStore(rootReducer, composeWithDevTools(applyMiddleware(promiseMiddleware)));
// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept('./reducers/root.reducer', function () {
        store.replaceReducer(require('./reducers/root.reducer').default);
    });
}
export default store;
