var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { NotificationsApi } from './notifications.api';
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { Storage } from "../../lib/localstorege.service";
import { dateTimeFormat } from "../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { useNavigate } from "react-router-dom";
import { modalServiceSubject } from '../../components/modal/modal';
import { useTranslation } from "react-i18next";
var Title = function () {
    return (_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "text-center" }, { children: "Tarih" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Ba\u015Fl\u0131k" }), void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Sil" }), void 0)] }, void 0) }, void 0));
};
export var notificationsDetailMap = [];
var Notification = function () {
    var _a = useState([]), notificationData = _a[0], setNotificationData = _a[1];
    var token = Storage.get('token');
    var data = { token: token };
    var navigate = useNavigate();
    var i18n = useTranslation().i18n;
    var base = '/' + i18n.resolvedLanguage;
    useEffect(function () {
        if (innerWidth > 968) {
            betSlipVisibleSubject.next(true);
        }
        return function () {
            betSlipVisibleSubject.next(false);
        };
    }, []);
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        NotificationsApi.get_notification(data).then(function (result) {
            if (result.status) {
                setNotificationData((result.data).reverse());
                notificationsDetailMap = result.data;
            }
            else {
                modalServiceSubject.next({
                    title: "Hata!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    }, []);
    function deleteNotification(id) {
        var data = {
            token: token,
            nofication_id: id
        };
        NotificationsApi.delete_notification(data).then(function (result) {
            if (result.status) {
                var element = document.getElementById(id);
                element.style.display = "none";
            }
            else {
                modalServiceSubject.next({
                    title: "Hata!",
                    content: result.message,
                    timeout: 0
                });
            }
        });
    }
    function detail(id) {
        navigate(base + '/bildirimler/' + id);
    }
    return (_jsx("div", __assign({ className: "container-fluid page pl-lg-3 pl-md-3 pr-md-5 pl-sm-0 pr-sm-0" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "right-column p-md-0 p-sm-1" }, { children: _jsxs("div", __assign({ className: "card mt-sm-0" }, { children: [_jsx("div", __assign({ className: "card-title mb-1" }, { children: _jsx("h2", { children: "Kullan\u0131c\u0131 Bildirimleri" }, void 0) }), void 0), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsxs("table", __assign({ className: "table" }, { children: [_jsx(Title, {}, void 0), _jsx("tbody", { children: notificationData.map(function (data, key) {
                                                return (_jsxs("tr", __assign({ id: data._id, className: "cursor-pointer" }, { children: [_jsx("td", __assign({ className: "text-center" }, { children: dateTimeFormat(data.create_date) }), void 0), _jsxs("td", __assign({ className: "text-center" + data.seen_date ? "font-weight-light" : "font-weight-bold", onClick: function () { return detail(data._id); } }, { children: [data.title, " "] }), void 0), _jsx("td", __assign({ className: "text-center delete-button", onClick: function () { return deleteNotification(data._id); } }, { children: "Sil" }), void 0)] }), "notificationData" + key));
                                            }) }, void 0)] }), void 0), notificationData.length === 0 ? _jsx(_Fragment, { children: _jsx("p", __assign({ className: "text-center py-5" }, { children: "Hi\u00E7 Bildiriminiz Bulunmamaktad\u0131r." }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0));
};
export default Notification;
