var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { sportsbookList, sportsSummery, } from '../../store/sportsbook.store';
import Slider from "./widgets/slider";
import { sportsMenu, initialLeftMenu, dateTimeFormat, useForceUpdate, } from "../../lib/misc.functions";
import "./home.css";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import HighLightedEvents from "./widgets/highlighted.events";
import { useNavigate } from "react-router-dom";
import useSpeechToText from 'react-hook-speech-to-text';
import _ from "lodash";
import { modalServiceSubject } from "../../components/modal/modal";
import { useAuthContext } from "../../store/login.store";
import { setFilterCompetitions } from "../../services/sportsbook.services";
import { useTranslation } from "react-i18next";
import { gameList, gamesUpdateSubject } from "../../store/games.store";
export function SportsList() {
    var openPage = window.location.href;
    var forceUpdate = useForceUpdate();
    var isAuthenticated = useAuthContext().isAuthenticated;
    var navigate = useNavigate();
    var width = window.innerWidth;
    var i18n = useTranslation().i18n;
    var base = '/' + i18n.resolvedLanguage;
    var goToUrl = function (sportSlug, eventType) {
        if (eventType === '2') {
            navigate(base + "/sports/" + sportSlug + '/uzunvadeli');
            // document.body.style.overflow = 'unset';
        }
        else {
            navigate(base + "/sports/" + sportSlug + '/tumu');
            // document.body.style.overflow = 'unset';
        }
    };
    var isActive = function (sportSlug, eventType) {
        if (window.location.pathname.indexOf(sportSlug) !== -1) {
            if (eventType === '2' && window.location.pathname.indexOf('uzunvadeli') !== -1) {
                return 'active';
            }
            if (eventType === '1' && (window.location.pathname.indexOf('tumu') !== -1 || window.location.pathname.indexOf('canli') !== -1)) {
                return 'active';
            }
        }
        return '';
    };
    return _jsx(_Fragment, { children: _jsx("ul", __assign({ className: "SportListHorizontal" }, { children: sportsSummery.map(function (item, key) {
                return (_jsxs("li", __assign({ onClick: function () {
                        goToUrl(item.sportSlug, item.eventType);
                    }, className: isActive(item.sportSlug, item.eventType) }, { children: [item.activeGame || item.eventType === '2' ? _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "badge nowrap" }, { children: [item.eventType === '2' ? 'UV' : '', item.eventType !== '2' ? _jsxs(_Fragment, { children: [_jsx("i", { className: "".concat(item.activeGame ? 'hasActiveGame' : '') }, void 0), item.liveEventCount] }, void 0) : _jsx(_Fragment, {}, void 0)] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", { className: "icon game-event-icon lg ".concat(item.sportSlug) }, void 0), _jsxs("div", __assign({ className: "label" }, { children: [item.sportName.replace('Hokeyi', 'Hok.').replace('Sporları', 'Spr.'), _jsxs("span", { children: [" ", item.count] }, void 0)] }), void 0)] }), key));
            }) }), void 0) }, void 0);
}
var Home = function () {
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var base = '/' + i18n.resolvedLanguage;
    var width = window.innerWidth;
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        initialLeftMenu(true);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    useEffect(function () {
        var gamesUpdateSubject_subscription = gamesUpdateSubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            gamesUpdateSubject_subscription.unsubscribe();
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Slider, { sliderType: 0 }, void 0), _jsx(HighLightedEvents, {}, void 0), _jsx("div", __assign({ className: "hm-row-bc", style: { gridTemplateColumns: "12fr" } }, { children: _jsxs("div", __assign({ className: "games-content" }, { children: [_jsxs("div", __assign({ className: "page-view-more" }, { children: [_jsx("h3", __assign({ className: "view-title-more ellipsis" }, { children: t("home.casino.title") }), void 0), _jsxs("a", __assign({ className: "view-see-more", href: "/tr/oyunlar/" }, { children: [t('general.more'), " ", _jsx("i", { className: "view-arrow-more bc-i-small-arrow-right" }, void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "games-horiz " }, { children: _jsx("div", __assign({ className: "games-horiz-scroll", "data-scroll-lock-scrollable": "", style: { gridTemplateRows: "auto" } }, { children: gameList.slice(0, 10).map(function (game, key) {
                                    return _jsx("div", __assign({ className: "casino-game-item-content ", onClick: function () {
                                            navigate(base + '/oyun/' + game.code);
                                        } }, { children: _jsxs("div", __assign({ className: "casino-game-item  ", "data-badge": "" }, { children: [_jsx("img", { src: game.image, className: "casino-game-item-image-bc", alt: game.name, title: game.name, loading: "lazy" }, void 0), _jsx("i", { className: "casino-game-item-fav-bc bc-i-favorite " }, void 0)] }), void 0) }), "home-game" + key);
                                }) }), void 0) }), void 0)] }), void 0) }), void 0)] }, void 0));
};
export default Home;
var speechTimer;
var searchTimer;
export function SearchBar() {
    var _a = React.useState(false), modalIsOpen = _a[0], setIsOpen = _a[1];
    var navigate = useNavigate();
    var _b = useState(''), input = _b[0], setInput = _b[1];
    var _c = useState([]), searchResult = _c[0], setSearchResult = _c[1];
    var i18n = useTranslation().i18n;
    var base = '/' + i18n.resolvedLanguage;
    var _d = useSpeechToText({
        continuous: false,
        useLegacyResults: false,
        timeout: 5,
    }), error = _d.error, interimResult = _d.interimResult, isRecording = _d.isRecording, results = _d.results, startSpeechToText = _d.startSpeechToText, stopSpeechToText = _d.stopSpeechToText;
    useEffect(function () {
        return function () {
            clearTimeout(speechTimer);
            clearTimeout(searchTimer);
            if (isRecording) {
                stopSpeechToText();
            }
            clearTimeout(speechTimer);
            clearTimeout(searchTimer);
        };
    }, []);
    clearTimeout(speechTimer);
    speechTimer = setTimeout(function () {
        if (interimResult) {
            // @ts-ignore
            setInput(interimResult);
            changeHandle(interimResult);
        }
    }, 50);
    var changeHandle = function (keyword) {
        clearTimeout(searchTimer);
        if (keyword.length > 2) {
            searchTimer = setTimeout(function () {
                if (keyword.toLowerCase().includes('para çek')) {
                    navigate(base + '/hesabim/para-cek');
                }
                else if (keyword.toLowerCase().includes('banka hesa')) {
                    navigate(base + '/hesabim/banka-hesaplarim');
                }
                else if (keyword.toLowerCase().includes('destek tale')) {
                    navigate(base + '/hesabim/destek-taleplerim/beklemede');
                }
                else {
                    if (keyword.toLowerCase().split(' ').includes('lig') ||
                        keyword.toLowerCase().split(' ').includes('ligi') ||
                        keyword.toLowerCase().split(' ').includes('bundesliga') ||
                        keyword.toLowerCase().split(' ').includes('lig1') ||
                        keyword.toLowerCase().split(' ').includes('Ligue 1') ||
                        keyword.toLowerCase().split(' ').includes('concacaf') ||
                        keyword.toLowerCase().split(' ').includes('uluslararası') ||
                        keyword.toLowerCase().split(' ').includes('uluslar arası') ||
                        keyword.toLowerCase().split(' ').includes('bölgesellig') ||
                        keyword.toLowerCase().split(' ').includes('euroleague') ||
                        keyword.toLowerCase().split(' ').includes('laliga')) {
                        var competitionResult = [];
                        keyword = keyword.toLowerCase().replace(' ligi', '').replace(' lig', '');
                        var result = _.orderBy(sportsbookList.filter(function (x) { return Number(x.eventType) !== 2; }).filter(function (el) {
                            var isMatch = 0;
                            if (keyword.length > 2) {
                                for (var _i = 0, _a = keyword.split(' '); _i < _a.length; _i++) {
                                    var k = _a[_i];
                                    // console.log(el.competitionName.toLowerCase(), k.toLowerCase(), el.competitionName.toLowerCase().includes(k.toLowerCase()))
                                    if (el.competitionName.toLowerCase().includes(k.toLowerCase())) {
                                        isMatch += 1;
                                    }
                                    if (el.competitionGroupName.toLowerCase().includes(k.toLowerCase())) {
                                        isMatch += 1;
                                    }
                                }
                                if (isMatch > 0) {
                                    el.searchScore = isMatch;
                                    return el;
                                }
                            }
                            return false;
                        }), ['competitionPriority', 'searchScore'], ['asc', 'desc']);
                        var _loop_1 = function (event_1) {
                            if (!competitionResult.find(function (x) {
                                return x.name === event_1.competitionName;
                            })) {
                                competitionResult.push({
                                    name: event_1.competitionName,
                                    sportSlug: event_1.sportSlug,
                                    competitionId: event_1.competitionId,
                                    eventType: event_1.eventType,
                                    count: result.filter(function (e) { return e.competitionName === event_1.competitionName; }).length
                                });
                            }
                        };
                        for (var _i = 0, result_1 = result; _i < result_1.length; _i++) {
                            var event_1 = result_1[_i];
                            _loop_1(event_1);
                        }
                        if (isRecording) {
                            stopSpeechToText();
                        }
                        if (competitionResult.length === 1) {
                            var competition = competitionResult[0];
                            setInput('');
                            navigate(base + '/sports/' + competition.sportSlug + '/' + (Number(competition.eventType) === 2 ? 'uzunvadeli' : 'tumu') + '/' + competition.competitionId);
                        }
                        else if (result.length > 1) {
                            setSearchResult(competitionResult.slice(0, 10));
                            setInput('');
                            setIsOpen(true);
                        }
                        else {
                            if (isRecording) {
                                stopSpeechToText();
                            }
                            modalServiceSubject.next({
                                title: 'Arama Sonucu',
                                content: 'Lütfen aramak istediğiniz takım ismini ya da lig ismi doğru giriniz',
                                confirm: {
                                    sure: {
                                        label: 'Temizle ve Tekrarla',
                                        action: function () {
                                            setInput('');
                                            changeHandle('');
                                            // @ts-ignore
                                            document.getElementById('seachBar').focus();
                                        },
                                    }
                                },
                                timeout: 3000,
                            });
                        }
                    }
                    else {
                        var result = _.orderBy(sportsbookList.filter(function (x) { return Number(x.eventType) !== 2; }).filter(function (el) {
                            var isMatch = 0;
                            if (keyword.length > 2) {
                                for (var _i = 0, _a = keyword.split(' '); _i < _a.length; _i++) {
                                    var k = _a[_i];
                                    if (el.eventName.toLowerCase().includes(k.toLowerCase())) {
                                        isMatch += 1;
                                    }
                                }
                                if (isMatch > 0) {
                                    el.searchScore = isMatch;
                                    return el;
                                }
                            }
                            return false;
                        }), ['searchScore'], ['desc']);
                        if (isRecording) {
                            stopSpeechToText();
                        }
                        if (result.length === 1) {
                            var event_2 = result[0];
                            setInput('');
                            navigate(base + '/sports/' + event_2.sportSlug + '/' + (Number(event_2.eventType) === 2 ? 'uzunvadeli' : 'tumu') + '/' + event_2.competitionId + '/' + event_2.eventId);
                        }
                        else if (result.length > 1) {
                            setSearchResult(result.slice(0, 5));
                            setInput('');
                            setIsOpen(true);
                        }
                        else {
                            if (isRecording) {
                                stopSpeechToText();
                            }
                            modalServiceSubject.next({
                                title: 'Arama Sonucu',
                                content: 'Lütfen aramak istediğiniz takım ismini ya da lig ismi doğru giriniz',
                                timeout: 3000,
                                confirm: {
                                    sure: {
                                        label: 'Temizle ve Tekrarla',
                                        action: function () {
                                            setInput('');
                                            changeHandle('');
                                            // @ts-ignore
                                            document.getElementById('seachBar').focus();
                                        },
                                    }
                                },
                            });
                        }
                    }
                }
            }, 1000);
        }
        else {
            if (isRecording) {
                stopSpeechToText();
            }
        }
        setInput(keyword);
    };
    function closeModal() {
        setIsOpen(false);
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "sport-search-bc active " }, { children: [_jsx("input", { placeholder: "Bir yar\u0131\u015Fma veya tak\u0131m aray\u0131n", className: "ss-input-bc", type: "search", autoComplete: "off", value: input, onChange: function (e) { return changeHandle(e.target.value); } }, void 0), _jsx("div", __assign({ className: "ss-icon-holder-bc" }, { children: _jsx("i", { className: "ss-icon-bc bc-i-search" }, void 0) }), void 0)] }), void 0), modalIsOpen && _jsx("div", { children: _jsxs("div", __assign({ className: "sport-search-result-bc", "data-scroll-lock-scrollable": "", style: { height: "calc(-158px + 100vh)" } }, { children: [_jsxs("div", __assign({ className: "sport-search-result-header-bc" }, { children: [_jsxs("span", __assign({ className: "sport-search-result-title-bc ellipsis" }, { children: ["Arama Sonu\u00E7lar\u0131 (", searchResult.length, ")"] }), void 0), _jsx("i", { onClick: function () { return closeModal(); }, className: "bc-i-close-remove search-close-btn" }, void 0)] }), void 0), _jsx("ul", __assign({ className: "sport-search-result-body-bc", "data-scroll-lock-scrollable": "" }, { children: searchResult.map(function (result, key) {
                                return _jsxs("div", __assign({ className: "sports-group-bc" }, { children: [_jsxs("div", __assign({ className: "sg-head-bc  ".concat(result.sportSlug, "-bg") }, { children: [_jsx("i", { className: "sg-type-icon-bc bc-i-".concat(result.sportSlug) }, void 0), _jsx("span", __assign({ className: "sg-title-bc ellipsis" }, { children: result.sportName }), void 0)] }), void 0), _jsx("div", __assign({ className: "sg-body-bc" }, { children: _jsxs("li", __assign({ className: "sport-search-result-item-bc", onClick: function () {
                                                    if (result.count) {
                                                        navigate(base + '/sports/' + result.sportSlug + '/' + (result.eventType === 2 ? 'uzunvadeli' : 'tumu') + '/' + result.competitionId);
                                                    }
                                                    else {
                                                        navigate(base + '/sports/' + result.sportSlug + '/' + (result.eventType === 2 ? 'uzunvadeli' : 'tumu') + '/' + result.competitionId + '/' + result.eventId);
                                                    }
                                                    setFilterCompetitions(result.competitionId);
                                                } }, { children: [_jsx("p", __assign({ className: "s-g-competition-n-bc" }, { children: result.competitionName }), void 0), _jsx("p", { children: result.name ? result.name : result.eventName }, void 0), _jsx("p", __assign({ className: "s-g-r-time-bc" }, { children: _jsx("time", __assign({ className: "" }, { children: dateTimeFormat(result.eventDate) }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0);
                            }) }), void 0)] }), void 0) }, void 0)] }, void 0));
}
