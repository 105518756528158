var BetSlip = /** @class */ (function () {
    function BetSlip() {
    }
    return BetSlip;
}());
export { BetSlip };
var BetSlipOptions = /** @class */ (function () {
    function BetSlipOptions() {
        this.inProgress = false;
        this.errorMessage = null;
        this.bettingInfoMessage = null;
        this.bettingInfoStatus = null;
        this.delay = 11;
        this.totalRatio = 1;
        this.specialRatio = 1;
        this.usePointBalance = false;
        this.betSlipQuantity = 1;
        this.betSlipMultiply = 0;
        this.systemOptions = [];
        this.systemCombinations = [];
        this.couponCondition = "1";
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.accept_higher_odds = true;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.accept_lower_odds = true;
    }
    return BetSlipOptions;
}());
export { BetSlipOptions };
