export var customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        background: 'var(--color-bg-white)',
        marginRight: '-50%',
        maxWidth: ' calc( 100% - 40px )',
        padding: '15px',
        transform: 'translate(-50%, -50%)',
    },
};
export var defaultModalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        maxWidth: 'calc(100vw - 80px)',
        width: '920px',
        padding: '15px',
        maxHeight: 'calc(100vh - 100px)',
        transform: 'translate(-50%, -50%)',
        background: 'var(--color-bg-white)',
    }
};
export var postCommentStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        maxWidth: '600px',
        height: '550px',
        maxHeight: 'calc( 100vh - 110px)',
        background: 'var(--color-bg-white)',
        width: '100%',
        padding: '15px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
