import { Subject } from 'rxjs';
import { Member, Location } from "../models/user.model";
import { createContext, useContext } from "react";
export var loginSubject = new Subject();
export var member = new Member();
export var loginMember = new Member();
export var location = new Location();
export var AuthContext = createContext({
    isAuthenticated: false,
    setIsAuthenticated: function (c) {
        console.log("setIsAuthenticated>>", c);
    },
});
export var useAuthContext = function () { return useContext(AuthContext); };
