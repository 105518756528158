export var eventEndedStatuses = [505, 506, 508, 509, 510, 511, 512, 514, 515, 550];
export var EventResult = {
    notStarted: 0,
    continues: 1,
    predictionWon: 2,
    continuesWinning: 3,
    predictionLost: 4,
    continuesLosing: 5,
    end: 6,
    cancelled: 7,
    officalWon: 8,
    officalLost: 9
};
