var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from "react";
import Lock from "../../../assets/feather/lock.svg";
import { addToBetSlip } from "../../../services/betslip.services";
import { marketNameTransform } from "../../../services/sportsbook.services";
import { handicappedMarkets } from "../../../definitions/constants/market-categories.constant";
import { isMobileView } from "../../../app";
import { betSlipActionSubject } from "../../../store/betslip.store";
import { useForceUpdate } from "../../../lib/misc.functions";
export function Odd(props) {
    var market = props.market;
    var hlGroup = props.hlGroup;
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        var betSlipActionSubject_subscription = betSlipActionSubject.subscribe(function (events) {
            forceUpdate();
        });
        return function () {
            betSlipActionSubject_subscription.unsubscribe();
        };
    }, []);
    if (market) {
        var event_1 = props.event;
        var marketOutcome = market.marketOutcome;
        // if (event.eventType === '2') {
        //     marketOutcome = _.orderBy(market.marketOutcome.filter((el: OutCome) => el.fixedOdds > 1), function (o) {
        //         return Number(o.fixedOdds);
        //     });
        // }
        if (isMobileView) {
            if (props.showOutcomeName) {
                return _jsx(React.Fragment, { children: marketOutcome.map(function (outcome, key) {
                        return _jsxs("div", __assign({ className: "sgm-market-g-i-cell-bc market-bc  ".concat(outcome.isSelected ? 'active' : ''), onClick: function () {
                                addToBetSlip(event_1, market, outcome);
                            } }, { children: [props.showOutcomeName && _jsxs("span", __assign({ className: "market-name-bc ellipsis" }, { children: [outcome.outcomeName, " ", handicappedMarkets.includes(market.marketType + '-' + market.marketSubType) || (market.marketName.includes("Maçın Geri Kalanını Kim Kazanır")) || (market.marketName.includes("Sıradaki Golü Kim Atar")) ? marketNameTransform(outcome.outcomeName, market.specialOddsValue, market) : " ".concat((market.specialOddsValue === '.5' ? '0.5' : market.specialOddsValue) || '')] }), void 0), _jsxs("div", __assign({ className: "market-coefficient-bc" }, { children: [_jsx("i", { className: "market-arrow-up-down-bc is-".concat(outcome.isUpdated) }, void 0), _jsx("div", __assign({ className: "market-odds-container" }, { children: _jsx("span", __assign({ className: "market-odd-bc" }, { children: market.marketStatus < 0 ? _jsx(_Fragment, { children: _jsx("img", { src: Lock, width: 15, className: "invert-color" }, void 0) }, void 0) : _jsx(_Fragment, { children: Number(outcome.fixedOdds) > 1 && market.marketStatus !== 0 && market.marketStatus < 2 ? _jsx(_Fragment, { children: Number(outcome.fixedOdds).toFixed(2) }, void 0) : _jsx("div", { children: " - " }, void 0) }, void 0) }), void 0) }), void 0)] }), void 0)] }), "odds_" + event_1.eventId + market.marketId + '__' + key);
                    }) }, void 0);
            }
            else {
                return _jsx(React.Fragment, { children: marketOutcome.map(function (outcome, key) {
                        return _jsx("div", __assign({ className: "c-odd-bc", onClick: function () {
                                addToBetSlip(event_1, market, outcome);
                            } }, { children: _jsx("div", __assign({ className: "sgm-market-g-i-cell-bc market-bc  ".concat(outcome.isSelected ? 'active' : '') }, { children: _jsxs("div", __assign({ className: "market-coefficient-bc" }, { children: [_jsx("i", { className: "market-arrow-up-down-bc is-".concat(outcome.isUpdated) }, void 0), _jsx("div", __assign({ className: "market-odds-container" }, { children: _jsx("span", __assign({ className: "market-odd-bc" }, { children: market.marketStatus < 0 ? _jsx(_Fragment, { children: _jsx("img", { src: Lock, width: 15, className: "invert-color" }, void 0) }, void 0) : _jsx(_Fragment, { children: Number(outcome.fixedOdds) > 1 && market.marketStatus !== 0 && market.marketStatus < 2 ? _jsx(_Fragment, { children: Number(outcome.fixedOdds).toFixed(2) }, void 0) : _jsx("div", { children: " - " }, void 0) }, void 0) }), void 0) }), void 0)] }), void 0) }), void 0) }), "odds_" + event_1.eventId + market.marketId + '__' + key);
                    }) }, void 0);
            }
        }
        else {
            return (_jsx(_Fragment, { children: marketOutcome.map(function (outcome, key) {
                    return _jsxs("div", __assign({ onClick: function () {
                            addToBetSlip(event_1, market, outcome);
                        }, className: "sgm-market-g-i-cell-bc market-bc  ".concat(outcome.isSelected ? 'active' : '') }, { children: [props.showOutcomeName && _jsxs("span", __assign({ className: "market-name-bc ellipsis" }, { children: [outcome.outcomeName, " ", handicappedMarkets.includes(market.marketType + '-' + market.marketSubType) || (market.marketName.includes("Maçın Geri Kalanını Kim Kazanır")) || (market.marketName.includes("Sıradaki Golü Kim Atar")) ? marketNameTransform(outcome.outcomeName, market.specialOddsValue, market) : " ".concat((market.specialOddsValue === '.5' ? '0.5' : market.specialOddsValue) || '')] }), void 0), _jsxs("div", __assign({ className: "market-coefficient-bc" }, { children: [_jsx("i", { className: "market-arrow-up-down-bc is-".concat(outcome.isUpdated) }, void 0), _jsx("div", __assign({ className: "market-odds-container" }, { children: _jsx("span", __assign({ className: "market-odd-bc" }, { children: market.marketStatus < 0 ?
                                                _jsx(_Fragment, { children: _jsx("img", { src: Lock, width: 15, className: "invert-color" }, void 0) }, void 0) : _jsx(_Fragment, { children: Number(outcome.fixedOdds) > 1 && market.marketStatus !== 0 && market.marketStatus < 2 ? _jsx(_Fragment, { children: Number(outcome.fixedOdds).toFixed(2) }, void 0) : _jsx("div", { children: " - " }, void 0) }, void 0) }), void 0) }), void 0)] }), void 0)] }), "odds_" + event_1.eventId + market.marketId + '__' + key);
                }) }, void 0));
        }
    }
    else {
        if (hlGroup) {
            return hlGroup.labels.map(function (e, key) {
                return _jsxs("div", __assign({ className: "sgm-market-g-i-cell-bc market-bc " }, { children: [props.showOutcomeName && _jsxs("span", __assign({ className: "market-name-bc ellipsis" }, { children: [e, " "] }), void 0), _jsx("div", __assign({ className: "market-coefficient-bc" }, { children: _jsx("div", __assign({ className: "market-odds-container" }, { children: _jsx("span", __assign({ className: "market-odd-bc" }, { children: _jsx("div", { children: " - " }, void 0) }), void 0) }), void 0) }), void 0)] }), "odds_" + '__' + key);
            });
        }
        else {
            return _jsx(React.Fragment, {}, void 0);
        }
    }
}
