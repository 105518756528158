var StorageService = /** @class */ (function () {
    function StorageService() {
    }
    StorageService.prototype.set = function (key, val, timeout) {
        if (timeout === void 0) { timeout = null; }
        var expire = null;
        if (timeout) {
            var now = new Date();
            expire = now.getTime() + timeout;
        }
        var data = { val: val, expire: expire };
        localStorage.setItem(key, JSON.stringify(data));
    };
    StorageService.prototype.get = function (key) {
        var now = new Date();
        var expire = now.getTime();
        var val = localStorage.getItem(key);
        if (val === null) {
            return null;
        }
        try {
            var data = JSON.parse(val);
            if (data.expire !== null && data.expire < expire) {
                localStorage.removeItem(key);
                return null;
            }
            else {
                return data.val;
            }
        }
        catch (e) {
            localStorage.clear();
            return null;
        }
    };
    StorageService.prototype.del = function (key) {
        localStorage.removeItem(key);
        return null;
    };
    return StorageService;
}());
export { StorageService };
export var Storage = new StorageService();
