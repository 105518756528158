var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { modalServiceSubject, showInternetProblem } from "../components/modal/modal";
import { env } from "../definitions/global.vars";
import { ApiService } from "../lib/http.service";
import { Storage } from "../lib/localstorege.service";
import { member } from "../store/login.store";
import { Subject } from "rxjs";
import { scoreStatusIds } from "../definitions/constants/score_status_ids";
import { inProgress } from "../app";
import { ServerDate } from "../lib/misc.functions";
import html2canvas from "html2canvas";
var Combinatorics = require('js-combinatorics');
export var commentUpdateSubject = new Subject();
export var Api = new ApiService();
export function sharePost(data) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    data['token'] = Storage.get('token');
                    data['obj_id'] = parseInt(data['obj_id']);
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + '/misc/api/add-post/', data, true)
                            .then(function (response) {
                            return response;
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function likePost(post) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    data = {
                        post_id: post.post_id,
                        token: Storage.get('token')
                    };
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + '/misc/api/post-like-actions/', data, true)
                            .then(function (response) {
                            if (response.status) {
                                var isLiked = response.data.liked ? 1 : 0;
                                var likedCount = response.data.liked_count ? response.data.liked_count : 0;
                                post.is_liked = [isLiked];
                                post.like_count = likedCount;
                                commentUpdateSubject.next(post.post_id);
                            }
                            else {
                                modalServiceSubject.next({
                                    title: 'Uyarı',
                                    content: response.message,
                                    confirm: {
                                        cancel: { label: 'Tamam' }
                                    },
                                    timeout: 0,
                                });
                            }
                            return response;
                        })
                        // this.userService.userServiceRequest(requestData).then(response => {
                        //   if (response.status) {
                        //     const isLiked = response.data.liked ? response.data.liked : false;
                        //     const likedCount = response.data.liked_count ? response.data.liked_count : 0;
                        //     this.eventComments.map((el) => {
                        //       if (Number(el.post_id) === Number(post.post_id)) {
                        //         el.is_liked = isLiked ? [1] : [0];
                        //         el.like_count = likedCount;
                        //       }
                        //     });
                        //   }
                        // });
                    ];
                case 1: 
                // console.log(post);
                return [2 /*return*/, _b.sent()
                    // this.userService.userServiceRequest(requestData).then(response => {
                    //   if (response.status) {
                    //     const isLiked = response.data.liked ? response.data.liked : false;
                    //     const likedCount = response.data.liked_count ? response.data.liked_count : 0;
                    //     this.eventComments.map((el) => {
                    //       if (Number(el.post_id) === Number(post.post_id)) {
                    //         el.is_liked = isLiked ? [1] : [0];
                    //         el.like_count = likedCount;
                    //       }
                    //     });
                    //   }
                    // });
                ];
            }
        });
    });
}
export function likeComment(comment) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    data = {
                        comment_id: parseInt(comment.comment_id),
                        token: Storage.get('token')
                    };
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + '/misc/api/comment-like-actions/', data, true)
                            .then(function (response) {
                            if (response.status) {
                                var isLiked = response.data.liked ? 1 : 0;
                                var likedCount = response.data.liked_count ? response.data.liked_count : 0;
                                comment.is_liked = [isLiked];
                                comment.like_count = likedCount;
                            }
                            return response;
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function sendComment(postId, comment) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    data = {
                        post_id: parseInt(postId),
                        comment: comment,
                        token: Storage.get('token')
                    };
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + '/misc/api/add-comment/', data, true)
                            .then(function (response) {
                            return response;
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function deleteComment(comment_id) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    data = {
                        comment_id: comment_id,
                        token: Storage.get('token')
                    };
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + '/misc/api/comment-delete/', data, true)
                            .then(function (response) {
                            return response;
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function getTrendEvents() {
    return __awaiter(this, void 0, void 0, function () {
        var apiService;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + "/misc/api/get-trends/events/", { type: 'events' }, true)
                            .then(function (response) {
                            if (response.status == true) {
                                return response.data;
                            }
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function askToReport(post) {
    var data = {
        post_id: post.post_id,
        token: Storage.get('token')
    };
    modalServiceSubject.next({
        title: 'Emin misiniz?',
        content: 'Bu paylaşımı şikayet etmek istediğinizden emin misiniz?',
        confirm: {
            sure: {
                label: 'Şikayet Et',
                action: function () {
                    if (!inProgress.state) {
                        inProgress.state = true;
                        var apiService = new ApiService();
                        apiService
                            .start('post', env.societywon_api + '/misc/api/post-report/', data, true)
                            .then(function (response) {
                            if (response.status) {
                                modalServiceSubject.next({
                                    title: 'Desteğiniz için teşekkür ederiz',
                                    content: 'Şikayetiniz kayda alınmıştır. Gerekli incelemelerden sonra geri dönüş yapılacaktır.',
                                    confirm: {
                                        cancel: { label: 'Tamam' }
                                    },
                                    timeout: 3000,
                                });
                            }
                            else {
                                modalServiceSubject.next({
                                    title: 'Uyarı!',
                                    content: response.message,
                                    confirm: {
                                        cancel: { label: 'Tamam' }
                                    },
                                    timeout: 5000,
                                });
                            }
                            inProgress.state = false;
                        });
                    }
                }
            }
        },
        timeout: 0,
    });
    // this.userService.userServiceRequest(requestData).then(response => {
    //   if (response.status) {
    //     const header = 'Teşekkürler';
    //     const alertMessage = 'Şikayetiniz kayda alınmıştır. Gerekli incelemelerden sonra geri dönüş yapılacaktır.';
    //     this.alertService.showWarningAlert(header, alertMessage);
    //   } else {
    //     this.alertService.showWarningAlert('Uyarı', response.message ? response.message : 'İşlem tamamlanamadı');
    //   }
    // }, onerror => {
    //   this.alertService.showWarningAlert('Uyarı', 'İşlem tamamlanamadı');
    // });
}
export function urltoFile(url, filename, mimeType) {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
    return (fetch(url)
        .then(function (res) {
        return res.arrayBuffer();
    })
        .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
    }));
}
export function getsocietywonData(filterData, link) {
    if (filterData === void 0) { filterData = {}; }
    if (link === void 0) { link = ''; }
    if (member && localStorage.getItem('token')) {
        var token = localStorage.getItem('token');
        filterData['token'] = JSON.parse(token).val;
    }
    else {
        filterData['token'] = '';
    }
    return Api.start('post', env.core_api + '/misc/api/' + link, filterData, true).then(function (response) {
        if (response.hasOwnProperty('status')) {
            // @ts-ignore
            if (response.status === true) {
                // @ts-ignore
                return [true, response.data, response.message];
            }
            else {
                // @ts-ignore
                return [false, response.message];
            }
        }
        else {
            return [false, 'İşlem Tamamlanamadı.'];
        }
    });
}
export function shareOnSocial(post, social, type) {
    // console.log(post,'post')
    var sharedId;
    var sharedElementId;
    if (type === 'post') {
        // console.log('type post')
        sharedId = post.post_id;
        sharedElementId = '#slip_' + sharedId;
    }
    else {
        // console.log('type copun')
        sharedId = post.id;
        sharedElementId = '#couponSlip_' + sharedId;
    }
    var element = document.getElementById(sharedElementId);
    var twitterShare = document.getElementById('twitterShare' + sharedId);
    var whatsappShare = document.querySelector('#waButton' + sharedId);
    // let downloadShare: any = document.querySelector('#downloadShare' + sharedId);
    if (twitterShare) {
        twitterShare.disabled = true;
    }
    if (whatsappShare) {
        whatsappShare.disabled = true;
    }
    // if(downloadShare){
    //     downloadShare.disabled = true;
    // }
    if (element) {
        html2canvas(element, {
            scale: 1,
            width: window.innerWidth < 768 ? element.outerWidth : 936,
            height: window.innerWidth < 768 ? element.outerHeight : 520
        }).then(function (canvas) {
            var imgData = canvas.toDataURL("image/jpeg");
            var fileImage = imgData.split(';base64,')[1];
            var data = {
                file: fileImage,
                post_id: sharedId,
                is_mobile: window.innerWidth < 768,
            };
            //twitter
            if (social === 0) {
                twitterShare.disabled = true;
                getsocietywonData(data, 'post-share/').then(function (response) {
                    if (response[0]) {
                        var t_href = 'https://twitter.com/intent/tweet?text=' + (post.comment && post.comment.length > 0 ? post.comment : '') + '&url=https://share.1xwon.com/post/' + sharedId;
                        window.open(t_href, '_blank');
                        // win.focus();
                    }
                });
                //whatsapp
            }
            else if (social === 1) {
                whatsappShare.disabled = true;
                if (post.is_shared) {
                    shareMobile(imgData).then(function (r) { return console.log(r); });
                }
                else {
                    if (!post.post_id) {
                        if (type === 'tjk') {
                            shareMobile(imgData).then(function (r) { return console.log(r); });
                        }
                        else {
                            modalServiceSubject.next({
                                title: 'Uyarı',
                                content: 'Bu kuponu paylaşmak için, önce societywon\n\'de yayınlamalısınız!',
                                timeout: 0,
                                confirm: {
                                    cancel: { label: 'Tamam' }
                                },
                            });
                        }
                    }
                    else {
                        shareMobile(imgData).then(function (r) { return console.log(r); });
                    }
                }
            }
            else {
                //download
                var link = document.createElement('a');
                link.download = sharedId;
                link.href = imgData;
                link.click();
            }
            if (twitterShare) {
                twitterShare.disabled = false;
            }
            if (whatsappShare) {
                whatsappShare.disabled = false;
            }
            // if (downloadShare) {
            //     downloadShare.disabled = false;
            // }
        });
    }
}
export function shareMobile(imgData) {
    return __awaiter(this, void 0, void 0, function () {
        var blob, filesArray, shareData, err_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch(imgData)];
                case 1: return [4 /*yield*/, (_b.sent()).blob()];
                case 2:
                    blob = _b.sent();
                    filesArray = [
                        new File([blob], 'kupon.png', {
                            type: blob.type,
                            lastModified: new Date().getTime()
                        })
                    ];
                    if (!navigator.share) return [3 /*break*/, 7];
                    shareData = {
                        files: filesArray
                    };
                    _b.label = 3;
                case 3:
                    _b.trys.push([3, 5, , 6]);
                    if (!(navigator.canShare(shareData))) {
                        throw new Error('Cant share data.');
                    }
                    ;
                    return [4 /*yield*/, navigator.share(shareData)];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 6];
                case 5:
                    err_1 = _b.sent();
                    console.error(err_1.name, err_1.message);
                    return [3 /*break*/, 6];
                case 6: return [3 /*break*/, 8];
                case 7:
                    console.log('Native Web Sharing not supported');
                    _b.label = 8;
                case 8: return [2 /*return*/];
            }
        });
    });
}
export function askToDelete(post, event, parentForceUpdate) {
    if (event === void 0) { event = null; }
    if (parentForceUpdate === void 0) { parentForceUpdate = null; }
    // console.log(post)
    // console.log(event)
    var apiService = new ApiService();
    var data = {
        post_id: post.post_id,
        token: Storage.get('token')
    };
    modalServiceSubject.next({
        title: 'Emin misiniz?',
        content: 'Bu paylaşımı silmek istediğinizden emin misiniz?',
        confirm: {
            sure: {
                label: 'Paylaşımı Sil',
                action: function () {
                    apiService
                        .start('post', env.societywon_api + '/misc/api/post-delete/', data, true)
                        .then(function (response) {
                        if (!response.status) {
                        }
                        else {
                            if (event) {
                                if (event.comments) {
                                    var index = event.comments.indexOf(post);
                                    event.comments.splice(index, 1);
                                    commentUpdateSubject.next(Number(event.eventId));
                                }
                                else {
                                    try {
                                        var index = event.indexOf(post);
                                        event.splice(index, 1);
                                        parentForceUpdate();
                                    }
                                    catch (e) { }
                                    commentUpdateSubject.next({ action: 'delete', post_id: post.post_id });
                                }
                            }
                        }
                    });
                }
            },
            cancel: {
                label: 'Vazgeç',
            }
        },
        timeout: 0,
    });
}
export function getMemberPhotos(memberId) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(memberId && memberId.length > 0)) return [3 /*break*/, 2];
                    apiService = new ApiService();
                    data = {
                        member_id: memberId.join(','),
                        token: Storage.get('token')
                    };
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + '/misc/api/get-member-photos/', data, true)
                            .then(function (response) {
                            // console.log(response)
                            return response;
                        })
                            .catch(function (e) {
                            console.log(e);
                            return null;
                        })];
                case 1: return [2 /*return*/, _b.sent()];
                case 2: return [2 /*return*/, null];
            }
        });
    });
}
export function getMemberInfoTag(tag) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    data = {
                        q: tag,
                        token: Storage.get('token')
                    };
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + '/misc/api/get-member-info/', data, true)
                            .then(function (response) {
                            if (response.status == true) {
                                // console.log(response)
                                return response.data;
                            }
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function getMemberInfo(data) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    // const data = {
                    //   member_id: memberId,
                    //   token: Storage.get('token')
                    // };
                    data.token = Storage.get('token');
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + '/misc/api/get-member-info/', data, true)
                            .then(function (response) {
                            if (response.status == true) {
                                return response.data;
                            }
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function getEventComments(eventId, page, contentType, createDate) {
    if (page === void 0) { page = 0; }
    if (contentType === void 0) { contentType = 1; }
    if (createDate === void 0) { createDate = ''; }
    return __awaiter(this, void 0, void 0, function () {
        var apiService, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    data = {
                        content_type: contentType,
                        obj_id: eventId,
                        page: page,
                        token: Storage.get('token')
                    };
                    if (createDate != '') {
                        data['fields'] = "{'create_date': " + createDate + "}";
                    }
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + '/misc/api/get-posts/', data, true)
                            .then(function (response) {
                            if (response.status == true) {
                                response.data.forEach(function (item) {
                                    item.comment = item.comment.replace(/\n/g, '<br />');
                                });
                                return getMemberPhotos(response.data.map(function (el) {
                                    return el.member_id;
                                })).then(function (members) {
                                    if (members && members.status) {
                                        response.data.forEach(function (item) {
                                            item.member = members.data.find(function (member) { return (member.member_id === item.member_id); });
                                        });
                                    }
                                    return response.data;
                                }).catch(function () {
                                    return response.data;
                                });
                                return response.data;
                            }
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function getCoupons(ids) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService, params;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    params = {
                        coupon_id: ids.join(','),
                        token: Storage.get('token'),
                    };
                    if (ids.length === 0) {
                        return [2 /*return*/, []];
                    }
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + "/misc/api/get-coupons/", params, true)
                            .then(function (response) {
                            if (response.status == true) {
                                return response.data;
                            }
                        }).catch(function () {
                            return [];
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function getPosts(url, params) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + url, params, true)
                            .then(function (response) {
                            if (response.status == true) {
                                return response.data.filter(function (el) { return el.is_blocked !== true && el.is_deleted !== true; });
                            }
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function getEvents(ids) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    return [4 /*yield*/, apiService
                            .start('get', env.sportsbook_api + "/events/?from-date=2019-01-01&eventIds=" + ids.join(','), null, false)
                            .then(function (response) {
                            if (response.status == true) {
                                return response.data;
                            }
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function getEvent(id) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    return [4 /*yield*/, apiService
                            .start('get', env.sportsbook_api + "/event/" + id + "/", null, false)
                            .then(function (response) {
                            if (response.status == true) {
                                return response.data;
                            }
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function getLeaderBoards(url, params) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    return [4 /*yield*/, apiService.start('post', env.societywon_api + url, params, true).then(function (response) {
                            if (response.status == true) {
                                return response.data;
                            }
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function getPostComment(postId, page) {
    if (page === void 0) { page = 0; }
    return __awaiter(this, void 0, void 0, function () {
        var apiService;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    apiService = new ApiService();
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + '/misc/api/get-comments/', {
                            post_id: postId,
                            page: page,
                            token: Storage.get('token')
                        }, true)
                            .then(function (response) {
                            if (response.status == true) {
                                return getMemberPhotos(response.data.map(function (el) {
                                    return el.member_id;
                                })).then(function (members) {
                                    if (members.status) {
                                        response.data.forEach(function (item) {
                                            item.member = members.data.find(function (member) { return (member.member_id === item.member_id); });
                                        });
                                    }
                                    return response.data;
                                }).catch(function () {
                                    response.data.forEach(function (item) {
                                        item.comment = item.comment.replace(/\n/g, '<br />');
                                    });
                                    return response.data;
                                });
                            }
                            return [];
                        }).catch(function (e) {
                            console.log(e);
                            showInternetProblem();
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
export function calculateCouponStatus(eventData) {
    var status = eventData[0].xwonOutcomeResult;
    for (var _b = 0, eventData_1 = eventData; _b < eventData_1.length; _b++) {
        var event_1 = eventData_1[_b];
        if (event_1.xwonOutcomeResult === 0) {
            status = 0; // Kaybediyor
            break;
        }
        else if (event_1.xwonOutcomeResult === 2) {
            status = 2; // Kazanıyor
        }
        else {
            status = 1; // Bekliyor
        }
    }
    return status;
}
export function calculateEventFlow(statusId, minute, sportType) {
    // if (statusId.toString() && minute.toString() && sportType.toString()) {
    if (statusId !== null && minute !== null && sportType !== null && statusId !== undefined && minute !== undefined && sportType !== undefined) {
        try {
            var sport = Number(sportType);
            var status_1 = Number(statusId);
            var shortnames = scoreStatusIds.filter(function (el) {
                return el.id === status_1;
            });
            var shortName = shortnames[0].shortName;
            if ([1, 6].includes(sport)) {
                // Futbol, Hentbol
                if ([2, 3, 6, 8].includes(status_1)) {
                    return { minute: minute + "'", shortName: shortName };
                }
                else {
                    return { minute: 0, shortName: shortName };
                }
            }
            else if (sport === 2) {
                // Basketbol
                if ([12, 13, 14, 15].includes(status_1)) {
                    return { minute: minute.toString() + "'" + ' (' + shortName + ')', shortName: shortName };
                }
                else {
                    return { minute: 0, shortName: shortName };
                }
            }
            else if ([4, 5, 20, 23].includes(sport)) {
                // Buz Hokeyi, Masa Tenisi, Voleybol, Tenis
                return { minute: 0, shortName: shortName };
            }
        }
        catch (e) {
            return null;
        }
    }
    return null;
}
export function couponSystem(coupons, systems, betSets) {
    var certainBets = [];
    var i = 0;
    var tmp;
    var _a;
    for (i = 0; i < coupons.length; i++) {
        if (coupons[i].isBanker) {
            certainBets.push(coupons[i]);
        }
    }
    function addToBet(coupons) {
        var control = true;
        if (systems.indexOf(coupons.length) >= 0) {
            if (certainBets.length > 0) {
                for (var _i = 0; _i < certainBets.length; _i++) {
                    if (coupons.indexOf(certainBets[_i]) === -1) {
                        control = false;
                        break;
                    }
                }
                if (control) {
                    betSets.push(coupons);
                }
            }
            else {
                betSets.push(coupons);
            }
        }
    }
    for (i = 0; i < systems.length; i++) {
        try {
            if (coupons.length > 0) {
                tmp = Combinatorics.bigCombination(coupons, systems[i]);
                _a = tmp.next();
                while (_a) {
                    addToBet(_a);
                    _a = tmp.next();
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }
}
export function checkAllPossibleCoupons(coupon) {
    var eventData = coupon.rows || coupon.slip_rows;
    var system = coupon.system ? coupon.system.split('-').map(function (x) { return +x; }) : [eventData.length];
    var betSets = [];
    couponSystem(eventData, system, betSets);
    var possibleStatus = [];
    for (var _b = 0, betSets_1 = betSets; _b < betSets_1.length; _b++) {
        var possibleSet = betSets_1[_b];
        var couponStatus = calculateCouponStatus(possibleSet);
        possibleStatus.push(couponStatus);
        if (couponStatus === 2) {
            break;
        }
    }
    var finalStatus = 0; // Kaybediyor
    if (possibleStatus.includes(2)) {
        finalStatus = 2;
    }
    else if (possibleStatus.includes(1)) {
        finalStatus = 1;
    }
    coupon['xwonStatus'] = finalStatus;
    return coupon;
}
export function calculateGameScore(event) {
    var gameScore = '-';
    if (event) {
        var sportType = event.sportId;
        if ([1, 2, 6].includes(Number(sportType))) {
            gameScore = '- / -';
        }
        if (event.scores) {
            var scores = event.scores;
            // console.log(scores)
            var status_2 = Number(scores.statusId);
            if ([1, 6].includes(Number(sportType))) {
                // Futbol, Hentbol
                if (status_2 === 2) {
                    // 1. Yarı
                    gameScore = scores.homeTeam.current + ':' + scores.awayTeam.current;
                }
                else {
                    gameScore = scores.homeTeam.firstHalf + ':' + scores.awayTeam.firstHalf + ' / ' + scores.homeTeam.current + ':' + scores.awayTeam.current;
                }
            }
            else if ([4, 5, 20, 23].includes(Number(sportType))) {
                // Masa Tenisi, Voleybol, Tenis, Buz Hokeyi
                if (status_2 === 5) {
                    // 1. Yarı
                    gameScore = scores.homeTeam.firstHalf + ':' + scores.awayTeam.firstHalf + ' / ' + scores.homeTeam.current + ':' + scores.awayTeam.current;
                }
                else {
                    gameScore = scores.homeTeam.current + ':' + scores.awayTeam.current + ' / -';
                }
            }
            else if (Number(sportType) === 2) {
                // Basketbol
                if ([1, 2, 12, 13].includes(status_2)) {
                    // 1. Yarı
                    gameScore = scores.homeTeam.current + ':' + scores.awayTeam.current + ' / -';
                }
                else {
                    gameScore = scores.homeTeam.firstHalf + ':' + scores.awayTeam.firstHalf + ' / ' + scores.homeTeam.current + ':' + scores.awayTeam.current;
                }
            }
        }
        else if (event.officialScore) {
            var scoreText = '';
            var fT = event.officialScore.find(function (el) { return el.scoreTypeName === 'FT'; });
            var hT = event.officialScore.find(function (el) { return el.scoreTypeName === 'HT'; });
            var Q1 = event.officialScore.find(function (el) { return el.scoreTypeName === '1Q'; });
            var Q2 = event.officialScore.find(function (el) { return el.scoreTypeName === '2Q'; });
            var Q3 = event.officialScore.find(function (el) { return el.scoreTypeName === '3Q'; });
            var Q4 = event.officialScore.find(function (el) { return el.scoreTypeName === '4Q'; });
            // if(Q1){
            //     scoreText +=  Number(Q1.homeScore) + ' - ' + Number(Q1.awayScore)
            // }
            if (Q2) {
                // if(Q1){ scoreText += ' <br /> ' }
                scoreText += Number(Q2.homeScore) + ' - ' + Number(Q2.awayScore);
            }
            // if(Q3){
            //     if(Q2){ scoreText += ' <br /> ' }
            //     scoreText +=  Number(Q3.homeScore) + ' - ' + Number(Q3.awayScore)
            // }
            if (hT) {
                scoreText += Number(hT.homeScore) + ' - ' + Number(hT.awayScore);
            }
            if (fT) {
                if (hT || Q3) {
                    scoreText += ' / ';
                }
                scoreText += Number(fT.homeScore) + ' - ' + Number(fT.awayScore);
            }
            return scoreText;
        }
        else if (event.matchStatus === 'ended') {
            return event.currentScore;
        }
    }
    return gameScore;
}
function formatDateStringToDate(dateString) {
    try {
        var year = dateString.split('T')[0].split('-')[0];
        var month = dateString.split('T')[0].split('-')[1];
        var day = dateString.split('T')[0].split('-')[2];
        var hour = dateString.split('T')[1].split(':')[0];
        var minute = dateString.split('T')[1].split(':')[1];
        var second = dateString.split('T')[1].split(':')[2].split('.')[0];
        return new Date(year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' + second);
    }
    catch (e) {
    }
    return dateString;
}
export function dateOperationsToDateString(action, originalDate, minutes) {
    var originalDateDateFormatted = formatDateStringToDate(originalDate);
    var finalDate = originalDateDateFormatted;
    if (action === 'add') {
        finalDate = new Date(originalDateDateFormatted.getTime() + (minutes * 60000));
    }
    else if (action === 'remove') {
        finalDate = new Date(originalDateDateFormatted.getTime() - (minutes * 60000));
    }
    return finalDate;
}
export function calculateManually(eventDate) {
    var today = ServerDate();
    var dateOfEvent = new Date(eventDate);
    if (dateOfEvent < today) {
        // Maç başlamış, devam ediyor
        return { eventStatus: 31, iconStatus: 1, shortName: 'Dev' };
    }
    else {
        // Maç başlamamış
        return { eventStatus: 1, iconStatus: 0, shortName: 'Bşl' };
    }
}
export function calculateSpecialScore(game, selected_market) {
    var marketTypeSubType = selected_market.marketType + '-' + selected_market.marketSubType;
    var officialScore = game.officialScore ? game.officialScore : null;
    var scores = game.scores ? game.scores : null;
    var score = '';
    // Her bir özel market için skor gösterimi hesaplanmalı.
    // Futbol
    if (['2-67', '4-215', '4-211', '4-210', '2-56', '2-52', '2-48'].includes(marketTypeSubType)) {
        if (game.statistics) {
            var homeCorners = Number(game.statistics.homeTeam.corners);
            var awayCorners = Number(game.statistics.awayTeam.corners);
            return '(K.S.: ' + homeCorners + ' - ' + awayCorners + ')';
        }
    }
    if (['2-801'].includes(marketTypeSubType)) {
        if (game.statistics) {
            var homeOffsides = game.statistics.homeTeam.offsides ? Number(game.statistics.homeTeam.offsides) : 0;
            var awayOffsides = game.statistics.awayTeam.offsides ? Number(game.statistics.awayTeam.offsides) : 0;
            return '(T.O.: ' + homeOffsides + ' - ' + awayOffsides + ')';
        }
    }
    // Basketbol
    if (['2-660', '4-673'].includes(marketTypeSubType)) {
        if (officialScore) {
            var firstQuarterScores = officialScore.filter(function (el) {
                return Number(el.scoreType) === 41;
            });
            var homeFirstQuarter = firstQuarterScores.map(function (o) { return Number(o.homeScore); }).reduce(function (a, c) {
                return a + c;
            });
            var awayFirstQuarter = firstQuarterScores.map(function (o) { return Number(o.awayScore); }).reduce(function (a, c) {
                return a + c;
            });
            var firstQuarterScore = homeFirstQuarter + '-' + awayFirstQuarter;
            return '(1.P: ' + firstQuarterScore + ')';
        }
        else if (scores) {
            var homeFirstQuarter = scores.homeTeam.periods.first;
            var awayFirstQuarter = scores.awayTeam.periods.first;
            var firstQuarterScore = homeFirstQuarter + '-' + awayFirstQuarter;
            return '(1.P: ' + firstQuarterScore + ')';
        }
    }
    if (['2-107'].includes(marketTypeSubType)) {
        if (officialScore) {
            var firstQuarterScores = officialScore.filter(function (el) {
                return Number(el.scoreType) === 41;
            });
            var secondQuarterScores = officialScore.filter(function (el) {
                return Number(el.scoreType) === 42;
            });
            var thirdQuarterScores = officialScore.filter(function (el) {
                return Number(el.scoreType) === 43;
            });
            var fourthQuarterScores = officialScore.filter(function (el) {
                return Number(el.scoreType) === 44;
            });
            var firstTotal = Number(firstQuarterScores[0].homeScore) + Number(firstQuarterScores[0].awayScore);
            var secondTotal = Number(secondQuarterScores[0].homeScore) + Number(secondQuarterScores[0].awayScore);
            var thirdTotal = Number(thirdQuarterScores[0].homeScore) + Number(thirdQuarterScores[0].awayScore);
            var fourthTotal = Number(fourthQuarterScores[0].homeScore) + Number(fourthQuarterScores[0].awayScore);
            var data_1 = {
                '1.P: ': firstTotal,
                '2.P: ': secondTotal,
                '3.P: ': thirdTotal,
                '4.P: ': fourthTotal
            };
            var highScorePeriod = Object.keys(data_1).reduce(function (a, b) { return data_1[a] > data_1[b] ? a : b; });
            return '(' + highScorePeriod + data_1[highScorePeriod] + ' Sayı)';
        }
        else if (scores) {
            var homeFirstQuarter = scores.homeTeam.periods.first;
            var awayFirstQuarter = scores.awayTeam.periods.first;
            var firstQuarterScore = Number(homeFirstQuarter) + Number(awayFirstQuarter);
            var homeSecondQuarter = scores.homeTeam.periods.second;
            var awaySecondQuarter = scores.awayTeam.periods.second;
            var secondQuarterScore = Number(homeSecondQuarter) + Number(awaySecondQuarter);
            var homeThirdQuarter = scores.homeTeam.periods.third;
            var awayThirdQuarter = scores.awayTeam.periods.third;
            var thirdQuarterScore = Number(homeThirdQuarter) + Number(awayThirdQuarter);
            var homeFourthQuarter = scores.homeTeam.periods.fourth;
            var awayFourthQuarter = scores.awayTeam.periods.fourth;
            var fourthQuarterScore = Number(homeFourthQuarter) + Number(awayFourthQuarter);
            var data_2 = {
                '1.P: ': firstQuarterScore,
                '2.P: ': secondQuarterScore,
                '3.P: ': thirdQuarterScore,
                '4.P: ': fourthQuarterScore
            };
            var highScorePeriod = Object.keys(data_2).reduce(function (a, b) { return data_2[a] > data_2[b] ? a : b; });
            return '(' + highScorePeriod + data_2[highScorePeriod] + ' Sayı)';
        }
    }
    return score;
}
export function followActions(memberId, action, event) {
    if (event === void 0) { event = null; }
    return __awaiter(this, void 0, void 0, function () {
        var data, apiService;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    event.target.disabled = true;
                    data = {
                        member_id: memberId,
                        action: action,
                        token: Storage.get('token'),
                    };
                    apiService = new ApiService();
                    return [4 /*yield*/, apiService
                            .start('post', env.societywon_api + '/member/api/set-follow/', data, true)
                            .then(function (response) {
                            if (response.status) {
                                if (action === 'add') {
                                    member.following.push(memberId);
                                }
                                else {
                                    var indexOfRow = member.following.indexOf(memberId);
                                    if (indexOfRow !== -1) {
                                        member.following.splice(indexOfRow, 1);
                                    }
                                }
                                event.target.disabled = false;
                                return true;
                            }
                            return false;
                        }).catch(function (e) {
                            event.target.disabled = false;
                            // console.log(e)
                            //fixme log and repost eklenmeli bir de üye internet bağlantısı
                            return false;
                        })];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
//
// getEventsDetailsWithField(eventIds:any, fields:any, isCurrent: boolean = false) {
//
//   let link = '/events/?from-date=2019-01-01&eventIds=' + eventIds;
//   if (fields && fields !== '') {
//     link += '&fields=' + fields;
//   }
//
//   if (isCurrent) {
//     link += '&current=true';
//   }
//
//   const bulletinApiHost = env.sportsbook_api;
//   return this.http.get(bulletinApiHost + link, {
//     headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
//   }).toPromise().then(response => {
//     if (response.hasOwnProperty('status')) {
//       // @ts-ignore
//       if (response.status === true) {
//         // @ts-ignore
//         return [true, response.data];
//       } else {
//         return [false];
//       }
//     } else {
//       return [false];
//     }
//   });
// }
//
// getEventStatistics(event) {
//   const bulletinApiHost = `${environment.bulletin_api_url}`;
//   return this.http.get(bulletinApiHost + '/get-event-statistics/?events=' + event.eventId, {
//     headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
//   }).toPromise().then(response => {
//     // @ts-ignore
//     if (response.status === true) {
//       // @ts-ignore
//       let response_data = response.data;
//       if (response_data.length > 0) {
//         if (event) {
//           event['statistics'] = {
//             'homeTeam': response_data[0]['homeTeam']['statistics'],
//             'awayTeam': response_data[0]['awayTeam']['statistics']
//           }
//           this.statisticsMap[event.eventId.toString()] = event.statistics;
//         }
//       }
//     }
//   }, onerror => {
//     event['statistics'] = null;
//   });
//
// }
