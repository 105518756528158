import { ApiService } from '../../../../lib/http.service';
import { env } from "../../../../definitions/global.vars";
export var Api = new ApiService();
export var GameOperationsApi = (function () {
    return {
        iddiaDetail: function (request) {
            return Api.start('post', env.list_api + '/order/api/get_coupon_detail/', request, true);
        },
        sporTotoDelete: function (request) {
            return Api.start('post', env.core_api + '/order/api/cancel-coupon/sportoto/', request, true);
        },
        sporTotoDetail: function (id) {
            return Api.start('get', env.sportsbook_api + '/toto/' + id + '/', null, false);
        },
        milliPiyango: function (request) {
            return Api.start('post', env.list_api + '/order/api/orders/millipiyango/', request, true);
        },
        sayisalLoto: function (request) {
            return Api.start('post', env.list_api + '/order/api/sans-oyunu/siparisler/', request, true);
        },
        sayisalLotoDetail: function (request) {
            return Api.start('post', env.list_api + '/order/api/sans-oyunu/siparis-detay/', request, true);
        },
        sharesocietywon: function (request) {
            return Api.start('post', env.societywon_api + '/order/api/share-coupon/', request, true);
        },
        removeCoupon: function (request) {
            return Api.start('post', env.core_api + '/order/api/remove-coupon/', request, true);
        },
    };
})();
