var OutCome = /** @class */ (function () {
    function OutCome() {
    }
    return OutCome;
}());
export { OutCome };
var EventMarket = /** @class */ (function () {
    function EventMarket() {
    }
    return EventMarket;
}());
export { EventMarket };
var SportEvent = /** @class */ (function () {
    function SportEvent() {
    }
    return SportEvent;
}());
export { SportEvent };
