import EventStatusManager from "../lib/event-status-manager";
import { EventResult as MatchResultStatus } from "../definitions/constants/event-result-type";
import { statisticsMap } from "../store/sportsbook.store";
var MarketHelper = /** @class */ (function () {
    function MarketHelper() {
    }
    MarketHelper.isNullOrEmptyOrUndefined = function (s) {
        return (typeof (s) === 'undefined' || typeof (s) === null);
    };
    MarketHelper.getCurrentScore = function (scores) {
        var scoresInfo = {
            currentHomeScore: -1,
            currentAwayScore: -1,
            firstHalfHomeScore: -1,
            firstHalfAwayScore: -1,
            secondHalfHomeScore: -1,
            secondHalfAwayScore: -1,
            error: false
        };
        if (!MarketCalculator.isNullOrEmptyOrUndefined(scores.homeTeam) || !MarketCalculator.isNullOrEmptyOrUndefined(scores.awayTeam)) {
            scoresInfo.currentHomeScore = Number(scores.homeTeam.current);
            scoresInfo.currentAwayScore = Number(scores.awayTeam.current);
            if (scores.homeTeam.firstHalf > -1 && scores.awayTeam.firstHalf > -1) {
                scoresInfo.firstHalfHomeScore = Number(scores.homeTeam.firstHalf);
                scoresInfo.firstHalfAwayScore = Number(scores.awayTeam.firstHalf);
            }
            if (scores.homeTeam.secondHalf > -1 && scores.awayTeam.secondHalf > -1) {
                scoresInfo.secondHalfHomeScore = scoresInfo.firstHalfHomeScore + Number(scores.homeTeam.secondHalf);
                scoresInfo.secondHalfAwayScore = scoresInfo.firstHalfHomeScore + Number(scores.awayTeam.secondHalf);
            }
        }
        else {
            scoresInfo.error = true;
        }
        return scoresInfo;
    };
    MarketHelper.getCurrentStatistics = function (statistics) {
        var statisticsInfo = {
            currentHomeCorners: -1,
            currentAwayCorners: -1,
            totalCorners: -1,
            statisticsError: false
        };
        if (statistics && (!MarketCalculator.isNullOrEmptyOrUndefined(statistics.homeTeam) || !MarketCalculator.isNullOrEmptyOrUndefined(statistics.awayTeam))) {
            statisticsInfo.currentHomeCorners = parseInt(statistics.homeTeam.corners);
            statisticsInfo.currentAwayCorners = parseInt(statistics.awayTeam.corners);
            statisticsInfo.totalCorners = parseInt(statistics.homeTeam.corners) + parseInt(statistics.awayTeam.corners);
        }
        else {
            statisticsInfo.statisticsError = true;
        }
        return statisticsInfo;
    };
    return MarketHelper;
}());
var MarketCalculator = /** @class */ (function () {
    function MarketCalculator() {
    }
    MarketCalculator.isNullOrEmptyOrUndefined = function (s) {
        return !(s && typeof (s) !== 'undefined' && typeof (s) !== null);
    };
    // Football
    // @ts-ignore
    MarketCalculator.matchResult = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentHomeScore === currentAwayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && currentAwayScore > currentHomeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && secondHalfHomeScore > secondHalfAwayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && secondHalfHomeScore === secondHalfAwayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && secondHalfAwayScore > secondHalfHomeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.doubleChance = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (currentHomeScore > currentAwayScore || currentHomeScore === currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore > currentAwayScore || currentAwayScore > currentHomeScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && (currentAwayScore > currentHomeScore || currentHomeScore === currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (secondHalfHomeScore > secondHalfAwayScore || secondHalfHomeScore === secondHalfAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (secondHalfHomeScore > secondHalfAwayScore || secondHalfAwayScore > secondHalfHomeScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (secondHalfAwayScore > secondHalfHomeScore || secondHalfHomeScore === secondHalfAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.totalGoalsOverUnder = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = MarketCalculator.isNullOrEmptyOrUndefined(market.specialOddsValue) ? 1 : parseFloat(market.specialOddsValue);
        var totalCurrentScore = (currentHomeScore + currentAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (totalCurrentScore > specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                else if (totalCurrentScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalCurrentScore < specialOddsValue) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (totalCurrentScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            // let totalSecondHalfScore = (secondHalfHomeScore + secondHalfAwayScore);
            if (Number(outcome.outcomeNo) === 1 && (totalCurrentScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (totalCurrentScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.threeWayHandicap = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            var currentHomeScoreAndSpecialOdds = currentHomeScore + parseFloat(market.specialOddsValue);
            if (Number(outcome.outcomeNo) === 1 && ((currentHomeScoreAndSpecialOdds) > currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((currentHomeScoreAndSpecialOdds) === currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && ((currentHomeScoreAndSpecialOdds) < currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            var secondHalfHomeScoreAndSpecialOdds = secondHalfHomeScore + parseFloat(market.specialOddsValue);
            if (Number(outcome.outcomeNo) === 1 && ((secondHalfHomeScoreAndSpecialOdds) > secondHalfAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((secondHalfHomeScoreAndSpecialOdds) === secondHalfAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && ((secondHalfHomeScoreAndSpecialOdds) < secondHalfAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.oddEvenTotalGoals = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            var totalCurrentScore = (currentHomeScore + currentAwayScore);
            if (Number(outcome.outcomeNo) === 1 && (totalCurrentScore % 2 !== 0)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (totalCurrentScore % 2 === 0)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            var totalSecondHalfScore = (secondHalfHomeScore + secondHalfAwayScore);
            if (Number(outcome.outcomeNo) === 1 && (totalSecondHalfScore % 2 !== 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (totalSecondHalfScore % 2 === 0)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.bothTeamToScore = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore > 0 && currentAwayScore > 0) {
                    return MatchResultStatus.predictionWon;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore === 0 || currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentHomeScore > 0 && currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > 0 && currentAwayScore > 0) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore === 0 || currentAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.soccerHalfTimeFullTime = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId)) {
            if ((Number(outcome.outcomeNo) === 1 || Number(outcome.outcomeNo) === 2 || Number(outcome.outcomeNo) === 3) && (currentHomeScore > currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if ((Number(outcome.outcomeNo) === 4 || Number(outcome.outcomeNo) === 5 || Number(outcome.outcomeNo) === 6) && (currentHomeScore === currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if ((Number(outcome.outcomeNo) === 7 || Number(outcome.outcomeNo) === 8 || Number(outcome.outcomeNo) === 9) && (currentHomeScore < currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsHalfTime(event.scores.statusId)) {
            if ((Number(outcome.outcomeNo) === 1 || Number(outcome.outcomeNo) === 2 || Number(outcome.outcomeNo) === 3) && (currentHomeScore > currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if ((Number(outcome.outcomeNo) === 4 || Number(outcome.outcomeNo) === 5 || Number(outcome.outcomeNo) === 6) && (currentHomeScore === currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if ((Number(outcome.outcomeNo) === 7 || Number(outcome.outcomeNo) === 8 || Number(outcome.outcomeNo) === 9) && (currentHomeScore < currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.predictionLost;
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if ((firstHalfHomeScore > firstHalfAwayScore) && (currentHomeScore > currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((firstHalfHomeScore <= firstHalfAwayScore)) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if ((firstHalfHomeScore > firstHalfAwayScore) && (currentHomeScore === currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfHomeScore <= firstHalfAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if ((firstHalfHomeScore > firstHalfAwayScore) && (currentHomeScore < currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfHomeScore <= firstHalfAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 4) {
                if ((firstHalfHomeScore === firstHalfAwayScore) && (currentHomeScore > currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfHomeScore !== firstHalfAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 5) {
                if ((firstHalfHomeScore === firstHalfAwayScore) && (currentHomeScore === currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfHomeScore !== firstHalfAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 6) {
                if ((firstHalfHomeScore === firstHalfAwayScore) && (currentHomeScore < currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfHomeScore !== firstHalfAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 7) {
                if ((firstHalfHomeScore < firstHalfAwayScore) && (currentHomeScore > currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfHomeScore >= firstHalfAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 8) {
                if ((firstHalfHomeScore < firstHalfAwayScore) && (currentHomeScore === currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfHomeScore >= firstHalfAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 9) {
                if ((firstHalfHomeScore < firstHalfAwayScore) && (currentHomeScore < currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfHomeScore >= firstHalfAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (firstHalfHomeScore > firstHalfAwayScore) && (secondHalfHomeScore > secondHalfAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((firstHalfHomeScore > firstHalfAwayScore) && (secondHalfHomeScore === secondHalfAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && ((firstHalfHomeScore > firstHalfAwayScore) && (secondHalfHomeScore < secondHalfAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && ((firstHalfHomeScore === firstHalfAwayScore) && (secondHalfHomeScore > secondHalfAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 5 && ((firstHalfHomeScore === firstHalfAwayScore) && (secondHalfHomeScore === secondHalfAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 6 && ((firstHalfHomeScore === firstHalfAwayScore) && (secondHalfHomeScore < secondHalfAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 7 && ((firstHalfHomeScore < firstHalfAwayScore) && (secondHalfHomeScore > secondHalfAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 8 && ((firstHalfHomeScore < firstHalfAwayScore) && (secondHalfHomeScore === secondHalfAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 9 && ((firstHalfHomeScore < firstHalfAwayScore) && (secondHalfHomeScore < secondHalfAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.correctScore = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore === 1 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentHomeScore < 1 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 1 || currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore === 2 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentHomeScore < 2 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 2 || currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if (currentHomeScore === 2 && currentAwayScore === 1) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore <= 2 && currentAwayScore < 1) || (currentHomeScore < 2 && currentAwayScore <= 1)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 2 || currentAwayScore > 1) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 4) {
                if (currentHomeScore === 3 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 3 && currentAwayScore === 0)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 3 || currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 5) {
                if (currentHomeScore === 3 && currentAwayScore === 1) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore <= 3 && currentAwayScore < 1) || (currentHomeScore < 3 && currentAwayScore <= 1)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 3 || currentAwayScore > 1) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 6) {
                if (currentHomeScore === 3 && currentAwayScore === 2) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore <= 3 && currentAwayScore < 2) || (currentHomeScore < 3 && currentAwayScore <= 2)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 3 || currentAwayScore > 2) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 7) {
                if (currentHomeScore === 4 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 4 && currentAwayScore === 0)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 4 || currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 8) {
                if (currentHomeScore === 4 && currentAwayScore === 1) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore <= 4 && currentAwayScore < 1) || (currentHomeScore < 4 && currentAwayScore <= 1)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 4 || currentAwayScore > 1) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 9) {
                if (currentHomeScore === 4 && currentAwayScore === 2) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore <= 4 && currentAwayScore < 2) || (currentHomeScore < 4 && currentAwayScore <= 2)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 4 || currentAwayScore > 2) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 10) {
                if (currentHomeScore === 5 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 5 && currentAwayScore === 0)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 5 || currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 11) {
                if (currentHomeScore === 5 && currentAwayScore === 1) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore <= 5 && currentAwayScore < 1) || (currentHomeScore < 5 && currentAwayScore <= 1)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 5 || currentAwayScore > 1) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 12) {
                if (currentHomeScore === 6 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 6 && currentAwayScore === 0)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 6 || currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 13) {
                if (currentHomeScore === 0 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentHomeScore > 0 || currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 14) {
                if (currentHomeScore === 1 && currentAwayScore === 1) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore <= 1 && currentAwayScore < 1) || (currentHomeScore < 1 && currentAwayScore <= 1)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 1 || currentAwayScore > 1) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 15) {
                if (currentHomeScore === 2 && currentAwayScore === 2) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore <= 2 && currentAwayScore < 2) || (currentHomeScore < 2 && currentAwayScore <= 2)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 2 || currentAwayScore > 2) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 16) {
                if (currentHomeScore === 3 && currentAwayScore === 3) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore <= 3 && currentAwayScore < 3) || (currentHomeScore < 3 && currentAwayScore <= 3)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 3 || currentAwayScore > 3) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 17) {
                if (currentHomeScore === 0 && currentAwayScore === 1) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore === 0 && currentAwayScore < 1)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 0 || currentAwayScore > 1) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 18) {
                if (currentHomeScore === 0 && currentAwayScore === 2) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore === 0 && currentAwayScore < 2)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 0 || currentAwayScore > 2) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 19) {
                if (currentHomeScore === 1 && currentAwayScore === 2) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 1 && currentAwayScore <= 2) || (currentHomeScore <= 1 && currentAwayScore < 2)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 1 || currentAwayScore > 2) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 20) {
                if (currentHomeScore === 0 && currentAwayScore === 3) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore === 0 && currentAwayScore < 3)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 0 || currentAwayScore > 3) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 21) {
                if (currentHomeScore === 1 && currentAwayScore === 3) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 1 && currentAwayScore <= 3) || (currentHomeScore <= 1 && currentAwayScore < 3)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 1 || currentAwayScore > 3) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 22) {
                if (currentHomeScore === 2 && currentAwayScore === 3) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 2 && currentAwayScore <= 3) || (currentHomeScore <= 2 && currentAwayScore < 3)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 2 || currentAwayScore > 3) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 23) {
                if (currentHomeScore === 0 && currentAwayScore === 4) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore === 0 && currentAwayScore < 4)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 0 || currentAwayScore > 4) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 24) {
                if (currentHomeScore === 1 && currentAwayScore === 4) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 1 && currentAwayScore <= 4) || (currentHomeScore <= 1 && currentAwayScore < 4)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 1 || currentAwayScore > 4) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 25) {
                if (currentHomeScore === 2 && currentAwayScore === 4) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 2 && currentAwayScore <= 4) || (currentHomeScore <= 2 && currentAwayScore < 4)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 2 || currentAwayScore > 4) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 26) {
                if (currentHomeScore === 0 && currentAwayScore === 5) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore === 0 && currentAwayScore < 5)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 0 || currentAwayScore > 5) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 27) {
                if (currentHomeScore === 1 && currentAwayScore === 5) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 1 && currentAwayScore <= 5) || (currentHomeScore <= 1 && currentAwayScore < 5)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 1 || currentAwayScore > 5) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 28) {
                if (currentHomeScore === 0 && currentAwayScore === 6) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore === 0 && currentAwayScore < 6)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 0 || currentAwayScore > 6) {
                    return MatchResultStatus.predictionLost;
                }
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (secondHalfHomeScore === 1 && secondHalfAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (secondHalfHomeScore === 2 && secondHalfAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (secondHalfHomeScore === 2 && secondHalfAwayScore === 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && (secondHalfHomeScore === 3 && secondHalfAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 5 && (secondHalfHomeScore === 3 && secondHalfAwayScore === 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 6 && (secondHalfHomeScore === 3 && secondHalfAwayScore === 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 7 && (secondHalfHomeScore === 4 && secondHalfAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 8 && (secondHalfHomeScore === 4 && secondHalfAwayScore === 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 9 && (secondHalfHomeScore === 4 && secondHalfAwayScore === 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 10 && (secondHalfHomeScore === 5 && secondHalfAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 11 && (secondHalfHomeScore === 5 && secondHalfAwayScore === 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 12 && (secondHalfHomeScore === 6 && secondHalfAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 13 && (secondHalfHomeScore === 0 && secondHalfAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 14 && (secondHalfHomeScore === 1 && secondHalfAwayScore === 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 15 && (secondHalfHomeScore === 2 && secondHalfAwayScore === 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 16 && (secondHalfHomeScore === 3 && secondHalfAwayScore === 3)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 17 && (secondHalfHomeScore === 0 && secondHalfAwayScore === 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 18 && (secondHalfHomeScore === 0 && secondHalfAwayScore === 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 19 && (secondHalfHomeScore === 1 && secondHalfAwayScore === 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 20 && (secondHalfHomeScore === 0 && secondHalfAwayScore === 3)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 21 && (secondHalfHomeScore === 1 && secondHalfAwayScore === 3)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 22 && (secondHalfHomeScore === 2 && secondHalfAwayScore === 3)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 23 && (secondHalfHomeScore === 0 && secondHalfAwayScore === 4)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 24 && (secondHalfHomeScore === 1 && secondHalfAwayScore === 4)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 25 && (secondHalfHomeScore === 2 && secondHalfAwayScore === 4)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 26 && (secondHalfHomeScore === 0 && secondHalfAwayScore === 5)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 27 && (secondHalfHomeScore === 1 && secondHalfAwayScore === 5)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 28 && (secondHalfHomeScore === 0 && secondHalfAwayScore === 6)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.totalGoals = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            var totalCurrentScore = currentHomeScore + currentAwayScore;
            if (Number(outcome.outcomeNo) === 1) {
                if ((totalCurrentScore >= 0) && (totalCurrentScore < 2)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (totalCurrentScore >= 2) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if ((totalCurrentScore >= 2) && (totalCurrentScore < 4)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((totalCurrentScore < 2)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if ((totalCurrentScore >= 4)) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if ((totalCurrentScore >= 4) && (totalCurrentScore < 6)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((totalCurrentScore < 4)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if ((totalCurrentScore >= 6)) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 4) {
                if (totalCurrentScore >= 6) {
                    return MatchResultStatus.predictionWon;
                }
                else if (totalCurrentScore < 6) {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            var totalFinishedSecondScore = secondHalfHomeScore + secondHalfAwayScore;
            if (Number(outcome.outcomeNo) === 1 && (totalFinishedSecondScore >= 0) && (totalFinishedSecondScore < 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (totalFinishedSecondScore >= 2) && (totalFinishedSecondScore < 4)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (totalFinishedSecondScore >= 4) && (totalFinishedSecondScore < 6)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && (totalFinishedSecondScore >= 6)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.homeTeamOverUnder = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, secondHalfHomeScore = _a.secondHalfHomeScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if ((currentHomeScore < specialOddsValue)) {
                    return MatchResultStatus.continuesWinning;
                }
                return MatchResultStatus.predictionLost;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if ((currentHomeScore > specialOddsValue)) {
                    return MatchResultStatus.predictionWon;
                }
                return MatchResultStatus.continuesLosing;
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (currentHomeScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.awayTeamOverUnder = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentAwayScore = _a.currentAwayScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentAwayScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                return MatchResultStatus.predictionLost;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentAwayScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                return MatchResultStatus.continuesLosing;
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (currentAwayScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentAwayScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.homeToWinZero = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
                else if (currentAwayScore === 0 && currentHomeScore === 0) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (((currentHomeScore > currentAwayScore) && (currentAwayScore === 0))) {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentAwayScore > 0) {
                    return MatchResultStatus.predictionWon;
                }
                else if (currentHomeScore === 0 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                return MatchResultStatus.continuesLosing;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && ((secondHalfHomeScore > secondHalfAwayScore) && (secondHalfAwayScore === 0))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((secondHalfAwayScore >= secondHalfHomeScore) || (secondHalfAwayScore > 0))) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.awayToWinZero = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
                else if (currentAwayScore === 0 && currentHomeScore === 0) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (((currentAwayScore > currentHomeScore) && (currentHomeScore === 0))) {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore > 0) {
                    return MatchResultStatus.predictionWon;
                }
                else if (currentHomeScore === 0 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                return MatchResultStatus.continuesLosing;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && ((secondHalfAwayScore > secondHalfHomeScore) && (secondHalfHomeScore === 0))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((secondHalfHomeScore >= secondHalfAwayScore) || (secondHalfHomeScore > 0))) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.highestScoringHalf = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId) || EventStatusManager.checkIsHalfTime(event.scores.statusId)) {
            return MatchResultStatus.continues;
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsSecondHalf(event.scores.statusId)) {
            var firstGoalTotal = firstHalfHomeScore + firstHalfAwayScore;
            var secondCurrentGoalTotal = (currentHomeScore + currentAwayScore) - (firstHalfHomeScore + firstHalfAwayScore);
            if (Number(outcome.outcomeNo) === 1) {
                if (firstGoalTotal <= secondCurrentGoalTotal) {
                    return MatchResultStatus.predictionLost;
                }
                else if (firstGoalTotal > secondCurrentGoalTotal) {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstGoalTotal === secondCurrentGoalTotal) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstGoalTotal < secondCurrentGoalTotal) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 3 && (firstGoalTotal < secondCurrentGoalTotal)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            var firstGoalTotal = firstHalfHomeScore + firstHalfAwayScore;
            var secondGoalTotal = (secondHalfHomeScore + secondHalfAwayScore) - (firstHalfHomeScore + firstHalfAwayScore);
            if (Number(outcome.outcomeNo) === 1 && (firstGoalTotal > secondGoalTotal)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (firstGoalTotal === secondGoalTotal)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (firstGoalTotal < secondGoalTotal)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.highestScoringHalfHome = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, secondHalfHomeScore = _a.secondHalfHomeScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var firstHalfHomeScore = parseInt(event.scores.homeTeam.firstHalf);
        var firstHomeCurrentGoalTotal = (currentHomeScore - firstHalfHomeScore);
        var secondHomeGoalTotal = (secondHalfHomeScore - firstHalfHomeScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId) || EventStatusManager.checkIsHalfTime(event.scores.statusId)) {
            return MatchResultStatus.continues;
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfHomeScore <= firstHomeCurrentGoalTotal) {
                    return MatchResultStatus.predictionLost;
                }
                else if (firstHalfHomeScore > firstHomeCurrentGoalTotal) {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfHomeScore === firstHomeCurrentGoalTotal) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfHomeScore < firstHomeCurrentGoalTotal) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 3 && (firstHalfHomeScore < firstHomeCurrentGoalTotal)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (firstHalfHomeScore > secondHomeGoalTotal)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (firstHalfHomeScore === secondHomeGoalTotal)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (firstHalfHomeScore < secondHomeGoalTotal)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.highestScoringHalfAway = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentAwayScore = _a.currentAwayScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var firstHalfAwayScore = parseInt(event.scores.awayTeam.firstHalf);
        var secondAwayCurrentGoalTotal = (currentAwayScore - firstHalfAwayScore);
        var secondAwayGoalTotal = (secondHalfAwayScore - firstHalfAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId) || EventStatusManager.checkIsHalfTime(event.scores.statusId)) {
            return MatchResultStatus.continues;
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfAwayScore <= secondAwayCurrentGoalTotal) {
                    return MatchResultStatus.predictionLost;
                }
                else if (firstHalfAwayScore > secondAwayCurrentGoalTotal) {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfAwayScore === secondAwayCurrentGoalTotal) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfAwayScore < secondAwayCurrentGoalTotal) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 3 && (firstHalfAwayScore < secondAwayCurrentGoalTotal)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (firstHalfAwayScore > secondAwayGoalTotal)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (firstHalfAwayScore === secondAwayGoalTotal)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (firstHalfAwayScore < secondAwayGoalTotal)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.cleanSheetHomeTeam = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentAwayScore = _a.currentAwayScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentAwayScore > 0) {
                    return MatchResultStatus.predictionWon;
                }
                else if (currentAwayScore === 0) {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (secondHalfAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (secondHalfAwayScore > 0)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.cleanSheetAwayTeam = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, secondHalfHomeScore = _a.secondHalfHomeScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentHomeScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore > 0) {
                    return MatchResultStatus.predictionWon;
                }
                else if (currentHomeScore === 0) {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (secondHalfHomeScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (secondHalfHomeScore > 0)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.soccerWinningMargin = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore >= (currentAwayScore + 3)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentHomeScore === (currentAwayScore + 2)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && currentHomeScore === (currentAwayScore + 1)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 4 && currentAwayScore === (currentHomeScore + 1)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 5 && currentAwayScore === (currentHomeScore + 2)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 6 && currentAwayScore === (currentHomeScore + 3)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 7 && currentHomeScore === currentAwayScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && secondHalfHomeScore >= (secondHalfAwayScore + 3)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && secondHalfHomeScore === (secondHalfAwayScore + 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && secondHalfHomeScore === (secondHalfAwayScore + 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && secondHalfHomeScore === (secondHalfAwayScore + 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 5 && secondHalfHomeScore === (secondHalfAwayScore + 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 6 && secondHalfHomeScore === (secondHalfAwayScore + 3)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 7 && secondHalfHomeScore === secondHalfAwayScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.overTimeTotalGoalsOverUnder = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var halfTotalScores = secondHalfHomeScore + secondHalfAwayScore;
        var currentTotalScores = currentHomeScore + currentAwayScore;
        var diffScores = currentTotalScores - halfTotalScores;
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsSecondHalf(event.scores.statusId)) {
            return MatchResultStatus.continues;
        }
        else if (EventStatusManager.checkIsOnlyExtraTime(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (specialOddsValue > diffScores) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (specialOddsValue < diffScores) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if ((specialOddsValue < diffScores)) {
                    return MatchResultStatus.predictionWon;
                }
                else if ((specialOddsValue > diffScores)) {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsPenaltyToBegin(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (specialOddsValue > diffScores)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (specialOddsValue < diffScores)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.whichTeamToScore = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
                else if (currentHomeScore > 0 && (currentAwayScore === 0)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentHomeScore === 0 && (currentAwayScore === 0)) {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
                else if (currentAwayScore > 0 && (currentHomeScore === 0)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentAwayScore === 0 && (currentHomeScore === 0)) {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if (currentHomeScore === 0 || currentAwayScore === 0) {
                    return MatchResultStatus.continuesLosing;
                }
                else if ((currentHomeScore > 0 && currentAwayScore > 0)) {
                    return MatchResultStatus.predictionWon;
                }
            }
            else if (Number(outcome.outcomeNo) === 4) {
                if ((currentAwayScore === 0 && currentHomeScore === 0)) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (secondHalfHomeScore > 0 && (secondHalfAwayScore === 0))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (secondHalfHomeScore === 0 && secondHalfAwayScore > 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (secondHalfHomeScore > 0 && secondHalfAwayScore > 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && (secondHalfAwayScore === 0 && secondHalfHomeScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.homeToWinBothHalves = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var secondHalfHomeGoalsCurrentCount = (currentHomeScore - firstHalfHomeScore);
        var secondHalfAwayGoalsCurrentCount = (currentAwayScore - firstHalfAwayScore);
        var secondHalfHomeGoalsCount = (secondHalfHomeScore - firstHalfHomeScore);
        var secondHalfAwayGoalsCount = (secondHalfAwayScore - firstHalfAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if ((currentHomeScore > currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                return MatchResultStatus.continuesLosing;
            }
            if (Number(outcome.outcomeNo) === 2) {
                if (((currentHomeScore <= currentAwayScore))) {
                    return MatchResultStatus.continuesWinning;
                }
                return MatchResultStatus.continuesLosing;
            }
        }
        else if (EventStatusManager.checkIsHalfTime(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfHomeScore > firstHalfAwayScore) {
                    return MatchResultStatus.continuesWinning;
                }
                return MatchResultStatus.predictionLost;
            }
            if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfHomeScore <= firstHalfAwayScore) {
                    return MatchResultStatus.predictionWon;
                }
                return MatchResultStatus.continuesLosing;
            }
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfHomeScore <= firstHalfAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                else if ((firstHalfHomeScore > firstHalfAwayScore) && (secondHalfHomeGoalsCurrentCount > secondHalfAwayGoalsCurrentCount)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((firstHalfHomeScore > firstHalfAwayScore) && (secondHalfHomeGoalsCurrentCount <= secondHalfAwayGoalsCurrentCount)) {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfHomeScore > firstHalfAwayScore) {
                    if (secondHalfHomeGoalsCurrentCount <= secondHalfAwayGoalsCurrentCount) {
                        return MatchResultStatus.continuesWinning;
                    }
                    return MatchResultStatus.continuesLosing;
                }
                else if ((firstHalfHomeScore <= firstHalfAwayScore)) {
                    return MatchResultStatus.predictionWon;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfHomeScore <= firstHalfAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                else if ((firstHalfHomeScore > firstHalfAwayScore) && (secondHalfHomeGoalsCount > secondHalfAwayGoalsCount)) {
                    return MatchResultStatus.predictionWon;
                }
                else if ((firstHalfHomeScore > firstHalfAwayScore) && (secondHalfHomeGoalsCount <= secondHalfAwayGoalsCount)) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if ((firstHalfHomeScore <= firstHalfAwayScore) && (secondHalfHomeGoalsCount <= secondHalfAwayGoalsCount)) {
                    return MatchResultStatus.predictionWon;
                }
                else if (firstHalfHomeScore > firstHalfAwayScore) {
                    if (secondHalfHomeGoalsCount > secondHalfAwayGoalsCount) {
                        return MatchResultStatus.predictionLost;
                    }
                    return MatchResultStatus.predictionWon;
                }
            }
        }
    };
    // @ts-ignore
    MarketCalculator.awayToWinBothHalves = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var secondHalfHomeGoalsCurrentCount = (currentHomeScore - firstHalfHomeScore);
        var secondHalfAwayGoalsCurrentCount = (currentAwayScore - firstHalfAwayScore);
        var secondHalfHomeGoalsCount = (currentHomeScore - firstHalfHomeScore);
        var secondHalfAwayGoalsCount = (currentAwayScore - firstHalfAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore >= currentAwayScore) {
                    return MatchResultStatus.continuesLosing;
                }
                else {
                    return MatchResultStatus.continuesWinning;
                }
            }
            if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore < currentAwayScore) {
                    return MatchResultStatus.continuesLosing;
                }
                else {
                    return MatchResultStatus.continuesWinning;
                }
            }
        }
        else if (EventStatusManager.checkIsHalfTime(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfAwayScore > firstHalfHomeScore) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfAwayScore <= firstHalfHomeScore) {
                    return MatchResultStatus.predictionWon;
                }
                return MatchResultStatus.continuesLosing;
            }
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfAwayScore <= firstHalfHomeScore) {
                    return MatchResultStatus.predictionLost;
                }
                else if ((firstHalfAwayScore > firstHalfHomeScore) && (secondHalfAwayGoalsCurrentCount > secondHalfHomeGoalsCurrentCount)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((firstHalfAwayScore > firstHalfHomeScore) && (secondHalfAwayGoalsCurrentCount <= secondHalfHomeGoalsCurrentCount)) {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfAwayScore > firstHalfHomeScore) {
                    if (secondHalfAwayGoalsCurrentCount <= secondHalfHomeGoalsCurrentCount) {
                        return MatchResultStatus.continuesWinning;
                    }
                    return MatchResultStatus.continuesLosing;
                }
                else if (firstHalfAwayScore <= firstHalfHomeScore) {
                    return MatchResultStatus.predictionWon;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfAwayScore <= firstHalfHomeScore) {
                    return MatchResultStatus.predictionLost;
                }
                else if ((firstHalfAwayScore > firstHalfHomeScore) && (secondHalfAwayGoalsCount > secondHalfHomeGoalsCount)) {
                    return MatchResultStatus.predictionWon;
                }
                else if ((firstHalfAwayScore > firstHalfHomeScore) && (secondHalfAwayGoalsCount <= secondHalfHomeGoalsCount)) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if ((firstHalfAwayScore <= firstHalfHomeScore) && (secondHalfAwayGoalsCount <= secondHalfHomeGoalsCount)) {
                    return MatchResultStatus.predictionWon;
                }
                else if (firstHalfAwayScore > firstHalfHomeScore) {
                    if (secondHalfAwayGoalsCount > secondHalfHomeGoalsCount) {
                        return MatchResultStatus.predictionLost;
                    }
                    return MatchResultStatus.predictionWon;
                }
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.soccerMatchBetTotals = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        var totalCurrentScore = (currentHomeScore + currentAwayScore);
        var totalSecondHalfScore = (secondHalfHomeScore + secondHalfAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if ((currentHomeScore > currentAwayScore) && (totalCurrentScore < specialOddsValue)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore <= currentAwayScore) && (totalCurrentScore < specialOddsValue)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if ((totalCurrentScore > specialOddsValue)) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore === currentAwayScore && (totalCurrentScore < specialOddsValue)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore !== currentAwayScore) && (totalCurrentScore < specialOddsValue)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if ((totalCurrentScore > specialOddsValue)) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if ((currentHomeScore < currentAwayScore && (totalCurrentScore < specialOddsValue))) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore >= currentAwayScore) && (totalCurrentScore < specialOddsValue)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if ((totalCurrentScore > specialOddsValue)) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 4) {
                if (((currentHomeScore > currentAwayScore) && (totalCurrentScore > specialOddsValue))) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (((currentHomeScore <= currentAwayScore) && (totalCurrentScore > specialOddsValue)) || (totalCurrentScore < specialOddsValue)) {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 5) {
                if (((currentHomeScore === currentAwayScore) && (totalCurrentScore > specialOddsValue))) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (((currentHomeScore !== currentAwayScore) && (totalCurrentScore > specialOddsValue)) || (totalCurrentScore < specialOddsValue)) {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 6) {
                if (((currentHomeScore < currentAwayScore) && (totalCurrentScore > specialOddsValue))) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (((currentHomeScore >= currentAwayScore) && (totalCurrentScore > specialOddsValue)) || (totalCurrentScore < specialOddsValue)) {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (secondHalfHomeScore > secondHalfAwayScore) && (totalSecondHalfScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (secondHalfHomeScore === secondHalfAwayScore && (totalSecondHalfScore < specialOddsValue))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (secondHalfHomeScore < secondHalfAwayScore && (totalSecondHalfScore < specialOddsValue))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && ((secondHalfHomeScore > secondHalfAwayScore) && (totalSecondHalfScore > specialOddsValue))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 5 && ((secondHalfHomeScore === secondHalfAwayScore) && (totalSecondHalfScore > specialOddsValue))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 6 && ((secondHalfHomeScore < secondHalfAwayScore) && (totalSecondHalfScore > specialOddsValue))) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.soccerFirstGoal = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > 0 && currentAwayScore < 1) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore < 1 && currentAwayScore < 1) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 3 && currentHomeScore < 1 && currentAwayScore > 0) {
                return MatchResultStatus.predictionWon;
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > 0 && currentAwayScore < 1) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore < 1 && currentAwayScore < 1) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 3 && currentHomeScore < 1 && currentAwayScore > 0) {
                return MatchResultStatus.predictionWon;
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.soccerOvertimeWinner = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var homeOverTimeScore = currentHomeScore - secondHalfHomeScore;
        var awayOverTimeScore = currentAwayScore - secondHalfAwayScore;
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsOnlyExtraTime(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && homeOverTimeScore > awayOverTimeScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeOverTimeScore === awayOverTimeScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayOverTimeScore > homeOverTimeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedOnlyExtraTime(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeOverTimeScore > awayOverTimeScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeOverTimeScore === awayOverTimeScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayOverTimeScore > homeOverTimeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.soccerCornersAggregated = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        // @ts-ignore
        var _b = MarketHelper.getCurrentStatistics(statisticsMap[event.eventId] || event.statistics), currentHomeCorner = _b.currentHomeCorner, currentAwayCorner = _b.currentAwayCorner, totalCorners = _b.totalCorners, statisticsError = _b.statisticsError;
        if (error || statisticsError) {
            return MatchResultStatus.notStarted;
        }
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (totalCorners < 9) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalCorners >= 9 && totalCorners < 12) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (totalCorners >= 12) {
                    return MatchResultStatus.predictionLost;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if (totalCorners >= 12) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && totalCorners < 9) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && totalCorners >= 9 && totalCorners < 12) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && totalCorners >= 12) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.soccerFirstHalfCornersAggregated = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        // @ts-ignore
        var _b = MarketHelper.getCurrentStatistics(statisticsMap[event.eventId] || event.statistics), currentHomeCorner = _b.currentHomeCorner, currentAwayCorner = _b.currentAwayCorner, totalCorners = _b.totalCorners, statisticsError = _b.statisticsError;
        if (error || statisticsError) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (totalCorners < 5) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalCorners >= 5 && totalCorners < 7) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (totalCorners >= 7) {
                    return MatchResultStatus.predictionLost;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if (totalCorners >= 7) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (totalCorners < 5) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continues;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalCorners < 5) {
                    return MatchResultStatus.predictionLost;
                }
                else {
                    return MatchResultStatus.continues;
                }
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if (totalCorners < 7) {
                    return MatchResultStatus.predictionLost;
                }
                else {
                    return MatchResultStatus.continues;
                }
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.soccerTotalCornerOverUnder = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        // @ts-ignore
        var _b = MarketHelper.getCurrentStatistics(statisticsMap[event.eventId] || event.statistics), currentHomeCorner = _b.currentHomeCorner, currentAwayCorner = _b.currentAwayCorner, totalCorners = _b.totalCorners, statisticsError = _b.statisticsError;
        if (error || statisticsError) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (totalCorners > specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                else if (totalCorners < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalCorners < specialOddsValue) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (totalCorners > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
            }
            return MatchResultStatus.continues;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (totalCorners < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (totalCorners > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.soccerFirstHalfCornerOverUnder = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        // @ts-ignore
        var _b = MarketHelper.getCurrentStatistics(statisticsMap[event.eventId] || event.statistics), currentHomeCorner = _b.currentHomeCorner, currentAwayCorner = _b.currentAwayCorner, totalCorners = _b.totalCorners, statisticsError = _b.statisticsError;
        if (error || statisticsError) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (totalCorners < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalCorners > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (totalCorners < specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continues;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalCorners < specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                else {
                    return MatchResultStatus.continues;
                }
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.soccerCornersMatchBet = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        // @ts-ignore
        var _b = MarketHelper.getCurrentStatistics(statisticsMap[event.eventId] || event.statistics), currentHomeCorner = _b.currentHomeCorner, currentAwayCorner = _b.currentAwayCorner, totalCorners = _b.totalCorners, statisticsError = _b.statisticsError;
        if (error || statisticsError) {
            return MatchResultStatus.notStarted;
        }
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeCorner > currentAwayCorner) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentHomeCorner === currentAwayCorner) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && currentAwayCorner > currentHomeCorner) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeCorner > currentAwayCorner) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentHomeCorner === currentAwayCorner) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && currentAwayCorner > currentHomeCorner) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.soccerFirstHalfCornersMatchBet = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        // @ts-ignore
        var _b = MarketHelper.getCurrentStatistics(statisticsMap[event.eventId] || event.statistics), currentHomeCorner = _b.currentHomeCorner, currentAwayCorner = _b.currentAwayCorner, totalCorners = _b.totalCorners, statisticsError = _b.statisticsError;
        if (error || statisticsError) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeCorner > currentAwayCorner) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentHomeCorner === currentAwayCorner) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && currentAwayCorner > currentHomeCorner) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeCorner < 1 && currentAwayCorner < 1) {
                return MatchResultStatus.predictionLost;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentHomeCorner < 1 && currentAwayCorner < 1) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && currentHomeCorner < 1 && currentAwayCorner < 1) {
                return MatchResultStatus.predictionLost;
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.soccerFirstCorner = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        // @ts-ignore
        var _b = MarketHelper.getCurrentStatistics(statisticsMap[event.eventId] || event.statistics), currentHomeCorner = _b.currentHomeCorner, currentAwayCorner = _b.currentAwayCorner, totalCorners = _b.totalCorners, statisticsError = _b.statisticsError;
        if (error || statisticsError) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeCorner > 0 && currentAwayCorner < 1) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if (currentHomeScore < 1 && currentAwayScore < 1) {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2 && currentHomeCorner < 1 && currentAwayCorner > 0) {
                return MatchResultStatus.predictionWon;
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeCorner > 0 && currentAwayCorner < 1) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if (currentHomeScore < 1 && currentAwayScore < 1) {
                    return MatchResultStatus.predictionWon;
                }
            }
            else if (Number(outcome.outcomeNo) === 2 && currentHomeCorner < 1 && currentAwayCorner > 0) {
                return MatchResultStatus.predictionWon;
            }
        }
        return MatchResultStatus.continues;
    };
    // if (Number(outcome.outcomeNo) === 1 && currentHomeScore > 0 && currentAwayScore < 1) {
    //     return MatchResultStatus.predictionWon;
    // } else if (Number(outcome.outcomeNo) === 2) {
    //     if (currentHomeScore < 1 && currentAwayScore < 1) {
    //         return MatchResultStatus.continuesWinning;
    //     } else {
    //         return MatchResultStatus.predictionLost;
    //     }
    // } else if (Number(outcome.outcomeNo) === 3 && currentHomeScore < 1 && currentAwayScore > 0) {
    //     return MatchResultStatus.predictionWon;
    // }
    // return MatchResultStatus.continues;
    // @ts-ignore
    MarketCalculator.secondHalfTotalGoalsRange = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId) || EventStatusManager.checkIsHalfTime(event.scores.statusId)) {
            return MatchResultStatus.continues;
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsSecondHalf(event.scores.statusId)) {
            var diffHalfCurrentScore = ((currentHomeScore + currentAwayScore) - (firstHalfHomeScore + firstHalfAwayScore));
            if (Number(outcome.outcomeNo) === 1) {
                if (diffHalfCurrentScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (diffHalfCurrentScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continues;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if ((diffHalfCurrentScore === 1)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((diffHalfCurrentScore > 1)) {
                    return MatchResultStatus.predictionLost;
                }
                else if ((diffHalfCurrentScore < 1)) {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if ((diffHalfCurrentScore >= 2)) {
                    return MatchResultStatus.predictionWon;
                }
                else if ((diffHalfCurrentScore < 2)) {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            var diffHalfSecondHalfScore = (secondHalfHomeScore + secondHalfAwayScore) - (firstHalfHomeScore + firstHalfAwayScore);
            if (Number(outcome.outcomeNo) === 1 && (diffHalfSecondHalfScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (diffHalfSecondHalfScore === 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (diffHalfSecondHalfScore >= 2)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.secondHalfResult = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId) || EventStatusManager.checkIsHalfTime(event.scores.statusId)) {
            return MatchResultStatus.continues;
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsSecondHalf(event.scores.statusId)) {
            var homeSecondHalfCurrentScore = (currentHomeScore - firstHalfHomeScore);
            var awaySecondHalfCurrentScore = (currentAwayScore - firstHalfAwayScore);
            if (Number(outcome.outcomeNo) === 1 && (homeSecondHalfCurrentScore > awaySecondHalfCurrentScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (homeSecondHalfCurrentScore === awaySecondHalfCurrentScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && (awaySecondHalfCurrentScore > homeSecondHalfCurrentScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            var homeSecondHalfScore = (secondHalfHomeScore - firstHalfHomeScore);
            var awaySecondHalfScore = (secondHalfAwayScore - firstHalfAwayScore);
            if (Number(outcome.outcomeNo) === 1 && (homeSecondHalfScore > awaySecondHalfScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (homeSecondHalfScore === awaySecondHalfScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (awaySecondHalfScore > homeSecondHalfScore)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstHalfResult = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentHomeScore === currentAwayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && currentAwayScore > currentHomeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && firstHalfHomeScore > firstHalfAwayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && firstHalfHomeScore === firstHalfAwayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && firstHalfAwayScore > firstHalfHomeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstHalfDoubleChance = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (currentHomeScore > currentAwayScore || currentHomeScore === currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore > currentAwayScore || currentAwayScore > currentHomeScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && (currentAwayScore > currentHomeScore || currentHomeScore === currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (firstHalfHomeScore > firstHalfAwayScore || firstHalfHomeScore === firstHalfAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (firstHalfHomeScore > firstHalfAwayScore || firstHalfAwayScore > firstHalfHomeScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (firstHalfAwayScore > firstHalfHomeScore || firstHalfHomeScore === firstHalfAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstHalfTotalGoalsUnderOver = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (((currentHomeScore + currentAwayScore) < specialOddsValue)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore + currentAwayScore) > specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (((currentHomeScore + currentAwayScore) > specialOddsValue)) {
                    return MatchResultStatus.predictionWon;
                }
                else if ((currentHomeScore + currentAwayScore) < specialOddsValue) {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) &&
            EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId) ||
            EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && ((firstHalfHomeScore + firstHalfAwayScore) < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((firstHalfHomeScore + firstHalfAwayScore) > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstHalfTotalGoalsRange = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if ((currentHomeScore + currentAwayScore === 0)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore + currentAwayScore > 0)) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if ((currentHomeScore + currentAwayScore === 1)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore + currentAwayScore > 1)) {
                    return MatchResultStatus.predictionLost;
                }
                else if ((currentHomeScore + currentAwayScore < 1)) {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if ((currentHomeScore + currentAwayScore >= 2)) {
                    return MatchResultStatus.predictionWon;
                }
                else if ((currentHomeScore + currentAwayScore < 2)) {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) &&
            EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId) ||
            EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (firstHalfHomeScore + firstHalfAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (firstHalfHomeScore + firstHalfAwayScore === 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (firstHalfHomeScore + firstHalfAwayScore >= 2)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.oddEventTotalCorner = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentStatistics(statisticsMap[event.eventId] || event.statistics), totalCorners = _a.totalCorners, statisticsError = _a.statisticsError;
        if (statisticsError) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (totalCorners % 2 !== 0)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (totalCorners % 2 === 0)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (totalCorners % 2 !== 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (totalCorners % 2 === 0)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstHalfCorrectScore = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore === 0 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentHomeScore > 0 || currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore === 1 && currentAwayScore === 1) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore <= 1 && currentAwayScore < 1) || (currentHomeScore < 1 && currentAwayScore <= 1)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 1 || currentAwayScore > 1) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if (currentHomeScore === 2 && currentAwayScore === 2) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore <= 2 && currentAwayScore < 2) || (currentHomeScore < 2 && currentAwayScore <= 2)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 2 || currentAwayScore > 2) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 4) {
                if (currentHomeScore === 1 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 1 && currentAwayScore === 0)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 1 || currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 5) {
                if (currentHomeScore === 2 && currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 2 && currentAwayScore === 0)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 2 || currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 6) {
                if (currentHomeScore === 2 && currentAwayScore === 1) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 2 && currentAwayScore === 1)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 2 || currentAwayScore > 1) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 7) {
                if (currentHomeScore === 0 && currentAwayScore === 1) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore === 0 && currentAwayScore < 1)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 0 || currentAwayScore > 1) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 8) {
                if (currentHomeScore === 0 && currentAwayScore === 2) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore === 0 && currentAwayScore < 2)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 0 || currentAwayScore > 2) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 9) {
                if (currentHomeScore === 1 && currentAwayScore === 2) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentHomeScore < 1 && currentAwayScore <= 2) || (currentHomeScore <= 1 && currentAwayScore < 2)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeScore > 1 || currentAwayScore > 2) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 10) {
                if (currentHomeScore > 2 || currentAwayScore > 2) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (firstHalfHomeScore === 0 && firstHalfAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (firstHalfHomeScore === 1 && firstHalfAwayScore === 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (firstHalfHomeScore === 2 && firstHalfAwayScore === 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && (firstHalfHomeScore === 1 && firstHalfAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 5 && (firstHalfHomeScore === 2 && firstHalfAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 6 && (firstHalfHomeScore === 2 && firstHalfAwayScore === 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 7 && (firstHalfHomeScore === 0 && firstHalfAwayScore === 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 8 && (firstHalfHomeScore === 0 && firstHalfAwayScore === 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 9 && (firstHalfHomeScore === 1 && firstHalfAwayScore === 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 10 && (firstHalfHomeScore > 2 || firstHalfAwayScore > 2)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstHalfOddEvent = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId)) {
            var totalCurrentScore = (currentHomeScore + currentAwayScore);
            if (Number(outcome.outcomeNo) === 1 && (totalCurrentScore % 2 !== 0)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (totalCurrentScore % 2 === 0)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId)) {
            var totalfirstHalfScore = (firstHalfHomeScore + firstHalfAwayScore);
            if (Number(outcome.outcomeNo) === 1 && (totalfirstHalfScore % 2 !== 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (totalfirstHalfScore % 2 === 0)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.totalAndBothTeamToScore = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = MarketCalculator.isNullOrEmptyOrUndefined(market.specialOddsValue) ? 1 : parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            var totalCurrentScore = (currentHomeScore + currentAwayScore);
            if (Number(outcome.outcomeNo) === 1) {
                if (totalCurrentScore > specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                else if (totalCurrentScore < specialOddsValue && (currentHomeScore > 0 && currentAwayScore > 0)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (totalCurrentScore < specialOddsValue && (currentHomeScore === 0 || currentAwayScore === 0)) {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalCurrentScore > specialOddsValue && (currentHomeScore > 0 && currentAwayScore > 0)) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if (totalCurrentScore < specialOddsValue && (currentHomeScore === 0 || currentAwayScore === 0)) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 4) {
                if (currentHomeScore > 0 && currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
                else if (totalCurrentScore > specialOddsValue && (currentHomeScore === 0 || currentAwayScore === 0)) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            var totalCurrentScore = (currentHomeScore + currentAwayScore);
            if (Number(outcome.outcomeNo) === 1) {
                if (totalCurrentScore < specialOddsValue && (currentHomeScore > 0 && currentAwayScore > 0)) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalCurrentScore > specialOddsValue && (currentHomeScore > 0 && currentAwayScore > 0)) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if (totalCurrentScore < specialOddsValue && (currentHomeScore === 0 || currentAwayScore === 0)) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 4) {
                if (currentHomeScore > 0 && currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
                else if (totalCurrentScore > specialOddsValue && (currentHomeScore === 0 || currentAwayScore === 0)) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstHalfBothTeamsToScore = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore > 0 && currentAwayScore > 0) {
                    return MatchResultStatus.predictionWon;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore === 0 || currentAwayScore === 0) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && firstHalfHomeScore > 0 && firstHalfAwayScore > 0) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (firstHalfHomeScore === 0 || firstHalfAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.homeTeamTotalCornersOverUnder = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        // @ts-ignore
        var _b = MarketHelper.getCurrentStatistics(statisticsMap[event.eventId] || event.statistics), currentHomeCorner = _b.currentHomeCorner, currentAwayCorner = _b.currentAwayCorner, totalCorners = _b.totalCorners, statisticsError = _b.statisticsError;
        if (error || statisticsError) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeCorner > specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                else if (currentHomeCorner < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeCorner < specialOddsValue) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentHomeCorner > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
            }
            return MatchResultStatus.continues;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (currentHomeCorner < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeCorner > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.awayTeamTotalCornersOverUnder = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        // @ts-ignore
        var _b = MarketHelper.getCurrentStatistics(statisticsMap[event.eventId] || event.statistics), currentHomeCorner = _b.currentHomeCorner, currentAwayCorner = _b.currentAwayCorner, totalCorners = _b.totalCorners, statisticsError = _b.statisticsError;
        if (error || statisticsError) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentAwayCorner > specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                else if (currentAwayCorner < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentAwayCorner < specialOddsValue) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (currentAwayCorner > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
            }
            return MatchResultStatus.continues;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (currentAwayCorner < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentAwayCorner > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstHalfHomeTeamTotalGoalsOverUnder = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), firstHalfHomeScore = _a.firstHalfHomeScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = MarketCalculator.isNullOrEmptyOrUndefined(market.specialOddsValue) ? 1 : parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfHomeScore > specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                else if (firstHalfHomeScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfHomeScore < specialOddsValue) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (firstHalfHomeScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (firstHalfHomeScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (firstHalfHomeScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstHalfAwayTeamTotalGoalsOverUnder = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), firstHalfAwayScore = _a.firstHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = MarketCalculator.isNullOrEmptyOrUndefined(market.specialOddsValue) ? 1 : parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfAwayScore > specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                else if (firstHalfAwayScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfAwayScore < specialOddsValue) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (firstHalfAwayScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (firstHalfAwayScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (firstHalfAwayScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.matchResultsAndBothTeamsToScore = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentHomeScore > currentAwayScore && (currentHomeScore === 0 || currentAwayScore === 0)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.predictionLost;
            }
            else if (Number(outcome.outcomeNo) === 3 && currentHomeScore === currentAwayScore && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 4 && currentHomeScore === currentAwayScore && (currentHomeScore === 0 || currentAwayScore === 0)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 4 && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.predictionLost;
            }
            else if (Number(outcome.outcomeNo) === 5 && currentHomeScore < currentAwayScore && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 6 && currentHomeScore < currentAwayScore && (currentHomeScore === 0 || currentAwayScore === 0)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 6 && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.predictionLost;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && secondHalfHomeScore > secondHalfAwayScore && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && secondHalfHomeScore > secondHalfAwayScore && (currentHomeScore === 0 || currentAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && secondHalfHomeScore === secondHalfAwayScore && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && secondHalfHomeScore === secondHalfAwayScore && (currentHomeScore === 0 || currentAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 5 && secondHalfAwayScore > secondHalfHomeScore && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 6 && secondHalfAwayScore > secondHalfHomeScore && (currentHomeScore === 0 || currentAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstHalfMatchResultsAndBothTeamsToScore = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore > currentAwayScore && (currentHomeScore === 0 || currentAwayScore === 0)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentHomeScore > 0 && currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 3 && currentHomeScore === currentAwayScore && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 4) {
                if (currentHomeScore === currentAwayScore && (currentHomeScore === 0 || currentAwayScore === 0)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentHomeScore > 0 && currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 5 && currentHomeScore < currentAwayScore && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 6) {
                if (currentHomeScore < currentAwayScore && (currentHomeScore === 0 || currentAwayScore === 0)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentHomeScore > 0 && currentAwayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && firstHalfHomeScore > firstHalfAwayScore && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && firstHalfHomeScore > firstHalfAwayScore && (currentHomeScore === 0 || currentAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && firstHalfHomeScore === firstHalfAwayScore && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && firstHalfHomeScore === firstHalfAwayScore && (currentHomeScore === 0 || currentAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 5 && firstHalfAwayScore > firstHalfHomeScore && (currentHomeScore > 0 && currentAwayScore > 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 6 && firstHalfAwayScore > firstHalfHomeScore && (currentHomeScore === 0 || currentAwayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstHalfResultAndOverUnder = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        var totalFirstCurrentScore = (firstHalfHomeScore + firstHalfAwayScore);
        var totalFirstHalfScore = (firstHalfHomeScore + firstHalfAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if ((firstHalfHomeScore > firstHalfAwayScore) && (totalFirstCurrentScore < specialOddsValue)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((totalFirstCurrentScore > specialOddsValue)) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfHomeScore === firstHalfAwayScore && (totalFirstCurrentScore < specialOddsValue)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((totalFirstCurrentScore > specialOddsValue)) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if (firstHalfHomeScore === firstHalfAwayScore && (totalFirstCurrentScore < specialOddsValue)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((totalFirstCurrentScore > specialOddsValue)) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 4) {
                if ((firstHalfHomeScore < firstHalfAwayScore && (totalFirstCurrentScore < specialOddsValue))) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((firstHalfHomeScore >= firstHalfAwayScore || totalFirstCurrentScore > specialOddsValue)) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 5) {
                if (((firstHalfHomeScore === firstHalfAwayScore) && (totalFirstCurrentScore > specialOddsValue))) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (((firstHalfHomeScore !== firstHalfAwayScore) || (totalFirstCurrentScore < specialOddsValue))) {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 6) {
                if (((firstHalfHomeScore < firstHalfAwayScore) && (totalFirstCurrentScore > specialOddsValue))) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (((firstHalfHomeScore >= firstHalfAwayScore) || (totalFirstCurrentScore < specialOddsValue))) {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (firstHalfHomeScore > firstHalfAwayScore) && (totalFirstCurrentScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (firstHalfHomeScore === firstHalfAwayScore && (totalFirstCurrentScore < specialOddsValue))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (firstHalfHomeScore < firstHalfAwayScore && (totalFirstCurrentScore < specialOddsValue))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && ((firstHalfHomeScore > firstHalfAwayScore) && (totalFirstCurrentScore > specialOddsValue))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 5 && ((firstHalfHomeScore === firstHalfAwayScore) && (totalFirstCurrentScore > specialOddsValue))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 6 && ((firstHalfHomeScore < firstHalfAwayScore) && (totalFirstCurrentScore > specialOddsValue))) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.homeToWinEitherHalf = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && firstHalfHomeScore > firstHalfAwayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfHomeScore <= firstHalfAwayScore) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            return MatchResultStatus.continues;
        }
        else if (EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId) || !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfHomeScore > firstHalfAwayScore) {
                    return MatchResultStatus.predictionWon;
                }
                else if (firstHalfHomeScore <= firstHalfAwayScore && secondHalfHomeScore > secondHalfAwayScore) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfHomeScore <= firstHalfAwayScore && secondHalfHomeScore <= secondHalfAwayScore) {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfHomeScore > firstHalfAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                else if (firstHalfHomeScore <= firstHalfAwayScore && secondHalfHomeScore <= secondHalfAwayScore) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfHomeScore <= firstHalfAwayScore && secondHalfHomeScore > secondHalfAwayScore) {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.awayToWinEitherHalf = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && firstHalfAwayScore > firstHalfHomeScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfAwayScore <= firstHalfHomeScore) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            return MatchResultStatus.continues;
        }
        else if (EventStatusManager.checkIsFinishedFirstHalf(event.scores.statusId) || !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfHomeScore < firstHalfAwayScore) {
                    return MatchResultStatus.predictionWon;
                }
                else if (firstHalfAwayScore <= firstHalfHomeScore && secondHalfAwayScore > secondHalfHomeScore) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfAwayScore <= firstHalfHomeScore && secondHalfAwayScore <= secondHalfHomeScore) {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfHomeScore < firstHalfAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                else if (firstHalfAwayScore <= firstHalfHomeScore && secondHalfAwayScore <= secondHalfHomeScore) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (firstHalfAwayScore <= firstHalfHomeScore && secondHalfAwayScore > secondHalfHomeScore) {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.bothHalvesUnder = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        var totalFirstHalfScores = firstHalfHomeScore + firstHalfAwayScore;
        var totalSecondHalfScores = secondHalfHomeScore + secondHalfAwayScore;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = MarketCalculator.isNullOrEmptyOrUndefined(market.specialOddsValue) ? 1 : parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (totalFirstHalfScores > specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                else {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalFirstHalfScores > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continues;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if ((totalFirstHalfScores < specialOddsValue) && totalSecondHalfScores < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalFirstHalfScores > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else if (totalFirstHalfScores < specialOddsValue && totalSecondHalfScores < specialOddsValue) {
                    return MatchResultStatus.continuesLosing;
                }
                else if ((totalFirstHalfScores < specialOddsValue && totalSecondHalfScores > specialOddsValue)) {
                    return MatchResultStatus.predictionLost;
                }
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.bothHalvesOver = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), firstHalfHomeScore = _a.firstHalfHomeScore, firstHalfAwayScore = _a.firstHalfAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        var totalFirstHalfScores = firstHalfHomeScore + firstHalfAwayScore;
        var totalSecondHalfScores = secondHalfHomeScore + secondHalfAwayScore;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = MarketCalculator.isNullOrEmptyOrUndefined(market.specialOddsValue) ? 1 : parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (totalFirstHalfScores > specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalFirstHalfScores > specialOddsValue) {
                    return MatchResultStatus.continues;
                }
                else {
                    return MatchResultStatus.continuesWinning;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if ((totalFirstHalfScores < specialOddsValue)) {
                    return MatchResultStatus.predictionLost;
                }
                else if ((totalFirstHalfScores < specialOddsValue) && (totalSecondHalfScores < specialOddsValue)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if ((totalFirstHalfScores > specialOddsValue) && (totalSecondHalfScores < specialOddsValue)) {
                    return MatchResultStatus.predictionWon;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalFirstHalfScores < specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                else if (totalFirstHalfScores > specialOddsValue && totalSecondHalfScores < specialOddsValue) {
                    return MatchResultStatus.continues;
                }
                else if ((totalFirstHalfScores > specialOddsValue && totalSecondHalfScores > specialOddsValue)) {
                    return MatchResultStatus.predictionWon;
                }
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.homeTeamOrUnder = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var totalScore = currentHomeScore + currentAwayScore;
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore > currentAwayScore || totalScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore < currentAwayScore || totalScore > specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore || totalScore < specialOddsValue) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore <= currentAwayScore && totalScore > specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.predictionWon;
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.awayTeamOrUnder = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var totalScore = currentHomeScore + currentAwayScore;
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore < currentAwayScore || totalScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore > currentAwayScore || totalScore > specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore < currentAwayScore || totalScore < specialOddsValue) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore >= currentAwayScore && totalScore > specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.predictionWon;
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.drawOrUnder = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var totalScore = currentHomeScore + currentAwayScore;
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore == currentAwayScore || totalScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore == currentAwayScore || totalScore > specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore == currentAwayScore || totalScore < specialOddsValue) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore !== currentAwayScore && totalScore > specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.predictionWon;
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.homeTeamOrOver = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var totalScore = currentHomeScore + currentAwayScore;
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore > currentAwayScore || totalScore > specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore <= currentAwayScore || totalScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore || totalScore > specialOddsValue) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore <= currentAwayScore && totalScore < specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                return MatchResultStatus.predictionLost;
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.awayTeamOrOver = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var totalScore = currentHomeScore + currentAwayScore;
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore < currentAwayScore || totalScore > specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore > currentAwayScore || totalScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore < currentAwayScore || totalScore > specialOddsValue) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore >= currentAwayScore && totalScore < specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                return MatchResultStatus.predictionLost;
            }
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.drawOrOver = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var totalScore = currentHomeScore + currentAwayScore;
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore == currentAwayScore || totalScore > specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore !== currentAwayScore || totalScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore == currentAwayScore || totalScore > specialOddsValue) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore !== currentAwayScore && totalScore < specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                return MatchResultStatus.predictionLost;
            }
        }
        return MatchResultStatus.continues;
    };
    // Basketball
    // @ts-ignore
    MarketCalculator.moneyLine = function (event, market, outcome) {
        //maç sonucu , uzatmalar dahil.
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentAwayScore > currentHomeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId) || EventStatusManager.checkIsExtraTime(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentAwayScore > currentHomeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.underOverTotalPoints = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        var totalScore = (currentHomeScore + currentAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (totalScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId) || EventStatusManager.checkIsExtraTime(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (totalScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (totalScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstHalfResultThreeWay = function (event, market, outcome) {
        if (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam) || MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam)) {
            return MatchResultStatus.notStarted;
        }
        var homeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.firstHalf) || parseInt(event.scores.homeTeam.firstHalf) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.firstHalf);
        var awayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.firstHalf) || parseInt(event.scores.awayTeam.firstHalf) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.firstHalf);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsBasketballFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsBasketballSecondHalf(event.scores.statusId) ||
                EventStatusManager.checkIsHalfTime(event.scores.statusId) ||
                EventStatusManager.checkIsExtraTime(event.scores.statusId)))
            || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstHalfResultTwoWay = function (event, market, outcome) {
        var homeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.firstHalf) || parseInt(event.scores.homeTeam.firstHalf) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.firstHalf);
        var awayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.firstHalf) || parseInt(event.scores.awayTeam.firstHalf) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.firstHalf);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsBasketballFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && awayScore > homeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsBasketballSecondHalf(event.scores.statusId) ||
                EventStatusManager.checkIsHalfTime(event.scores.statusId) ||
                EventStatusManager.checkIsExtraTime(event.scores.statusId)))
            || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && awayScore > homeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketBallHalfTimeFullTime = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var halfTimeHomeScore = parseInt(event.scores.homeTeam.periods.first) + parseInt(event.scores.homeTeam.periods.second);
        var halfTimeAwayScore = parseInt(event.scores.awayTeam.periods.first) + parseInt(event.scores.awayTeam.periods.second);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsBasketballFirstHalf(event.scores.statusId)) {
            if ((Number(outcome.outcomeNo) === 1 || Number(outcome.outcomeNo) === 2 || Number(outcome.outcomeNo) === 3) && (currentHomeScore > currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if ((Number(outcome.outcomeNo) === 4 || Number(outcome.outcomeNo) === 5 || Number(outcome.outcomeNo) === 6) && (currentHomeScore === currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if ((Number(outcome.outcomeNo) === 7 || Number(outcome.outcomeNo) === 8 || Number(outcome.outcomeNo) === 9) && (currentHomeScore < currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsHalfTime(event.scores.statusId)) {
            if ((Number(outcome.outcomeNo) === 1 || Number(outcome.outcomeNo) === 2 || Number(outcome.outcomeNo) === 3) && (currentHomeScore > currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if ((Number(outcome.outcomeNo) === 4 || Number(outcome.outcomeNo) === 5 || Number(outcome.outcomeNo) === 6) && (currentHomeScore === currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if ((Number(outcome.outcomeNo) === 7 || Number(outcome.outcomeNo) === 8 || Number(outcome.outcomeNo) === 9) && (currentHomeScore < currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.predictionLost;
        }
        else if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsBasketballSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if ((halfTimeHomeScore > halfTimeAwayScore) && (currentHomeScore > currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (halfTimeHomeScore <= halfTimeAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if ((halfTimeHomeScore > halfTimeAwayScore) && (currentHomeScore === currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((halfTimeHomeScore <= halfTimeAwayScore)) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if ((halfTimeHomeScore > halfTimeAwayScore) && (currentHomeScore < currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((halfTimeHomeScore <= halfTimeAwayScore)) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 4) {
                if ((halfTimeHomeScore === halfTimeAwayScore) && (currentHomeScore > currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (halfTimeHomeScore !== halfTimeAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 5) {
                if ((halfTimeHomeScore === halfTimeAwayScore) && (currentHomeScore === currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (halfTimeHomeScore !== halfTimeAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 6) {
                if ((halfTimeHomeScore === halfTimeAwayScore) && (currentHomeScore < currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (halfTimeHomeScore !== halfTimeAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 7) {
                if ((halfTimeHomeScore < halfTimeAwayScore) && (currentHomeScore > currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (halfTimeHomeScore >= halfTimeAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 8) {
                if ((halfTimeHomeScore < halfTimeAwayScore) && (currentHomeScore === currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (halfTimeHomeScore >= halfTimeAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 9) {
                if ((halfTimeHomeScore < halfTimeAwayScore) && (currentHomeScore < currentAwayScore)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (halfTimeHomeScore >= halfTimeAwayScore) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId) || EventStatusManager.checkIsExtraTime(event.scores.statusId)) {
            var fullTimeHomeScore = parseInt(event.scores.homeTeam.periods.first) + parseInt(event.scores.homeTeam.periods.second)
                + parseInt(event.scores.homeTeam.periods.third) + parseInt(event.scores.homeTeam.periods.fourth);
            var fullTimeAwayScore = parseInt(event.scores.awayTeam.periods.first) + parseInt(event.scores.awayTeam.periods.second)
                + parseInt(event.scores.awayTeam.periods.third) + parseInt(event.scores.awayTeam.periods.fourth);
            if (Number(outcome.outcomeNo) === 1 && (halfTimeHomeScore > halfTimeAwayScore) && (fullTimeHomeScore > fullTimeAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((halfTimeHomeScore > halfTimeAwayScore) && (fullTimeHomeScore === fullTimeAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && ((halfTimeHomeScore > halfTimeAwayScore) && (fullTimeHomeScore < fullTimeAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && ((halfTimeHomeScore === halfTimeAwayScore) && (fullTimeHomeScore > fullTimeAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 5 && ((halfTimeHomeScore === halfTimeAwayScore) && (fullTimeHomeScore === fullTimeAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 6 && ((halfTimeHomeScore === halfTimeAwayScore) && (fullTimeHomeScore < fullTimeAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 7 && ((halfTimeHomeScore < halfTimeAwayScore) && (fullTimeHomeScore > fullTimeAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 8 && ((halfTimeHomeScore < halfTimeAwayScore) && (fullTimeHomeScore === fullTimeAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 9 && ((halfTimeHomeScore < halfTimeAwayScore) && (fullTimeHomeScore < fullTimeAwayScore))) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.winnerWithPointSpread = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var homeAndSpecialOddSum = currentHomeScore + parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && ((homeAndSpecialOddSum) > currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((homeAndSpecialOddSum) < currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && ((homeAndSpecialOddSum) > currentAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((homeAndSpecialOddSum) < currentAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.firstPeriodResult = function (event, market, outcome) {
        var homeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.first) || parseInt(event.scores.homeTeam.periods.first) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.first);
        var awayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.first) || parseInt(event.scores.awayTeam.periods.first) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.periods.first);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstPeriod(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsSecondPeriod(event.scores.statusId) ||
                EventStatusManager.checkIsHalfTime(event.scores.statusId) ||
                EventStatusManager.checkIsBasketballSecondHalf(event.scores.statusId) ||
                EventStatusManager.checkIsExtraTime(event.scores.statusId)))
            || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballWinningMargin = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (currentHomeScore >= (currentAwayScore + 11))) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore <= (currentAwayScore + 10) && (currentHomeScore >= (currentAwayScore + 6)))) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && (currentHomeScore <= (currentAwayScore + 5) && (currentHomeScore >= (currentAwayScore + 1)))) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 4 && (currentAwayScore <= (currentHomeScore + 5) && (currentAwayScore >= (currentHomeScore + 1)))) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 5 && (currentAwayScore <= (currentHomeScore + 10) && (currentAwayScore >= (currentHomeScore + 6)))) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 6 && (currentAwayScore >= (currentHomeScore + 11))) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId) || EventStatusManager.checkIsExtraTime(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (currentHomeScore >= (currentAwayScore + 11))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore <= (currentAwayScore + 10) && (currentHomeScore >= (currentAwayScore + 6)))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (currentHomeScore <= (currentAwayScore + 5) && (currentHomeScore >= (currentAwayScore + 1)))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && (currentAwayScore <= (currentHomeScore + 5) && (currentAwayScore >= (currentHomeScore + 1)))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 5 && (currentAwayScore <= (currentHomeScore + 10) && (currentAwayScore >= (currentHomeScore + 6)))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 6 && (currentAwayScore >= (currentHomeScore + 11))) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballFirstHalfPointSpread = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var halfTimeHomeScore = parseInt(event.scores.homeTeam.firstHalf);
        var halfTimeAwayScore = parseInt(event.scores.awayTeam.firstHalf);
        var specialOddsValue = parseFloat(market.specialOddsValue);
        var currentHomeScoreAndSpecialOddTotal = (currentHomeScore + specialOddsValue);
        var halfHomeScoreAndSpecialOddTotal = (halfTimeHomeScore + specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsBasketballFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (currentHomeScoreAndSpecialOddTotal > currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeScoreAndSpecialOddTotal < currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsBasketballSecondHalf(event.scores.statusId) ||
                EventStatusManager.checkIsHalfTime(event.scores.statusId) ||
                EventStatusManager.checkIsExtraTime(event.scores.statusId)))
            || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (halfHomeScoreAndSpecialOddTotal > halfTimeAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (halfHomeScoreAndSpecialOddTotal < halfTimeAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballFirstHalfPointsUnderOver = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var halfTimeHomeScore = parseInt(event.scores.homeTeam.firstHalf);
        var halfTimeAwayScore = parseInt(event.scores.awayTeam.firstHalf);
        var specialOddsValue = parseFloat(market.specialOddsValue);
        var currentTotalScore = (currentHomeScore + currentAwayScore);
        var halfTotalScore = (halfTimeHomeScore + halfTimeAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsBasketballFirstHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentTotalScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentTotalScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsBasketballSecondHalf(event.scores.statusId) ||
                EventStatusManager.checkIsHalfTime(event.scores.statusId) ||
                EventStatusManager.checkIsExtraTime(event.scores.statusId))) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (halfTotalScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (halfTotalScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballUnderOverTotalPointsHomeTeam = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentHomeScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentHomeScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId) || EventStatusManager.checkIsExtraTime(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (currentHomeScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballUnderOverTotalPointsAwayTeam = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (currentAwayScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (currentAwayScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId) || EventStatusManager.checkIsExtraTime(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (currentAwayScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentAwayScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballMatchBetTotals = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        var currentTotalScore = (currentHomeScore + currentAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if ((currentHomeScore > currentAwayScore) && (currentTotalScore < specialOddsValue)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentTotalScore >= specialOddsValue)) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (((currentHomeScore < currentAwayScore) && (currentTotalScore < specialOddsValue))) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentTotalScore >= specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if ((currentHomeScore > currentAwayScore) && (currentTotalScore > specialOddsValue)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if (currentTotalScore <= specialOddsValue) {
                    return MatchResultStatus.continuesLosing;
                }
                return MatchResultStatus.continuesLosing;
            }
            else if (Number(outcome.outcomeNo) === 4) {
                if ((currentHomeScore < currentAwayScore) && (currentTotalScore > specialOddsValue)) {
                    return MatchResultStatus.continuesWinning;
                }
                else if ((currentTotalScore <= specialOddsValue)) {
                    return MatchResultStatus.continuesLosing;
                }
                return MatchResultStatus.continuesLosing;
            }
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId) || EventStatusManager.checkIsExtraTime(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (currentHomeScore > currentAwayScore) && (currentTotalScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore < currentAwayScore && (currentTotalScore < specialOddsValue))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && ((currentHomeScore > currentAwayScore) && (currentTotalScore > specialOddsValue))) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && ((currentHomeScore < currentAwayScore) && (currentTotalScore > specialOddsValue))) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballHighestScoringQuarter = function (event, market, outcome) {
        var periodsInfo = {};
        periodsInfo.first = parseInt(event.scores.homeTeam.periods.first) + parseInt(event.scores.awayTeam.periods.first);
        periodsInfo.second = parseInt(event.scores.homeTeam.periods.second) + parseInt(event.scores.awayTeam.periods.second);
        periodsInfo.third = parseInt(event.scores.homeTeam.periods.third) + parseInt(event.scores.awayTeam.periods.third);
        periodsInfo.fourth = parseInt(event.scores.homeTeam.periods.fourth) + parseInt(event.scores.awayTeam.periods.fourth);
        var maxField = null;
        var maxCount = 0;
        for (var i in periodsInfo) {
            if (periodsInfo.hasOwnProperty(i)) {
                if (periodsInfo[i] > maxCount) {
                    maxCount = periodsInfo[i];
                    maxField = i;
                }
            }
        }
        var isEquals = (Object.values(periodsInfo).filter(function (i) { return i === maxCount; }).length > 1);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFirstPeriod(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 || Number(outcome.outcomeNo) === 2 || Number(outcome.outcomeNo) === 3 || Number(outcome.outcomeNo) === 4 || Number(outcome.outcomeNo) === 5) {
                return MatchResultStatus.continues;
            }
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && maxField === 'first' && !isEquals) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && maxField === 'second' && !isEquals) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && maxField === 'third' && !isEquals) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && maxField === 'fourth' && !isEquals) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 5 && isEquals) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.secondPeriodResult = function (event, market, outcome) {
        var homeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.second) || parseInt(event.scores.homeTeam.periods.second) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.second);
        var awayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.second) || parseInt(event.scores.awayTeam.periods.second) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.periods.second);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsSecondPeriod(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsHalfTime(event.scores.statusId) ||
                EventStatusManager.checkIsBasketballSecondHalf(event.scores.statusId) ||
                EventStatusManager.checkIsExtraTime(event.scores.statusId)))
            || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.thirdPeriodResult = function (event, market, outcome) {
        var homeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.third) || parseInt(event.scores.homeTeam.periods.third) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.third);
        var awayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.third) || parseInt(event.scores.awayTeam.periods.third) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.periods.third);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsThirdPeriod(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsFourthPeriod(event.scores.statusId) || EventStatusManager.checkIsExtraTime(event.scores.statusId)))
            || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.fourthPeriodResult = function (event, market, outcome) {
        var homeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.fourth) || parseInt(event.scores.homeTeam.periods.fourth) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.fourth);
        var awayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.fourth) || parseInt(event.scores.awayTeam.periods.fourth) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.periods.fourth);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFourthPeriod(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsExtraTime(event.scores.statusId)) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballfirstHalfUnderOverTotalPointsHomeTeam = function (event, market, outcome) {
        var error = MarketHelper.getCurrentScore(event.scores).error;
        var secondPeriodHomeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.second) || parseInt(event.scores.homeTeam.periods.second) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.second);
        var firstPeriodHomeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.first) || parseInt(event.scores.homeTeam.periods.first) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.first);
        var firstHalfHomeScore = secondPeriodHomeScore + firstPeriodHomeScore;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsSecondHalf(event.scores.statusId) && !(EventStatusManager.checkIsHalfTime(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfHomeScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfHomeScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsHalfTime(event.scores.statusId) ||
                EventStatusManager.checkIsBasketballSecondHalf(event.scores.statusId) ||
                EventStatusManager.checkIsExtraTime(event.scores.statusId)))
            || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (firstHalfHomeScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (firstHalfHomeScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballfirstHalfUnderOverTotalPointsAwayTeam = function (event, market, outcome) {
        var error = MarketHelper.getCurrentScore(event.scores).error;
        var secondPeriodAwayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.second) || parseInt(event.scores.awayTeam.periods.second) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.awayTeam.periods.second);
        var firstPeriodAwayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.first) || parseInt(event.scores.awayTeam.periods.first) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.awayTeam.periods.first);
        var firstHalfAwayScore = firstPeriodAwayScore + secondPeriodAwayScore;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !(EventStatusManager.checkIsSecondHalf(event.scores.statusId)) && !(EventStatusManager.checkIsHalfTime(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstHalfAwayScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstHalfAwayScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) && !(EventStatusManager.checkIsFirstHalf(event.scores.statusId))) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (firstHalfAwayScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (firstHalfAwayScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballFirstPeriodPointsUnderOver = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        var firstPeriodHomeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.first) || parseInt(event.scores.homeTeam.periods.first) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.first);
        var firstPeriodAwayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.first) || parseInt(event.scores.awayTeam.periods.first) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.periods.first);
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        // const currentTotalScore = (currentHomeScore + currentAwayScore);
        var firstPeriodTotalScore = (firstPeriodHomeScore + firstPeriodAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstPeriod(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1) {
                if (firstPeriodTotalScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (firstPeriodTotalScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            !(EventStatusManager.checkIsFirstPeriod(event.scores.statusId))) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (firstPeriodTotalScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (firstPeriodTotalScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballSecondPeriodPointsUnderOver = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        var secondPeriodHomeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.second) || parseInt(event.scores.homeTeam.periods.second) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.second);
        var secondtPeriodAwayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.second) || parseInt(event.scores.awayTeam.periods.second) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.periods.second);
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        // const currentTotalScore = (currentHomeScore + currentAwayScore);
        var secondPeriodTotalScore = (secondPeriodHomeScore + secondtPeriodAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && (EventStatusManager.checkIsBasketballFirstHalf(event.scores.statusId)) && (EventStatusManager.checkIsSecondPeriod(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (secondPeriodTotalScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (secondPeriodTotalScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsBasketballSecondHalf(event.scores.statusId) || EventStatusManager.checkIsExtraTime(event.scores.statusId))) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (secondPeriodTotalScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (secondPeriodTotalScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballThirdPeriodPointsUnderOver = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        var thirdPeriodHomeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.third) || parseInt(event.scores.homeTeam.periods.third) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.third);
        var thirdPeriodAwayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.third) || parseInt(event.scores.awayTeam.periods.third) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.periods.third);
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        // const currentTotalScore = (currentHomeScore + currentAwayScore);
        var thirdPeriodTotalScore = (thirdPeriodHomeScore + thirdPeriodAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && (EventStatusManager.checkIsBasketballSecondHalf(event.scores.statusId)) && (EventStatusManager.checkIsThirdPeriod(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (thirdPeriodTotalScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (thirdPeriodTotalScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsBasketballSecondHalf(event.scores.statusId) ||
                EventStatusManager.checkIsHalfTime(event.scores.statusId) ||
                EventStatusManager.checkIsExtraTime(event.scores.statusId))) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (thirdPeriodTotalScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (thirdPeriodTotalScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballFourthPeriodPointsUnderOver = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        var fourthPeriodHomeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.fourth) || parseInt(event.scores.homeTeam.periods.fourth) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.fourth);
        var fourthPeriodAwayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.fourth) || parseInt(event.scores.awayTeam.periods.fourth) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.periods.fourth);
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        // const currentTotalScore = (currentHomeScore + currentAwayScore);
        var fourthPeriodTotalScore = (fourthPeriodHomeScore + fourthPeriodAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && (EventStatusManager.checkIsBasketballSecondHalf(event.scores.statusId)) && (EventStatusManager.checkIsFourthPeriod(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (fourthPeriodTotalScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
                else {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (fourthPeriodTotalScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
                else {
                    return MatchResultStatus.continuesLosing;
                }
            }
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsBasketballSecondHalf(event.scores.statusId) ||
                EventStatusManager.checkIsHalfTime(event.scores.statusId) ||
                EventStatusManager.checkIsExtraTime(event.scores.statusId))) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (fourthPeriodTotalScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (fourthPeriodTotalScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballHighestScoringHalf = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballPointRange180 = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.basketballPointRange250 = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // Masa Tenisi
    // @ts-ignore
    MarketCalculator.tableTennisMatchResult = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if ((EventStatusManager.checkIsLive(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentAwayScore > currentHomeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentAwayScore > currentHomeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // Tennis
    // @ts-ignore
    MarketCalculator.tennisMatchWinner = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if ((EventStatusManager.checkIsLive(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentAwayScore > currentHomeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentAwayScore > currentHomeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.tennisUnderOverTotalGames = function (event, market, outcome) {
        if (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam) || MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam)) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
        // const homeSets = event.scores.homeTeam.sets;
        // const awaySets = event.scores.awayTeam.sets;
        // const specialOddsValue = parseFloat(market.specialOddsValue);
        // //@ts-ignore
        // const totalHomeSets: number = Object.values(homeSets).reduce((a, b) => a + b);
        // //@ts-ignore
        // const totalAwaySets: number = Object.values(awaySets).reduce((a, b) => a + b);
        // if (Number(outcome.outcomeNo) === 1 && ((totalHomeSets + totalAwaySets) < specialOddsValue)) {
        //     return MatchResultStatus.continuesWinning;
        // } else if (Number(outcome.outcomeNo) === 2 && ((totalHomeSets + +totalAwaySets) > specialOddsValue)) {
        //     return MatchResultStatus.continuesWinning;
        // }
        // return MatchResultStatus.continuesLosing;
    };
    // @ts-ignore
    MarketCalculator.tennisWinnerWithHandicap = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (Number(outcome.outcomeNo) === 1 && ((currentHomeScore + specialOddsValue) > currentAwayScore)) {
            return MatchResultStatus.continuesWinning;
        }
        else if (Number(outcome.outcomeNo) === 2 && ((currentHomeScore + specialOddsValue < currentAwayScore))) {
            return MatchResultStatus.continuesWinning;
        }
        return MatchResultStatus.continuesLosing;
    };
    // @ts-ignore
    MarketCalculator.tennisFirstSetWinner = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var homeSetScoreFirst = parseInt(event.scores.homeTeam.sets.first);
        var awaySetScoreFirst = parseInt(event.scores.awayTeam.sets.first);
        var isFirstSet = MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.sets.first);
        if (isFirstSet) {
            if (Number(outcome.outcomeNo) === 1 && (currentHomeScore > currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore < currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
        }
        else {
            if (Number(outcome.outcomeNo) === 1 && (homeSetScoreFirst > awaySetScoreFirst)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (homeSetScoreFirst < awaySetScoreFirst)) {
                return MatchResultStatus.continuesWinning;
            }
        }
        return MatchResultStatus.continuesLosing;
    };
    // @ts-ignore
    MarketCalculator.tennisSecondSetWinner = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var homeSetScoreSecond = parseInt(event.scores.homeTeam.sets.second);
        var awaySetScoreSecond = parseInt(event.scores.awayTeam.sets.second);
        var isFirstSetEnd = MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.sets.first);
        var isSecondSet = MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.sets.second);
        if (isFirstSetEnd && isSecondSet) {
            if (Number(outcome.outcomeNo) === 1 && (homeSetScoreSecond > awaySetScoreSecond)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (homeSetScoreSecond < awaySetScoreSecond)) {
                return MatchResultStatus.continuesWinning;
            }
        }
        else {
            return MatchResultStatus.continues;
        }
        return MatchResultStatus.continuesLosing;
    };
    // @ts-ignore
    MarketCalculator.tennisSetWinner = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        // const homeSetScoreFirst = parseInt(event.scores.homeTeam.sets.first);
        // const awaySetScoreFirst = parseInt(event.scores.awayTeam.sets.first);
        //
        // let isFirstSet = MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.sets.first);
        // if (isFirstSet) {
        //     if (Number(outcome.outcomeNo) === 1 && (homeSetScoreFirst > awaySetScoreFirst)) {
        //         return MatchResultStatus.continuesWinning;
        //     } else if (Number(outcome.outcomeNo) === 2 && (homeSetScoreFirst < awaySetScoreFirst)) {
        //         return MatchResultStatus.continuesWinning;
        //     }
        // } else {
        //     if (Number(outcome.outcomeNo) === 1 && (currentHomeScore > currentAwayScore)) {
        //         return MatchResultStatus.continuesWinning;
        //     } else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore < currentAwayScore)) {
        //         return MatchResultStatus.continuesWinning;
        //     }
        //
        // }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.tennisTotalNumberOfSetsBestOfThree = function (event, market, outcome) {
        if (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam) || MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam)) {
            return MatchResultStatus.notStarted;
        }
        else {
            return MatchResultStatus.continues;
        }
    };
    // @ts-ignore
    MarketCalculator.tennisTotalNumberOfSetsBestOfFive = function (event, market, outcome) {
        console.log(event);
    };
    // @ts-ignore
    MarketCalculator.tennisTotalNumberOfGamesPlayerOne = function (event, market, outcome) {
        if (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam) || MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam)) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
        // const homeSets = event.scores.homeTeam.sets;
        // const specialOddsValue = parseFloat(market.specialOddsValue);
        // //@ts-ignore
        // const totalHomeSets: number = Object.values(homeSets).reduce((a, b) => a + b);
        // if (Number(outcome.outcomeNo) === 1 && ((totalHomeSets) < specialOddsValue)) {
        //     return MatchResultStatus.continuesWinning;
        // } else if (Number(outcome.outcomeNo) === 2 && ((totalHomeSets) > specialOddsValue)) {
        //     return MatchResultStatus.continuesWinning;
        // }
        // return MatchResultStatus.continuesLosing;
    };
    // @ts-ignore
    MarketCalculator.tennisTotalNumberOfGamesPlayerTwo = function (event, market, outcome) {
        if (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam) || MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam)) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
        // const awaySets = event.scores.homeTeam.sets;
        // const specialOddsValue = parseFloat(market.specialOddsValue);
        // //@ts-ignore
        // const totalAwaySets: number = Object.values(awaySets).reduce((a, b) => a + b);
        // if (Number(outcome.outcomeNo) === 1 && ((totalAwaySets) < specialOddsValue)) {
        //     return MatchResultStatus.continuesWinning;
        // } else if (Number(outcome.outcomeNo) === 2 && ((totalAwaySets) > specialOddsValue)) {
        //     return MatchResultStatus.continuesWinning;
        // }
        // return MatchResultStatus.continuesLosing;
    };
    // @ts-ignore
    MarketCalculator.tennisToWinSetPlayerOne = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (Number(outcome.outcomeNo) === 1 && (currentHomeScore > 0)) {
            return MatchResultStatus.continuesWinning;
        }
        else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore < 0)) {
            return MatchResultStatus.continuesWinning;
        }
        return MatchResultStatus.continuesLosing;
    };
    // @ts-ignore
    MarketCalculator.tennisToWinSetPlayerTwo = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (Number(outcome.outcomeNo) === 1 && (currentAwayScore > 0)) {
            return MatchResultStatus.continuesWinning;
        }
        else if (Number(outcome.outcomeNo) === 2 && (currentAwayScore < 0)) {
            return MatchResultStatus.continuesWinning;
        }
        return MatchResultStatus.continuesLosing;
    };
    // @ts-ignore
    MarketCalculator.tennisCorrectSetScoreBestOfThreeSets = function (event, market, outcome) {
        console.log(event);
    };
    // @ts-ignore
    MarketCalculator.tennisCorrectSetScoreBestOfFiveSets = function (event, market, outcome) {
        console.log(event);
    };
    // @ts-ignore
    MarketCalculator.tennisTieBreak = function (event, market, outcome) {
        var error = MarketHelper.getCurrentScore(event.scores).error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.tennisSetHandikap = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId)) {
            var currentHomeScoreAndSpecialOdds = currentHomeScore + parseFloat(market.specialOddsValue);
            var currentAwayScoreAndSpecialOdds = currentAwayScore + parseFloat(market.specialOddsValue);
            if (Number(outcome.outcomeNo) === 1 && ((currentHomeScoreAndSpecialOdds) > currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((currentAwayScoreAndSpecialOdds) > currentHomeScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
    };
    // @ts-ignore
    MarketCalculator.tennisOddEvenTotal = function (event, market, outcome) {
        var error = MarketHelper.getCurrentScore(event.scores).error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.tennisUnderOverResult = function (event, market, outcome) {
        var error = MarketHelper.getCurrentScore(event.scores).error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.tennisAnySetToNil = function (event, market, outcome) {
        var error = MarketHelper.getCurrentScore(event.scores).error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.tennisDoubleResult = function (event, market, outcome) {
        var error = MarketHelper.getCurrentScore(event.scores).error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.matchContinues = function (event, market, outcome) {
        var error = MarketHelper.getCurrentScore(event.scores).error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.tennisHomePlayerWinExactlyOneSet = function (event, market, outcome) {
        var error = MarketHelper.getCurrentScore(event.scores).error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.tennisAwayPlayerWinExactlyOneSet = function (event, market, outcome) {
        var error = MarketHelper.getCurrentScore(event.scores).error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // Handball
    // @ts-ignore
    MarketCalculator.handballMatchResult = function (event, market, outcome) {
        // return MarketCalculator.matchResult(event, market, outcome);
        var error = MarketHelper.getCurrentScore(event.scores).error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId))) {
            return MatchResultStatus.continues;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            return MatchResultStatus.continues;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.handballDoubleChance = function (event, market, outcome) {
        return MarketCalculator.doubleChance(event, market, outcome);
    };
    // @ts-ignore
    MarketCalculator.handballTotalGoalsUnderOver = function (event, market, outcome) {
        return MarketCalculator.totalGoalsOverUnder(event, market, outcome);
    };
    // @ts-ignore
    MarketCalculator.handballOddEvenTotalGoals = function (event, market, outcome) {
        return MarketCalculator.oddEvenTotalGoals(event, market, outcome);
    };
    // @ts-ignore
    MarketCalculator.handballWinnerWithGoalSpread = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && ((currentHomeScore + specialOddsValue) > currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((currentHomeScore + specialOddsValue) < currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinishedSecondHalf(event.scores.statusId) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && ((currentHomeScore + specialOddsValue) > currentAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((currentHomeScore + specialOddsValue) < currentAwayScore)) {
                return MatchResultStatus.predictionLost;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // Volleyball
    // @ts-ignore
    MarketCalculator.volleyballMatchResult = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentAwayScore > currentHomeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentAwayScore > currentHomeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.volleyballTotalPointsUnderOver = function (event, market, outcome) {
        try {
            var specialOddsValue = parseFloat(market.specialOddsValue);
            var homeFirstSetScore = event.scores.homeTeam.sets.first ? parseInt(event.scores.homeTeam.sets.first) : 0;
            var homeSecondSetScore = event.scores.homeTeam.sets.second ? parseInt(event.scores.homeTeam.sets.second) : 0;
            var homeThirdSetScore = event.scores.homeTeam.sets.third ? parseInt(event.scores.homeTeam.sets.third) : 0;
            var homeFourthSetScore = event.scores.homeTeam.sets.fourth ? parseInt(event.scores.homeTeam.sets.fourth) : 0;
            var homeFifthSetScore = event.scores.homeTeam.sets.fifth ? parseInt(event.scores.homeTeam.sets.fifth) : 0;
            var awayFirstSetScore = event.scores.awayTeam.sets.first ? parseInt(event.scores.awayTeam.sets.first) : 0;
            var awaySecondSetScore = event.scores.awayTeam.sets.second ? parseInt(event.scores.awayTeam.sets.second) : 0;
            var awayThirdSetScore = event.scores.awayTeam.sets.third ? parseInt(event.scores.awayTeam.sets.third) : 0;
            var awayFourthSetScore = event.scores.awayTeam.sets.fourth ? parseInt(event.scores.awayTeam.sets.fourth) : 0;
            var awayFifthSetScore = event.scores.awayTeam.sets.fifth ? parseInt(event.scores.awayTeam.sets.fifth) : 0;
            var totalScore = homeFirstSetScore + homeSecondSetScore + homeThirdSetScore + homeFourthSetScore + homeFifthSetScore + awayFirstSetScore + awaySecondSetScore + awayThirdSetScore + awayFourthSetScore + awayFifthSetScore;
            if (EventStatusManager.checkIsLive(event.scores.statusId)) {
                if (Number(outcome.outcomeNo) === 1) {
                    return totalScore < specialOddsValue ? MatchResultStatus.continuesWinning : MatchResultStatus.predictionLost;
                }
                else if (Number(outcome.outcomeNo) === 2) {
                    return totalScore > specialOddsValue ? MatchResultStatus.predictionWon : MatchResultStatus.continuesLosing;
                }
            }
            else if (EventStatusManager.checkIsFinished(event.scores.statusId)) {
                if (Number(outcome.outcomeNo) === 1) {
                    return totalScore < specialOddsValue ? MatchResultStatus.predictionWon : MatchResultStatus.predictionLost;
                }
                else if (Number(outcome.outcomeNo) === 2) {
                    return totalScore > specialOddsValue ? MatchResultStatus.predictionWon : MatchResultStatus.predictionLost;
                }
            }
            return MatchResultStatus.continues;
        }
        catch (e) {
            return MatchResultStatus.continues;
        }
    };
    // @ts-ignore
    MarketCalculator.volleyballTwoWayHandicapSets = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        // const homeScoreAndSpecialOddValue = parseFloat(event.scores.homeTeam.firstHalf.toString())
        // const awayScore = parseFloat(event.scores.awayTeam.firstHalf.toString())
        var homeFirstSetScore = event.scores.homeTeam.sets.first ? parseFloat(event.scores.homeTeam.sets.first) : 0;
        var homeSecondSetScore = event.scores.homeTeam.sets.second ? parseFloat(event.scores.homeTeam.sets.second) : 0;
        var homeThirdSetScore = event.scores.homeTeam.sets.third ? parseFloat(event.scores.homeTeam.sets.third) : 0;
        var homeFourthSetScore = event.scores.homeTeam.sets.fourth ? parseFloat(event.scores.homeTeam.sets.fourth) : 0;
        var homeFifthSetScore = event.scores.homeTeam.sets.fifth ? parseFloat(event.scores.homeTeam.sets.fifth) : 0;
        var awayFirstSetScore = event.scores.awayTeam.sets.first ? parseFloat(event.scores.awayTeam.sets.first) : 0;
        var awaySecondSetScore = event.scores.awayTeam.sets.second ? parseFloat(event.scores.awayTeam.sets.second) : 0;
        var awayThirdSetScore = event.scores.awayTeam.sets.third ? parseFloat(event.scores.awayTeam.sets.third) : 0;
        var awayFourthSetScore = event.scores.awayTeam.sets.fourth ? parseFloat(event.scores.awayTeam.sets.fourth) : 0;
        var awayFifthSetScore = event.scores.awayTeam.sets.fifth ? parseFloat(event.scores.awayTeam.sets.fifth) : 0;
        var totalHomeScore = homeFirstSetScore + homeSecondSetScore + homeThirdSetScore + homeFourthSetScore + homeFifthSetScore;
        var totalAwayScore = awayFirstSetScore + awaySecondSetScore + awayThirdSetScore + awayFourthSetScore + awayFifthSetScore;
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && ((totalHomeScore) > totalAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((totalHomeScore) < totalAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && ((totalHomeScore) > totalAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((totalHomeScore) < totalAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.volleyballCorrectSetScore = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var homeScore = parseInt(event.scores.homeTeam.current);
        var awayScore = parseInt(event.scores.awayTeam.current);
        if ((EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinished(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (homeScore === 3 && awayScore === 0) {
                    return MatchResultStatus.predictionWon;
                }
                else if (homeScore < 3 && awayScore === 0) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (awayScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (homeScore === 3 && awayScore === 1) {
                    return MatchResultStatus.predictionWon;
                }
                else if (homeScore < 3 && (awayScore <= 1)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (awayScore > 1) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 3) {
                if (homeScore === 3 && awayScore === 2) {
                    return MatchResultStatus.predictionWon;
                }
                else if ((homeScore < 3) && (awayScore <= 2)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (awayScore > 2) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 4) {
                if (homeScore === 0 && awayScore === 3) {
                    return MatchResultStatus.predictionWon;
                }
                else if ((homeScore === 0 && awayScore < 3)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (homeScore > 0) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 5) {
                if (homeScore === 1 && awayScore === 3) {
                    return MatchResultStatus.predictionWon;
                }
                else if ((homeScore <= 1 && awayScore < 3)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (homeScore > 1) {
                    return MatchResultStatus.predictionLost;
                }
            }
            else if (Number(outcome.outcomeNo) === 6) {
                if ((homeScore === 2) && (awayScore === 3)) {
                    return MatchResultStatus.predictionWon;
                }
                else if ((homeScore <= 2) && (awayScore < 3)) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (homeScore > 2) {
                    return MatchResultStatus.predictionLost;
                }
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (homeScore === 3 && awayScore === 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (homeScore === 3 && awayScore === 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (homeScore === 3 && awayScore === 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && (homeScore === 0 && awayScore === 3)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 5 && (homeScore === 1 && awayScore === 3)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 6 && (homeScore === 2 && awayScore === 3)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.volleyballExactSets = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.volleyballHomeTeamToWinExactlyOneSet = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.volleyballAwayTeamToWinExactlyOneSet = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        return MatchResultStatus.continues;
    };
    // Snooker
    // @ts-ignore
    MarketCalculator.snookerMatchWinner = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var homeScore = parseInt(event.scores.homeTeam.current);
        var awayScore = parseInt(event.scores.awayTeam.current);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && !EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && awayScore > homeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if (EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && awayScore > homeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.snookerTwoWayMatchHandicapFrames = function (event, market, outcome) {
        console.log(event);
    };
    // @ts-ignore
    MarketCalculator.snookerTotalFramesUnderOver = function (event, market, outcome) {
        console.log(event);
    };
    // Buz Hokeyi
    // @ts-ignore
    MarketCalculator.hokeyMatchResult = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && (!EventStatusManager.checkIsExtraTime(event.scores.statusId) || !EventStatusManager.checkIsPenalty(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentHomeScore === currentAwayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && currentAwayScore > currentHomeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) && (EventStatusManager.checkIsExtraTime(event.scores.statusId) || EventStatusManager.checkIsPenalty(event.scores.statusId)) || EventStatusManager.checkIsFinished(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore > currentAwayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentHomeScore === currentAwayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && currentAwayScore > currentHomeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.hokeyDoubleChance = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && (!EventStatusManager.checkIsExtraTime(event.scores.statusId) || !EventStatusManager.checkIsPenalty(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && (currentHomeScore > currentAwayScore || currentHomeScore === currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore > currentAwayScore || currentAwayScore > currentHomeScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && (currentAwayScore > currentHomeScore || currentHomeScore === currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) && (EventStatusManager.checkIsExtraTime(event.scores.statusId) || EventStatusManager.checkIsPenalty(event.scores.statusId)) || EventStatusManager.checkIsFinished(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && (currentHomeScore > currentAwayScore || currentHomeScore === currentAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (currentHomeScore > currentAwayScore || currentAwayScore > currentHomeScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (currentAwayScore > currentHomeScore || currentHomeScore === currentAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.hokeyTotalsOverUnder = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var specialOddsValue = MarketCalculator.isNullOrEmptyOrUndefined(market.specialOddsValue) ? 1 : parseFloat(market.specialOddsValue);
        var totalCurrentScore = (currentHomeScore + currentAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && (!EventStatusManager.checkIsExtraTime(event.scores.statusId) || !EventStatusManager.checkIsPenalty(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1) {
                if (totalCurrentScore > specialOddsValue) {
                    return MatchResultStatus.predictionLost;
                }
                else if (totalCurrentScore < specialOddsValue) {
                    return MatchResultStatus.continuesWinning;
                }
            }
            else if (Number(outcome.outcomeNo) === 2) {
                if (totalCurrentScore < specialOddsValue) {
                    return MatchResultStatus.continuesLosing;
                }
                else if (totalCurrentScore > specialOddsValue) {
                    return MatchResultStatus.predictionWon;
                }
            }
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) && (EventStatusManager.checkIsExtraTime(event.scores.statusId) || EventStatusManager.checkIsPenalty(event.scores.statusId)) || EventStatusManager.checkIsFinished(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && (totalCurrentScore < specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (totalCurrentScore > specialOddsValue)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.hokeyHandicap = function (event, market, outcome) {
        // @ts-ignore
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var currentHomeScoreAndSpecialOdds = currentHomeScore + parseFloat(market.specialOddsValue);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && (!EventStatusManager.checkIsExtraTime(event.scores.statusId) || !EventStatusManager.checkIsPenalty(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && ((currentHomeScoreAndSpecialOdds) > currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((currentHomeScoreAndSpecialOdds) === currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && ((currentHomeScoreAndSpecialOdds) < currentAwayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) && (EventStatusManager.checkIsExtraTime(event.scores.statusId) || EventStatusManager.checkIsPenalty(event.scores.statusId)) || EventStatusManager.checkIsFinished(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && ((currentHomeScoreAndSpecialOdds) > secondHalfAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && ((currentHomeScoreAndSpecialOdds) === secondHalfAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && ((currentHomeScoreAndSpecialOdds) < secondHalfAwayScore)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.hokeyOddEven = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        var totalCurrentScore = (currentHomeScore + currentAwayScore);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && (!EventStatusManager.checkIsExtraTime(event.scores.statusId) || !EventStatusManager.checkIsPenalty(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && (totalCurrentScore % 2 !== 0)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (totalCurrentScore % 2 === 0)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) && (EventStatusManager.checkIsExtraTime(event.scores.statusId) || EventStatusManager.checkIsPenalty(event.scores.statusId)) || EventStatusManager.checkIsFinished(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && (totalCurrentScore % 2 !== 0)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (totalCurrentScore % 2 === 0)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.hokeyFirstPeriod = function (event, market, outcome) {
        return MarketCalculator.firstPeriodResult(event, market, outcome);
    };
    // @ts-ignore
    MarketCalculator.hokeySecondPeriod = function (event, market, outcome) {
        var homeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.second) || parseInt(event.scores.homeTeam.periods.second) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.second);
        var awayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.second) || parseInt(event.scores.awayTeam.periods.second) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.periods.second);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsSecondPeriod(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsThirdPeriod(event.scores.statusId) ||
                EventStatusManager.checkIsExtraTime(event.scores.statusId)))
            || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.hokeyThirdPeriod = function (event, market, outcome) {
        var homeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.third) || parseInt(event.scores.homeTeam.periods.third) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.third);
        var awayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.third) || parseInt(event.scores.awayTeam.periods.third) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.periods.third);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsThirdPeriod(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsExtraTime(event.scores.statusId)) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && homeScore > awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && homeScore === awayScore) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && awayScore > homeScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.hokeyFirstPeriodDoubleChance = function (event, market, outcome) {
        var homeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.first) || parseInt(event.scores.homeTeam.periods.first) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.first);
        var awayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.first) || parseInt(event.scores.awayTeam.periods.first) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.periods.first);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsFirstPeriod(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (homeScore > awayScore || homeScore === awayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (homeScore > awayScore || awayScore > homeScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && (awayScore > homeScore || homeScore === awayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsSecondPeriod(event.scores.statusId) ||
                EventStatusManager.checkIsThirdPeriod(event.scores.statusId) ||
                EventStatusManager.checkIsExtraTime(event.scores.statusId))) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (homeScore > awayScore || homeScore === awayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (homeScore > awayScore || awayScore > homeScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (awayScore > homeScore || homeScore === awayScore)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.hokeySecondPeriodDoubleChance = function (event, market, outcome) {
        var homeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.second) || parseInt(event.scores.homeTeam.periods.second) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.second);
        var awayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.second) || parseInt(event.scores.awayTeam.periods.second) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.periods.second);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsSecondPeriod(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (homeScore > awayScore || homeScore === awayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (homeScore > awayScore || awayScore > homeScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && (awayScore > homeScore || homeScore === awayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) &&
            (EventStatusManager.checkIsThirdPeriod(event.scores.statusId) ||
                EventStatusManager.checkIsExtraTime(event.scores.statusId))) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (homeScore > awayScore || homeScore === awayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (homeScore > awayScore || awayScore > homeScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (awayScore > homeScore || homeScore === awayScore)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.hokeyThirdPeriodDoubleChance = function (event, market, outcome) {
        var homeScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.homeTeam.periods.third) || parseInt(event.scores.homeTeam.periods.third) === -1) ? parseInt(event.scores.homeTeam.current) : parseInt(event.scores.homeTeam.periods.third);
        var awayScore = (MarketCalculator.isNullOrEmptyOrUndefined(event.scores.awayTeam.periods.third) || parseInt(event.scores.awayTeam.periods.third) === -1) ? parseInt(event.scores.awayTeam.current) : parseInt(event.scores.awayTeam.periods.third);
        if (EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsThirdPeriod(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (homeScore > awayScore || homeScore === awayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && (homeScore > awayScore || awayScore > homeScore)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && (awayScore > homeScore || homeScore === awayScore)) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) && EventStatusManager.checkIsExtraTime(event.scores.statusId)) || EventStatusManager.checkIsFinished(event.scores.statusId)) {
            if (Number(outcome.outcomeNo) === 1 && (homeScore > awayScore || homeScore === awayScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && (homeScore > awayScore || awayScore > homeScore)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && (awayScore > homeScore || homeScore === awayScore)) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // @ts-ignore
    MarketCalculator.hokeyBothTeamScore = function (event, market, outcome) {
        return MarketCalculator.bothTeamToScore(event, market, outcome);
    };
    // @ts-ignore
    MarketCalculator.hokeyHighestPeriod = function (event, market, outcome) {
        return MarketCalculator.basketballHighestScoringQuarter(event, market, outcome);
    };
    // @ts-ignore
    MarketCalculator.hokeyWinningMargin = function (event, market, outcome) {
        var _a = MarketHelper.getCurrentScore(event.scores), currentHomeScore = _a.currentHomeScore, currentAwayScore = _a.currentAwayScore, secondHalfHomeScore = _a.secondHalfHomeScore, secondHalfAwayScore = _a.secondHalfAwayScore, error = _a.error;
        if (error) {
            return MatchResultStatus.notStarted;
        }
        if (EventStatusManager.checkIsLive(event.scores.statusId) && (!EventStatusManager.checkIsExtraTime(event.scores.statusId) || !EventStatusManager.checkIsPenalty(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && currentHomeScore >= (currentAwayScore + 3)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 2 && currentHomeScore === (currentAwayScore + 2)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 3 && currentHomeScore === (currentAwayScore + 1)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 4 && currentAwayScore === (currentHomeScore + 1)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 5 && currentAwayScore === (currentHomeScore + 2)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 6 && currentAwayScore === (currentHomeScore + 3)) {
                return MatchResultStatus.continuesWinning;
            }
            else if (Number(outcome.outcomeNo) === 7 && currentHomeScore === currentAwayScore) {
                return MatchResultStatus.continuesWinning;
            }
            return MatchResultStatus.continuesLosing;
        }
        else if ((EventStatusManager.checkIsLive(event.scores.statusId) && (EventStatusManager.checkIsExtraTime(event.scores.statusId) || EventStatusManager.checkIsPenalty(event.scores.statusId)) || EventStatusManager.checkIsFinished(event.scores.statusId))) {
            if (Number(outcome.outcomeNo) === 1 && secondHalfHomeScore >= (secondHalfAwayScore + 3)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 2 && secondHalfHomeScore === (secondHalfAwayScore + 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 3 && secondHalfHomeScore === (secondHalfAwayScore + 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 4 && secondHalfHomeScore === (secondHalfAwayScore + 1)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 5 && secondHalfHomeScore === (secondHalfAwayScore + 2)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 6 && secondHalfHomeScore === (secondHalfAwayScore + 3)) {
                return MatchResultStatus.predictionWon;
            }
            else if (Number(outcome.outcomeNo) === 7 && secondHalfHomeScore === secondHalfAwayScore) {
                return MatchResultStatus.predictionWon;
            }
            return MatchResultStatus.predictionLost;
        }
        return MatchResultStatus.continues;
    };
    // Long Term
    // @ts-ignore
    MarketCalculator.longTermResult = function (event, market, outcome) {
        var eventResult = MatchResultStatus.notStarted;
        if (outcome.outcomeResult === 1) {
            eventResult = MatchResultStatus.continues;
        }
        else if (outcome.outcomeResult === 0) {
            eventResult = MatchResultStatus.officalLost;
        }
        else if (outcome.outcomeResult === 2) {
            eventResult = MatchResultStatus.officalWon;
        }
        return eventResult;
    };
    return MarketCalculator;
}());
var MarketCalculatePointer = {
    // Football
    '1-1': MarketCalculator.matchResult,
    '4-4': MarketCalculator.matchResult,
    '2-92': MarketCalculator.doubleChance,
    '4-129': MarketCalculator.doubleChance,
    '2-101': MarketCalculator.totalGoalsOverUnder,
    '4-14': MarketCalculator.totalGoalsOverUnder,
    '2-88': MarketCalculator.firstHalfResult,
    '4-47': MarketCalculator.firstHalfResult,
    '2-77': MarketCalculator.firstHalfDoubleChance,
    '4-625': MarketCalculator.firstHalfDoubleChance,
    '2-36': MarketCalculator.secondHalfResult,
    '4-321': MarketCalculator.secondHalfResult,
    '2-100': MarketCalculator.threeWayHandicap,
    '4-12': MarketCalculator.threeWayHandicap,
    '2-91': MarketCalculator.oddEvenTotalGoals,
    '4-136': MarketCalculator.oddEvenTotalGoals,
    '2-89': MarketCalculator.bothTeamToScore,
    '4-131': MarketCalculator.bothTeamToScore,
    '2-90': MarketCalculator.soccerHalfTimeFullTime,
    '2-86': MarketCalculator.correctScore,
    '4-252': MarketCalculator.correctScore,
    '2-4': MarketCalculator.totalGoals,
    '2-60': MarketCalculator.firstHalfTotalGoalsUnderOver,
    '4-63': MarketCalculator.firstHalfTotalGoalsUnderOver,
    '2-46': MarketCalculator.firstHalfTotalGoalsRange,
    '4-262': MarketCalculator.firstHalfTotalGoalsRange,
    '2-47': MarketCalculator.secondHalfTotalGoalsRange,
    '2-603': MarketCalculator.homeTeamOverUnder,
    '4-203': MarketCalculator.homeTeamOverUnder,
    '2-604': MarketCalculator.awayTeamOverUnder,
    '4-207': MarketCalculator.awayTeamOverUnder,
    '2-643': MarketCalculator.homeToWinZero,
    '2-644': MarketCalculator.awayToWinZero,
    '2-6': MarketCalculator.highestScoringHalf,
    '4-199': MarketCalculator.highestScoringHalf,
    '2-84': MarketCalculator.highestScoringHalfHome,
    '2-85': MarketCalculator.highestScoringHalfAway,
    '2-43': MarketCalculator.cleanSheetHomeTeam,
    '2-44': MarketCalculator.cleanSheetAwayTeam,
    '2-11': MarketCalculator.soccerWinningMargin,
    '4-54': MarketCalculator.overTimeTotalGoalsOverUnder,
    '2-45': MarketCalculator.whichTeamToScore,
    '2-69': MarketCalculator.homeToWinBothHalves,
    '2-72': MarketCalculator.awayToWinBothHalves,
    '2-7': MarketCalculator.soccerMatchBetTotals,
    '2-87': MarketCalculator.soccerFirstGoal,
    '4-27': MarketCalculator.soccerOvertimeWinner,
    '2-67': MarketCalculator.soccerCornersAggregated,
    '4-215': MarketCalculator.soccerCornersAggregated,
    '2-68': MarketCalculator.soccerFirstHalfCornersAggregated,
    '4-222': MarketCalculator.soccerFirstHalfCornersAggregated,
    '2-48': MarketCalculator.soccerTotalCornerOverUnder,
    '4-211': MarketCalculator.soccerTotalCornerOverUnder,
    '2-49': MarketCalculator.soccerFirstHalfCornerOverUnder,
    '4-218': MarketCalculator.soccerFirstHalfCornerOverUnder,
    '2-52': MarketCalculator.soccerCornersMatchBet,
    '4-210': MarketCalculator.soccerCornersMatchBet,
    '2-53': MarketCalculator.soccerFirstHalfCornersMatchBet,
    '4-217': MarketCalculator.soccerFirstHalfCornersMatchBet,
    '2-56': MarketCalculator.soccerFirstCorner,
    '2-737': MarketCalculator.oddEventTotalCorner,
    '4-727': MarketCalculator.oddEventTotalCorner,
    '2-717': MarketCalculator.firstHalfCorrectScore,
    '4-728': MarketCalculator.firstHalfCorrectScore,
    '2-719': MarketCalculator.firstHalfOddEvent,
    '2-700': MarketCalculator.totalAndBothTeamToScore,
    '2-720': MarketCalculator.firstHalfBothTeamsToScore,
    '4-735': MarketCalculator.firstHalfBothTeamsToScore,
    '4-731': MarketCalculator.homeTeamTotalCornersOverUnder,
    '4-732': MarketCalculator.awayTeamTotalCornersOverUnder,
    '2-722': MarketCalculator.firstHalfHomeTeamTotalGoalsOverUnder,
    '4-737': MarketCalculator.firstHalfHomeTeamTotalGoalsOverUnder,
    '2-723': MarketCalculator.firstHalfAwayTeamTotalGoalsOverUnder,
    '4-738': MarketCalculator.firstHalfAwayTeamTotalGoalsOverUnder,
    '2-698': MarketCalculator.matchResultsAndBothTeamsToScore,
    '4-708': MarketCalculator.matchResultsAndBothTeamsToScore,
    '2-699': MarketCalculator.firstHalfMatchResultsAndBothTeamsToScore,
    '4-709': MarketCalculator.firstHalfMatchResultsAndBothTeamsToScore,
    '2-724': MarketCalculator.firstHalfResultAndOverUnder,
    '4-739': MarketCalculator.firstHalfResultAndOverUnder,
    '2-727': MarketCalculator.homeToWinEitherHalf,
    '2-728': MarketCalculator.awayToWinEitherHalf,
    '2-729': MarketCalculator.bothHalvesUnder,
    '2-730': MarketCalculator.bothHalvesOver,
    '2-731': MarketCalculator.homeTeamOrUnder,
    '2-733': MarketCalculator.awayTeamOrUnder,
    '2-735': MarketCalculator.drawOrUnder,
    '2-732': MarketCalculator.homeTeamOrOver,
    '2-734': MarketCalculator.awayTeamOrOver,
    '2-736': MarketCalculator.drawOrOver,
    // Basketball
    '1-2': MarketCalculator.moneyLine,
    '4-71': MarketCalculator.moneyLine,
    '2-114': MarketCalculator.underOverTotalPoints,
    '4-140': MarketCalculator.underOverTotalPoints,
    '2-621': MarketCalculator.firstHalfResultThreeWay,
    '4-606': MarketCalculator.firstHalfResultThreeWay,
    '2-106': MarketCalculator.firstHalfResultTwoWay,
    '4-77': MarketCalculator.firstHalfResultTwoWay,
    '2-111': MarketCalculator.basketBallHalfTimeFullTime,
    '2-113': MarketCalculator.winnerWithPointSpread,
    '4-286': MarketCalculator.winnerWithPointSpread,
    '2-660': MarketCalculator.firstPeriodResult,
    '4-673': MarketCalculator.firstPeriodResult,
    '2-105': MarketCalculator.basketballWinningMargin,
    '2-115': MarketCalculator.basketballFirstHalfPointSpread,
    '2-116': MarketCalculator.basketballFirstHalfPointsUnderOver,
    '4-64': MarketCalculator.basketballFirstHalfPointsUnderOver,
    '2-619': MarketCalculator.basketballUnderOverTotalPointsHomeTeam,
    '4-615': MarketCalculator.basketballUnderOverTotalPointsHomeTeam,
    '2-620': MarketCalculator.basketballUnderOverTotalPointsAwayTeam,
    '4-616': MarketCalculator.basketballUnderOverTotalPointsAwayTeam,
    '2-622': MarketCalculator.basketballMatchBetTotals,
    '2-107': MarketCalculator.basketballHighestScoringQuarter,
    '2-775': MarketCalculator.secondPeriodResult,
    '4-750': MarketCalculator.secondPeriodResult,
    '2-756': MarketCalculator.thirdPeriodResult,
    '4-751': MarketCalculator.thirdPeriodResult,
    '2-757': MarketCalculator.fourthPeriodResult,
    '4-752': MarketCalculator.fourthPeriodResult,
    '2-746': MarketCalculator.basketballfirstHalfUnderOverTotalPointsHomeTeam,
    '2-747': MarketCalculator.basketballfirstHalfUnderOverTotalPointsAwayTeam,
    '2-749': MarketCalculator.basketballFirstPeriodPointsUnderOver,
    '4-742': MarketCalculator.basketballFirstPeriodPointsUnderOver,
    '2-758': MarketCalculator.basketballSecondPeriodPointsUnderOver,
    '4-747': MarketCalculator.basketballSecondPeriodPointsUnderOver,
    '2-759': MarketCalculator.basketballThirdPeriodPointsUnderOver,
    '4-748': MarketCalculator.basketballThirdPeriodPointsUnderOver,
    '2-760': MarketCalculator.basketballFourthPeriodPointsUnderOver,
    '4-749': MarketCalculator.basketballFourthPeriodPointsUnderOver,
    '2-748': MarketCalculator.basketballHighestScoringHalf,
    '2-750': MarketCalculator.basketballPointRange180,
    '2-769': MarketCalculator.basketballPointRange250,
    // Masa Tenisi
    '2-633': MarketCalculator.tableTennisMatchResult,
    '4-653': MarketCalculator.tableTennisMatchResult,
    // Tennis
    '1-9': MarketCalculator.tennisMatchWinner,
    '4-56': MarketCalculator.tennisMatchWinner,
    '2-168': MarketCalculator.tennisUnderOverTotalGames,
    '4-191': MarketCalculator.tennisUnderOverTotalGames,
    '2-649': MarketCalculator.tennisWinnerWithHandicap,
    '4-613': MarketCalculator.tennisWinnerWithHandicap,
    '2-166': MarketCalculator.tennisFirstSetWinner,
    '4-57': MarketCalculator.tennisSetWinner,
    '2-169': MarketCalculator.tennisSecondSetWinner,
    //'4-57': MarketCalculator.tennisSecondSetWinner,
    '2-167': MarketCalculator.tennisTotalNumberOfSetsBestOfThree,
    '4-122': MarketCalculator.tennisTotalNumberOfSetsBestOfThree,
    '2-170': MarketCalculator.tennisTotalNumberOfSetsBestOfFive,
    '4-124': MarketCalculator.tennisTotalNumberOfSetsBestOfFive,
    '2-650': MarketCalculator.tennisTotalNumberOfGamesPlayerOne,
    '4-622': MarketCalculator.tennisTotalNumberOfGamesPlayerOne,
    '2-651': MarketCalculator.tennisTotalNumberOfGamesPlayerTwo,
    '4-623': MarketCalculator.tennisTotalNumberOfGamesPlayerTwo,
    '2-647': MarketCalculator.tennisToWinSetPlayerOne,
    '4-607': MarketCalculator.tennisToWinSetPlayerOne,
    '2-648': MarketCalculator.tennisToWinSetPlayerTwo,
    '4-608': MarketCalculator.tennisToWinSetPlayerTwo,
    '2-171': MarketCalculator.tennisCorrectSetScoreBestOfThreeSets,
    '4-274': MarketCalculator.tennisCorrectSetScoreBestOfThreeSets,
    '2-172': MarketCalculator.tennisCorrectSetScoreBestOfFiveSets,
    '4-276': MarketCalculator.tennisCorrectSetScoreBestOfFiveSets,
    '2-652': MarketCalculator.tennisTieBreak,
    '4-609': MarketCalculator.tennisTieBreak,
    '2-702': MarketCalculator.tennisSetHandikap,
    '2-701': MarketCalculator.tennisOddEvenTotal,
    '4-711': MarketCalculator.tennisOddEvenTotal,
    '2-703': MarketCalculator.tennisUnderOverResult,
    '4-713': MarketCalculator.tennisUnderOverResult,
    '2-704': MarketCalculator.tennisAnySetToNil,
    '4-714': MarketCalculator.tennisAnySetToNil,
    '2-705': MarketCalculator.tennisDoubleResult,
    '4-715': MarketCalculator.tennisDoubleResult,
    '4-718': MarketCalculator.matchContinues,
    '4-743': MarketCalculator.matchContinues,
    '4-744': MarketCalculator.matchContinues,
    '4-745': MarketCalculator.matchContinues,
    '4-746': MarketCalculator.matchContinues,
    '4-767': MarketCalculator.matchContinues,
    '4-768': MarketCalculator.matchContinues,
    '2-709': MarketCalculator.tennisHomePlayerWinExactlyOneSet,
    '4-719': MarketCalculator.tennisHomePlayerWinExactlyOneSet,
    '2-710': MarketCalculator.tennisAwayPlayerWinExactlyOneSet,
    '4-720': MarketCalculator.tennisAwayPlayerWinExactlyOneSet,
    // Handball
    '1-16': MarketCalculator.handballMatchResult,
    '4-3': MarketCalculator.handballMatchResult,
    '2-185': MarketCalculator.handballDoubleChance,
    '4-633': MarketCalculator.handballDoubleChance,
    '2-191': MarketCalculator.handballTotalGoalsUnderOver,
    '4-20': MarketCalculator.handballTotalGoalsUnderOver,
    '2-184': MarketCalculator.handballOddEvenTotalGoals,
    '4-133': MarketCalculator.handballOddEvenTotalGoals,
    '2-187': MarketCalculator.handballWinnerWithGoalSpread,
    // Volleyball
    '1-10': MarketCalculator.volleyballMatchResult,
    '4-114': MarketCalculator.volleyballMatchResult,
    '2-217': MarketCalculator.volleyballTotalPointsUnderOver,
    '4-18': MarketCalculator.volleyballTotalPointsUnderOver,
    '2-231': MarketCalculator.volleyballTwoWayHandicapSets,
    '4-239': MarketCalculator.volleyballTwoWayHandicapSets,
    '2-220': MarketCalculator.volleyballCorrectSetScore,
    '4-277': MarketCalculator.volleyballCorrectSetScore,
    '2-713': MarketCalculator.volleyballExactSets,
    '4-723': MarketCalculator.volleyballExactSets,
    '2-715': MarketCalculator.volleyballHomeTeamToWinExactlyOneSet,
    '2-716': MarketCalculator.volleyballAwayTeamToWinExactlyOneSet,
    // Snooker
    '1-15': MarketCalculator.snookerMatchWinner,
    '4-600': MarketCalculator.snookerMatchWinner,
    '2-641': MarketCalculator.snookerTwoWayMatchHandicapFrames,
    '2-642': MarketCalculator.snookerTotalFramesUnderOver,
    '4-602': MarketCalculator.snookerTotalFramesUnderOver,
    // Buz Hokeyi
    '1-21': MarketCalculator.hokeyMatchResult,
    '4-1': MarketCalculator.hokeyMatchResult,
    '2-159': MarketCalculator.hokeyDoubleChance,
    '4-128': MarketCalculator.hokeyDoubleChance,
    '2-164': MarketCalculator.hokeyTotalsOverUnder,
    '2-165': MarketCalculator.hokeyTotalsOverUnder,
    '4-21': MarketCalculator.hokeyTotalsOverUnder,
    '2-163': MarketCalculator.hokeyHandicap,
    '4-9': MarketCalculator.hokeyHandicap,
    '2-158': MarketCalculator.hokeyOddEven,
    '4-135': MarketCalculator.hokeyOddEven,
    '2-126': MarketCalculator.hokeyFirstPeriod,
    '2-137': MarketCalculator.hokeySecondPeriod,
    '2-127': MarketCalculator.hokeyThirdPeriod,
    '2-131': MarketCalculator.hokeyFirstPeriodDoubleChance,
    '2-132': MarketCalculator.hokeySecondPeriodDoubleChance,
    '2-133': MarketCalculator.hokeyThirdPeriodDoubleChance,
    '2-157': MarketCalculator.hokeyBothTeamScore,
    '4-619': MarketCalculator.hokeyBothTeamScore,
    '2-135': MarketCalculator.hokeyHighestPeriod,
    '2-617': MarketCalculator.hokeyWinningMargin,
    '4-639': MarketCalculator.hokeyWinningMargin,
    //'4-37': MarketCalculator.hokeyWhoWinsPeriod,
    // '4-37': MarketCalculator.hokeyWinsPeriod,
    // '2-601': MarketCalculator.hokeyResultTwoWay,
    // '4-120': MarketCalculator.hokeyResultTwoWay,
    // '2-161': MarketCalculator.hokeyHandicapTwoWay,
    // LongTerm
    "3-1": MarketCalculator.longTermResult
};
export var CouponChecker = function (event, market, outcome) {
    if (EventStatusManager.checkIsNotStarted(event.scores.statusId) || EventStatusManager.checkIsPostponed(event.scores.statusId) || EventStatusManager.checkIsCancelled(event.scores.statusId)) {
        return EventStatusManager.checkIsNotStarted(event.scores.statusId) ? MatchResultStatus.notStarted : MatchResultStatus.cancelled;
    }
    // console.log("event.marketType", market.marketType)
    var calculator = MarketCalculatePointer[market.marketType + '-' + market.marketSubType] || Function("return ".concat(MatchResultStatus.continues, ";"));
    return calculator(event, market, outcome);
};
