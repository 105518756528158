export var scoreStatusIds = [
    {
        "name": "Başlamadı",
        "shortName": "Bşl",
        "id": 1
    },
    {
        "name": "1. Yarı",
        "shortName": "1.Y",
        "id": 2
    },
    {
        "name": "2. Yarı",
        "shortName": "2.Y",
        "id": 3
    },
    {
        "name": "Devre Arası",
        "shortName": "D.A.",
        "id": 4
    },
    {
        "name": "Tamamlandı",
        "shortName": "MS",
        "id": 5
    },
    {
        "name": "1. Uzatma Devresi",
        "shortName": "1.U",
        "id": 6
    },
    {
        "name": "Uzatmalarda Devre Arası",
        "shortName": "Uz",
        "id": 7
    },
    {
        "name": "2. Uzatma Devresi",
        "shortName": "2.U",
        "id": 8
    },
    {
        "name": "Uzatmalar ile Tamamlandı",
        "shortName": "UZS",
        "id": 9
    },
    {
        "name": "Penaltılar",
        "shortName": "Pen",
        "id": 10
    },
    {
        "name": "Penaltılar ile Tamamlandı",
        "shortName": "PS",
        "id": 11
    },
    {
        "name": "1. Periyot",
        "shortName": "1.P",
        "id": 12
    },
    {
        "name": "2. Periyot",
        "shortName": "2.P",
        "id": 13
    },
    {
        "name": "3. Periyot",
        "shortName": "3.P",
        "id": 14
    },
    {
        "name": "4. Periyot",
        "shortName": "4.P",
        "id": 15
    },
    {
        "name": "1. Set",
        "shortName": "1.Set",
        "id": 16
    },
    {
        "name": "2. Set",
        "shortName": "2.Set",
        "id": 17
    },
    {
        "name": "3. Set",
        "shortName": "3.Set",
        "id": 18
    },
    {
        "name": "4. Set",
        "shortName": "4.Set",
        "id": 19
    },
    {
        "name": "5. Set",
        "shortName": "5.Set",
        "id": 20
    },
    {
        "name": "Ertelendi",
        "shortName": "Ert",
        "id": 21
    },
    {
        "name": "Durduruldu",
        "shortName": "Dur",
        "id": 22
    },
    {
        "name": "İptal Edildi",
        "shortName": "İpt",
        "id": 23
    },
    {
        "name": "3. Uzatma Devresi",
        "shortName": "3.U",
        "id": 24
    },
    {
        "name": "4. Uzatma Devresi",
        "shortName": "4.U",
        "id": 25
    },
    {
        "name": "5. Uzatma Devresi",
        "shortName": "5.U",
        "id": 26
    },
    {
        "name": "6. Uzatma Devresi",
        "shortName": "6.U",
        "id": 27
    },
    {
        "name": "7. Uzatma Devresi",
        "shortName": "7.U",
        "id": 28
    },
    {
        "name": "8. Uzatma Devresi",
        "shortName": "8.U",
        "id": 29
    },
    {
        "name": "9. Uzatma Devresi",
        "shortName": "9.U",
        "id": 30
    },
    {
        "name": "Devam Ediyor",
        "shortName": "Dev",
        "id": 31
    },
    {
        "name": "Uzatmalar Henüz Başlamadı",
        "shortName": "Uz",
        "id": 32
    },
    {
        "name": "Penaltılar Henüz Başlamadı",
        "shortName": "Pen",
        "id": 33
    },
    {
        "name": "5. Periyot",
        "shortName": "5. P",
        "id": 34
    },
    {
        "name": "6. Periyot",
        "shortName": "6. P",
        "id": 35
    },
    {
        "name": "7. Periyot",
        "shortName": "7. P",
        "id": 36
    },
    {
        "name": "8. Periyot",
        "shortName": "8. P",
        "id": 37
    },
    {
        "name": "9. Periyot",
        "shortName": "9. P",
        "id": 38
    },
    {
        "name": "6. Set",
        "shortName": "6. S",
        "id": 39
    },
    {
        "name": "7. Set",
        "shortName": "7. S",
        "id": 40
    },
    {
        "name": "8. Set",
        "shortName": "8. S",
        "id": 41
    },
    {
        "name": "9. Set",
        "shortName": "9. S",
        "id": 42
    },
    {
        "name": "10. Set",
        "shortName": "10. S",
        "id": 43
    },
    {
        "name": "1. inning",
        "shortName": "",
        "id": 44
    },
    {
        "name": "2. inning",
        "shortName": "",
        "id": 45
    },
    {
        "name": "Mola",
        "shortName": "",
        "id": 46
    },
    {
        "name": "1. Gün",
        "shortName": "",
        "id": 47
    },
    {
        "name": "1. Gün Sonu",
        "shortName": "",
        "id": 48
    },
    {
        "name": "2. Gün",
        "shortName": "",
        "id": 49
    },
    {
        "name": "2. Gün Sonu",
        "shortName": "",
        "id": 50
    },
    {
        "name": "3. Gün",
        "shortName": "",
        "id": 51
    },
    {
        "name": "3. Gün Sonu",
        "shortName": "",
        "id": 52
    },
    {
        "name": "4. Gün",
        "shortName": "",
        "id": 53
    },
    {
        "name": "4. Gün Sonu",
        "shortName": "",
        "id": 54
    },
    {
        "name": "5. Gün",
        "shortName": "",
        "id": 55
    },
    {
        "name": "5. Gün Sonu",
        "shortName": "",
        "id": 56
    }
];
