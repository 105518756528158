import { loginApi } from "../../services/login.services";
export var LOGIN_ACTION_TYPE;
(function (LOGIN_ACTION_TYPE) {
    LOGIN_ACTION_TYPE["LOGIN"] = "@@login/LOGIN";
    LOGIN_ACTION_TYPE["GET_AUTH"] = "@@login/GET_AUTH";
})(LOGIN_ACTION_TYPE || (LOGIN_ACTION_TYPE = {}));
export var login = function (request) { return ({
    type: LOGIN_ACTION_TYPE.LOGIN,
    payload: loginApi.login(request)
}); };
export var checkAouth = function (request) { return ({
    type: LOGIN_ACTION_TYPE.GET_AUTH,
    payload: loginApi.checkAouth(request)
}); };
