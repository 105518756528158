var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
export default function Menu() {
    return (_jsx("div", __assign({ className: "card" }, { children: _jsx("div", __assign({ className: "card-body" }, { children: _jsxs("ul", __assign({ className: "menu", style: { padding: innerWidth <= 968 ? "10px" : "0px" } }, { children: [_jsx("li", { children: _jsx(Link, __assign({ to: "/yardim/uyelik" }, { children: _jsx("span", { children: "\u00DCyelik" }, void 0) }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/yardim/para-yatir" }, { children: _jsx("span", { children: "Para Yat\u0131rma" }, void 0) }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/yardim/para-cekme" }, { children: _jsx("span", { children: "Para \u00C7ekme" }, void 0) }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/yardim/sports-oyunlari" }, { children: _jsx("span", { children: "T\u00FCm\u00FC" }, void 0) }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/yardim/canli-sports" }, { children: _jsx("span", { children: "Canl\u0131" }, void 0) }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/yardim/sports" }, { children: _jsx("span", { children: "Pre" }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0) }), void 0));
}
