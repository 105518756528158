var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { popularCompetition, sportsbookFilter, sportsbookList, sportsSummery, sportsSummerySubject } from "../../store/sportsbook.store";
import { useNavigate } from "react-router-dom";
import { useForceUpdate } from "../../lib/misc.functions";
import { generateCompetitionGroupList, setFilterCompetitions } from "../../services/sportsbook.services";
import _ from "lodash";
import { SearchBar } from "../../pages/home/home";
import { isMobileView } from "../../app";
import { betSlipActionSubject } from "../../store/betslip.store";
import { useTranslation } from "react-i18next";
var selectedLiveComp = [];
export default function SideMenu(props) {
    var forceUpdate = useForceUpdate();
    var navigate = useNavigate();
    var eventType = props.eventType;
    var sportSlug = props.sportSlug;
    var paramsCompetitionId = props.competitionId;
    var openLeftMenu = props.openLeftMenu;
    var _a = useState(null), selectedComp = _a[0], setSelectedComp = _a[1];
    var _b = useState(null), selectedSport = _b[0], setSelectedSport = _b[1];
    var i18n = useTranslation().i18n;
    var base = '/' + i18n.resolvedLanguage;
    useEffect(function () {
        var betSlipActionSubject_subscription = betSlipActionSubject.subscribe(function (events) {
            forceUpdate();
        });
        return function () {
            betSlipActionSubject_subscription.unsubscribe();
        };
    }, []);
    var setFilter = function (competitionId, sportSlug, eventType) {
        if (competitionId == 'clear') {
            setFilterCompetitions('clear');
            navigate(base + '/sports/' + sportSlug + '/' + eventType);
        }
        else {
            sportsbookFilter.filterCompetition = [];
            setFilterCompetitions(competitionId);
            forceUpdate();
            navigate(base + '/sports/' + sportSlug + '/' + eventType + '/' + competitionId);
        }
    };
    useEffect(function () {
        var sportsSummerySubject_subscription = sportsSummerySubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            sportsSummerySubject_subscription.unsubscribe();
        };
    }, []);
    var popularCompetitions = popularCompetition;
    useEffect(function () {
        if (sportSlug) {
            setSelectedSport(sportSlug + '-' + (eventType == 'uzunvade' ? '2' : '1'));
            var sportsCompetitions = generateCompetitionGroupList(_.orderBy(sportsbookList, ['eventDate', 'competitionGroupName', 'competitionName']).filter(function (el) {
                if (sportSlug === el.sportSlug) {
                    if ((eventType === 'tumu' && Number(el.eventType) === 1) ||
                        (eventType === 'canli' && Number(el.eventType) === 1 && Number(el.isLiveEvent) === 1 && Number(el.eventType) === 1) ||
                        (eventType === 'uzunvadeli' && Number(el.eventType) === 2 && el.marketOutcomeCount > 0)) {
                        return el;
                    }
                }
                else if (sportSlug == null && Number(el.eventType) !== 2) {
                    return el;
                }
            }), [sportSlug]);
            if (paramsCompetitionId) {
                for (var _i = 0, sportsCompetitions_1 = sportsCompetitions; _i < sportsCompetitions_1.length; _i++) {
                    var sportsCompetition = sportsCompetitions_1[_i];
                    for (var _a = 0, _b = Object.entries(sportsCompetition.competitions); _a < _b.length; _a++) {
                        var _c = _b[_a], key = _c[0], value = _c[1];
                        // @ts-ignore
                        if (value[0].competitionId == paramsCompetitionId) {
                            setSelectedComp(sportsCompetition);
                            break;
                        }
                    }
                }
            }
            else {
                // setSelectedComp(sportsCompetitions[0]);
            }
        }
    }, []);
    return (_jsx(_Fragment, { children: !isMobileView && _jsxs("div", __assign({ className: "left-menu-container", id: "left-menu-container", style: { width: "40px" } }, { children: [openLeftMenu && _jsx("div", __assign({ className: "left-menu-search" }, { children: _jsx(SearchBar, {}, void 0) }), void 0), openLeftMenu && _jsxs("div", __assign({ className: "second-tabs-bc" }, { children: [_jsxs("div", __assign({ onClick: function () {
                                navigate(base + '/sports/futbol/canli');
                            }, className: "tab-bc selected-underline ".concat(window.location.href.includes("sports") && window.location.href.includes("canli") ? 'active' : ''), title: "" }, { children: [_jsx("span", { children: "CANLI" }, void 0), _jsxs("span", { children: ["(", sportsbookList.filter(function (el) {
                                            return Number(el.isLiveEvent) === 1;
                                        }).length, ")"] }, void 0)] }), void 0), _jsxs("div", __assign({ onClick: function () {
                                navigate(base + '/sports/futbol/tumu');
                            }, className: "tab-bc selected-underline ".concat(window.location.href.includes("sports") && window.location.href.includes("tumu") ? 'active' : ''), title: "" }, { children: [_jsx("span", { children: "SPOR" }, void 0), _jsxs("span", { children: ["(", sportsbookList.filter(function (el) {
                                            return Number(el.isLiveEvent) === 0;
                                        }).length, ")"] }, void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "pp-sport-list-holder-bc" }, { children: _jsxs("div", __assign({ className: "left-menu-scroll" }, { children: [_jsx("div", __assign({ className: "sp-sub-list-bc popular-matches   " }, { children: _jsxs("div", __assign({ className: "sp-s-l-head-bc", title: "POP\u00DCLER MA\u00C7LAR" }, { children: [_jsx("i", { className: "sp-s-l-h-flag-bc bc-i-tournament" }, void 0), _jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: "POP\u00DCLER MA\u00C7LAR" }), void 0) }), void 0), _jsx("i", { className: "sp-s-l-h-arrow-icon-bc bc-i-small-arrow-right" }, void 0)] }), void 0) }), void 0), sportsSummery.map(function (item, key) {
                                var originSportsbookList = _.orderBy(sportsbookList, ['eventDate', 'competitionGroupName', 'competitionName']).filter(function (el) {
                                    // console.log(params)
                                    if (item.sportSlug === el.sportSlug) {
                                        if ((item.eventType === '1' && Number(el.eventType) === 1) ||
                                            (Number(el.eventType) === 1 && Number(el.isLiveEvent) === 1 && Number(el.eventType) === 1) ||
                                            (item.eventType === '2' && Number(el.eventType) === 2 && el.marketOutcomeCount > 0)) {
                                            return el;
                                        }
                                    }
                                    else if (item.sportSlug == null) {
                                        return el;
                                    }
                                });
                                var sportsCompetitions = generateCompetitionGroupList(originSportsbookList, ['eventDate']);
                                return _jsxs("div", __assign({ className: "sp-sub-list-bc ".concat(item.sportSlug, " ").concat((selectedSport === item.sportSlug + "-" + item.eventType && openLeftMenu) ? "active selected" : '') }, { children: [_jsxs("div", __assign({ onClick: function () {
                                                if (openLeftMenu) {
                                                    if (selectedSport == item.sportSlug + "-" + item.eventType) {
                                                        setSelectedSport('');
                                                    }
                                                    else {
                                                        setSelectedSport(item.sportSlug + "-" + item.eventType);
                                                    }
                                                }
                                                else {
                                                    setSelectedSport(item.sportSlug + "-" + item.eventType);
                                                    setFilterCompetitions("clear");
                                                    var sportsBookContainer = document.getElementById('sportsbook-container-id');
                                                    var multiColumnToIcon = document.getElementById('multi-column-to-icon-id');
                                                    multiColumnToIcon.classList.remove("bc-i-block-to-right");
                                                    multiColumnToIcon.classList.add("bc-i-block-to-left");
                                                    sportsBookContainer.classList.add("multi-column-view-expanded");
                                                    navigate(base + '/sports/' + item.sportSlug + '/' + eventType);
                                                }
                                                // forceUpdate();
                                            }, className: "sp-s-l-head-bc", title: item.sportSlug }, { children: [_jsx("i", { className: "sp-s-l-h-flag-bc bc-i-".concat(item.sportSlug) }, void 0), _jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsxs("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: [item.sportName, " ", item.eventType === '2' ? 'UV' : '', " "] }), void 0) }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: sportsCompetitions.length }), void 0), _jsx("i", { className: "sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0), _jsx("div", __assign({ className: "sp-s-l-b-content-bc" }, { children: sportsCompetitions.map(function (itemComp, key) {
                                                var cmptCount = eventType == 'canli' ? itemComp.competitionCount["live"] : itemComp.competitionCount["pre"];
                                                if (Number(cmptCount) > 0) {
                                                    return _jsxs("div", __assign({ className: "sp-sub-list-bc ".concat(selectedComp != null && selectedComp.competitionGroupName == itemComp.competitionGroupName && selectedComp.sportId == itemComp.sportId ? "active selected" : '') }, { children: [_jsxs("div", __assign({ onClick: function () {
                                                                    if (selectedComp == null) {
                                                                        setSelectedComp(itemComp);
                                                                        var competitionIds = Object.entries(itemComp.competitions).map(function (cmp) { return cmp[1].map(function (inEl) { return inEl.competitionId; }); }).map(function (inn) { return inn.filter(function (value, _index, current_value) { return current_value.indexOf(value) === _index; }); }).join(',');
                                                                        setFilter(competitionIds, item.sportSlug, eventType);
                                                                        // console.log(Object.entries(itemComp.competitions));
                                                                    }
                                                                    else {
                                                                        if (selectedComp.competitionGroupName == itemComp.competitionGroupName && selectedComp.sportId == itemComp.sportId) {
                                                                            setSelectedComp(null);
                                                                            setFilter("clear", item.sportSlug, eventType);
                                                                        }
                                                                        else {
                                                                            setSelectedComp(itemComp);
                                                                            var competitionIds = Object.entries(itemComp.competitions).map(function (cmp) { return cmp[1].map(function (inEl) { return inEl.competitionId; }); }).map(function (inn) { return inn.filter(function (value, _index, current_value) { return current_value.indexOf(value) === _index; }); }).join(',');
                                                                            setFilter(competitionIds, item.sportSlug, eventType);
                                                                            // console.log(competitionIds);
                                                                        }
                                                                    }
                                                                    forceUpdate();
                                                                }, className: "sp-s-l-head-bc", title: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }, { children: [_jsx("i", { className: "sp-s-l-h-flag-bc flag-bc ".concat(itemComp.competitionGroupName === "IIIskoçya" ? 'scotland' : itemComp.countryName.toLowerCase()) }, void 0), _jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }), void 0) }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: cmptCount }), void 0), _jsx("i", { className: "sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down" }, void 0)] }), void 0), _jsx("div", __assign({ className: "sp-s-l-b-content-bc" }, { children: Object.entries(itemComp.competitions).map(function (comp, key) {
                                                                    return (_jsxs("div", __assign({ onClick: function () {
                                                                            var competitionIds = comp[1].map(function (cmp) { return cmp.competitionId; }).filter(function (value, _index, current_value) { return current_value.indexOf(value) === _index; }).join(',');
                                                                            setFilter(competitionIds, item.sportSlug, eventType);
                                                                        }, className: "sp-sub-list-bc" }, { children: [_jsxs("div", __assign({ className: "sp-s-l-head-bc", title: "" }, { children: [_jsx("div", __assign({ className: "sp-s-l-h-title-content ellipsis" }, { children: _jsx("p", __assign({ className: "sp-s-l-h-title-bc ellipsis" }, { children: comp[1][0].competitionName.replace('UEFA Şampiyonlar Ligi Elemeler', 'UEFA Şampiyonlar Ligi Elemeleri').replace('UEFA Avrupa Ligi, Elemeler', 'UEFA Avrupa Ligi Elemeleri') }), void 0) }), void 0), _jsx("span", __assign({ className: "sp-s-l-b-c-i-g-league-c-bc" }, { children: comp[1].length }), void 0)] }), void 0), _jsx("div", { className: "sp-s-l-b-content-bc" }, void 0)] }), "com-" + key));
                                                                }) }), void 0)] }), "eventSporMenu2".concat(key));
                                                }
                                            }) }), void 0)] }), "sportsCompetitions-" + key);
                            })] }), void 0) }), void 0)] }), void 0) }, void 0));
}
