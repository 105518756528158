import { ApiService } from '../../lib/http.service';
import { env } from "../../definitions/global.vars";
export var Api = new ApiService();
export var RegisterApi = (function () {
    return {
        register: function (request) {
            return Api.start('post', env.core_api + '/member/api/registration/', request, true, 60000);
        },
        validation_sms: function (request) {
            return Api.start('post', env.core_api + '/member/api/validation_sms/', request, true, 10000);
        }
    };
})();
