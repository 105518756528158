var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { dateTimeFormat, dateTimeShortStringFormat, getDateHumanize, useForceUpdate } from "../../../lib/misc.functions";
import { Event } from "../components/event";
import React, { useEffect, useMemo } from "react";
import { mobileHighLightedMarket, myHighLightedMarketGroups } from "../../../services/sportsbook.services";
import { isMobileView } from "../../../app";
export function Eventlist(props) {
    // let visibleSportsBook:any = [];
    var shownSportsBook = props.shownSportsBook;
    var params = props.params;
    var width = window.innerWidth;
    var forceUpdate = useForceUpdate();
    var openEventDetail = props.openEventDetail;
    var showEventDetail = props.showEventDetail;
    var page = props.page || 0;
    var prevLabel = '';
    var getHeader = function (label, sportSlug) {
        if (prevLabel != label) {
            prevLabel = label;
            return (_jsx("div", __assign({ className: "c-segment-holder-bc head" }, { children: _jsxs("div", __assign({ className: "c-head-bc" }, { children: [_jsx("time", __assign({ className: "c-title-bc ellipsis" }, { children: label }), void 0), isMobileView && _jsx("div", __assign({ className: "market-filter-cell-bc" }, { children: mobileHighLightedMarket.selected &&
                                mobileHighLightedMarket.selected.labels.map(function (el, key) {
                                    return _jsx("div", __assign({ className: "market-filter-info-bc" }, { children: el }), "mobileHighLightedMarket-" + key);
                                }) }), void 0)] }), void 0) }), void 0));
        }
        else {
            return (_jsx(React.Fragment, {}, void 0));
        }
    };
    var vh = window.outerHeight;
    var pageSize = vh / 50;
    // console.log(pageSize)
    useEffect(function () {
        forceUpdate();
    }, [page]);
    var date = "";
    var MemoList = function () {
        return (_jsx(React.Fragment, { children: shownSportsBook.slice(0, ((page + 1) * pageSize)).map(function (event, key) {
                // console.log("params.eventType",event.eventDate)
                params.eventType != 'uzunvadeli' ? date = getDateHumanize(event.eventDate) : date = dateTimeShortStringFormat(event.eventDate);
                return (_jsxs("div", __assign({ className: "competition-bc" }, { children: [getHeader(dateTimeFormat(event.eventDate).split(" ")[0], event.sportSlug), !isMobileView && _jsx("ul", __assign({ id: "eventId_" + event.eventId, className: "multi-column-content ".concat((showEventDetail === null || showEventDetail === void 0 ? void 0 : showEventDetail.eventId) === event.eventId ? ' active ' : '', "  multi-column-show-type-undefined") }, { children: _jsx(Event, { event: event, eventType: params.eventType, context: openEventDetail }, "eventId_" + event.eventId) }), void 0), isMobileView &&
                            _jsx("div", __assign({ id: "eventId_" + event.eventId, className: "c-segment-holder-bc single-g-info-bc" }, { children: _jsx(Event, { event: event, eventType: params.eventType, context: openEventDetail }, "eventId_" + event.eventId) }), "list" + key)] }), "list" + key));
            }) }, void 0));
    };
    // console.log("params.sportsbookFilter",props.sportsbookFilter)
    var result = useMemo(function () { return MemoList(); }, [page, params.sportSlug, params.eventType, JSON.stringify(props.sportsbookFilter), JSON.stringify(myHighLightedMarketGroups), JSON.stringify(mobileHighLightedMarket)]);
    return result;
}
