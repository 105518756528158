import { loginSubject, member } from "../store/login.store";
import { env, successLogStyle } from "../definitions/global.vars";
import { refreshMemberInfo } from "./login.services";
import { eventMapping, eventUpdateSubject, marketIdMapping, marketMapping, } from "../store/sportsbook.store";
import { eventEndedStatuses } from "../definitions/constants/event-result-type";
import { addToBetSlip } from "./betslip.services";
import { Storage } from "../lib/localstorege.service";
import { checkAndAddHighLightedMarkets, compireEventKeys, compireMarketKeys, generateEventSlider, getSportSummery, getUpdateFromSportsbook, reOrganizeEventRatios, updateEventComments } from "./sportsbook.services";
import { modalServiceSubject } from "../components/modal/modal";
import html2canvas from 'html2canvas';
import { eventSliderSubject } from "../pages/home/widgets/slider";
import { ServerDate } from "../lib/misc.functions";
var updateTimer;
export var AuthWSProvider = null;
export var SportWSProvider = null;
function sendFocusMessage() {
    try {
        var _message = {
            $type: 'setPageStatus',
            data: 'focus',
        };
        if (AuthWSProvider && AuthWSProvider.readyState === 1) {
            AuthWSProvider.send(JSON.stringify({ type: _message }));
        }
    }
    catch (e) {
        console.log('web-socket service send Message ', e);
    }
}
function sendBlurMessage() {
    try {
        var _message = {
            $type: 'setPageStatus',
            data: 'blur',
        };
        if (AuthWSProvider && AuthWSProvider.readyState === 1) {
            AuthWSProvider.send(JSON.stringify({ type: _message }));
        }
    }
    catch (e) {
        console.log('web-socket service send Message ', e);
    }
}
export function sendWSMessage(mType, data) {
    try {
        var _message = {
            $type: mType,
            data: data,
        };
        if (AuthWSProvider && AuthWSProvider.readyState === 1) {
            AuthWSProvider.send(JSON.stringify({ type: _message }));
        }
    }
    catch (e) {
        console.log('web-socket service send Message ', e);
    }
}
var WebSocketService = /** @class */ (function () {
    function WebSocketService() {
        this.token = null;
        this.AUTH = env.accounting_wss;
        this.SPORTBOOK = env.sportsbook_wss;
        this.isConnected = false;
    }
    WebSocketService.prototype.ping = function (ws) {
        this.timer = setInterval(function () {
            if (ws && ws.readyState === ws.OPEN) {
                ws.send('__ping__');
            }
        }, 1000);
    };
    WebSocketService.prototype.connect = function (wssUrl, token, isFirst) {
        var _this = this;
        if (token === void 0) { token = null; }
        if (isFirst === void 0) { isFirst = false; }
        this.wssUrl = wssUrl;
        this.token = token;
        if (this.wssUrl === this.AUTH && this.token === null) {
            return null;
        }
        var fullUrl = token ? wssUrl + "/" + token + "/" : wssUrl;
        this.ws = new WebSocket(fullUrl);
        this.ws.onopen = function () {
            _this.isConnected = true;
            if (_this.wssUrl === _this.AUTH) {
                console.log("%c* Member websocket servis connected", successLogStyle);
                AuthWSProvider = _this.ws;
            }
            if (_this.wssUrl === _this.SPORTBOOK) {
                console.log("%c* Sportsbook websocket servis connected", successLogStyle);
                SportWSProvider = _this.ws;
                var lastConnectionDown = Storage.get('ConnectionDownTime') || new Date().getTime();
                // console.log("lastConnectionDown",lastConnectionDown)
                var lostconnectionBefore = (new Date().getTime() - lastConnectionDown) / 1000;
                // console.log("lostconnectionBefore",lostconnectionBefore)
                if (lostconnectionBefore > 120 && !isFirst) {
                    window.location.reload();
                }
                if (lostconnectionBefore > 1 && !isFirst) {
                    // let apiUrl = env.currentbook_api + "/current/"
                    var apiUrl = env.currentbook_api + "/sportsbook/?v=" + Math.random().toString();
                    if (lostconnectionBefore > 3 && !isFirst) {
                        // apiUrl = env.currentbook_api + "/current/60/"
                        apiUrl = env.currentbook_api + "/sportsbook/?v=" + Math.random().toString();
                        // window.location.reload();
                    }
                    if (lostconnectionBefore > 30 && !isFirst) {
                        apiUrl = env.currentbook_api + "/sportsbook/?v=" + Math.random().toString();
                        window.location.reload();
                    }
                    getUpdateFromSportsbook(apiUrl);
                }
                // removeEndedEvents();
            }
        };
        if (this.wssUrl === this.AUTH) {
            this.ws.onmessage = function (e) {
                clearInterval(_this.timer);
                _this.handleAccountingMessage(JSON.parse(e.data));
                // this.ping(this.ws);
            };
            window.addEventListener('focus', sendFocusMessage);
            window.addEventListener('blur', sendBlurMessage);
        }
        if (this.wssUrl === this.SPORTBOOK) {
            this.ws.onmessage = function (e) {
                var _a;
                var message = JSON.parse(e.data);
                var updated = [];
                if (message.type === 'program') {
                    if (message.events) {
                        var incomingEvents = message.events;
                        // @ts-ignore
                        for (var _i = 0, _b = Object.entries(incomingEvents); _i < _b.length; _i++) {
                            var _c = _b[_i], intEventId = _c[0], eventData = _c[1];
                            var eventId = intEventId.toString();
                            if (eventMapping[eventId]) {
                                var durationInMinutes = eventMapping[eventId].sportSlug === 'futbol' ? 85 : 35;
                                var diffMin = ServerDate().getTime() - new Date(eventMapping[eventId].eventDate).getTime();
                                if (eventMapping[eventId] && ((eventMapping[eventId].isLiveEvent === '1' &&
                                    eventEndedStatuses.indexOf(eventMapping[eventId].liveScoreStatus) !== -1 &&
                                    diffMin > (durationInMinutes * 60000))
                                    || (eventMapping[eventId].isLiveEvent === '0' && new Date(eventMapping[eventId].eventDate) < ServerDate()))) {
                                    eventMapping[eventId].isRemoved = true;
                                    if (eventMapping[eventId].isAdded) {
                                        addToBetSlip(eventMapping[eventId], null, null, 'removeEvent');
                                    }
                                    eventUpdateSubject.next([eventId]);
                                }
                                else {
                                    eventMapping[eventId].isRemoved = false;
                                    eventUpdateSubject.next([eventId]);
                                }
                                // console.log(eventMapping[eventId], incomingEvents[eventId])
                                if (compireEventKeys(eventMapping[eventId], incomingEvents[eventId])) {
                                    updated.push(eventId);
                                }
                            }
                            // else{
                            //   const apiService = new ApiService();
                            //   apiService.start('get', env.sportsbook_api + /event/+eventId+'/', null, false)
                            //     .then((response: any) => {
                            //       if(response.status && response.data && response.data.length > 0){
                            //         const newEvent = response.data[0];
                            //         if(addToSportsBook(newEvent)){
                            //           updated.push(eventId)
                            //         }
                            //       }
                            //     });
                            // }
                        }
                    }
                    if (message.markets) {
                        var incomingMarkets = message.markets;
                        // @ts-ignore
                        for (var _d = 0, _e = Object.entries(incomingMarkets); _d < _e.length; _d++) {
                            var _f = _e[_d], intEventId = _f[0], eventData = _f[1];
                            var eventId = intEventId.toString();
                            if (eventMapping[eventId]) {
                                var durationInMinutes = eventMapping[eventId].sportSlug === 'futbol' ? 85 : 35;
                                var diffMin = ServerDate().getTime() - new Date(eventMapping[eventId].eventDate).getTime();
                                if (eventMapping[eventId] && ((eventMapping[eventId].isLiveEvent === '1' &&
                                    eventEndedStatuses.indexOf(eventMapping[eventId].liveScoreStatus) !== -1 &&
                                    diffMin > (durationInMinutes * 60000)) ||
                                    (eventMapping[eventId].isLiveEvent === '0' && new Date(eventMapping[eventId].eventDate) < ServerDate()))) {
                                    eventMapping[eventId].isRemoved = true;
                                    if (eventMapping[eventId].isAdded) {
                                        addToBetSlip(eventMapping[eventId], null, null, 'removeEvent');
                                    }
                                }
                                else {
                                    eventMapping[eventId].isRemoved = false;
                                }
                            }
                            if (marketIdMapping[eventId]) {
                                eventMapping[eventId].hasCustomratio = false;
                                // @ts-ignore
                                for (var _g = 0, _h = Object.entries(incomingMarkets[eventId]); _g < _h.length; _g++) {
                                    var _j = _h[_g], marketId = _j[0], marketData = _j[1];
                                    var currentMarket = marketIdMapping[eventId][marketId];
                                    if (currentMarket) {
                                        if (compireMarketKeys(eventMapping[eventId], currentMarket, incomingMarkets[eventId][marketId])) {
                                            updated.push(eventId.toString());
                                        }
                                    }
                                    else {
                                        if (Number((_a = incomingMarkets[eventId][marketId]) === null || _a === void 0 ? void 0 : _a.channelWeb) === 1) {
                                            marketIdMapping[eventId][marketId] = incomingMarkets[eventId][marketId];
                                            marketMapping[eventId].push(incomingMarkets[eventId][marketId]);
                                            checkAndAddHighLightedMarkets(eventMapping[eventId.toString()], marketIdMapping[eventId][marketId]);
                                            updated.push(eventId);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (updated.length > 0) {
                    clearTimeout(updateTimer);
                    updateTimer = setTimeout(function () {
                        eventUpdateSubject.next(updated);
                    }, 130);
                }
            };
        }
        // this.ws.addEventListener('error', function (event:any) {
        //   console.log('WebSocket error: ', event);
        // });
        //
        // setInterval(()=>{
        //   if(window.navigator.onLine===false){
        //     if(AuthWSProvider && AuthWSProvider.readyState===1){
        //       AuthWSProvider.close();
        //     }
        //     if(SportWSProvider && SportWSProvider.readyState===1){
        //       SportWSProvider.close();
        //     }
        //   }
        // },3000);
        this.ws.onclose = function () {
            if (_this.wssUrl === _this.AUTH) {
                window.removeEventListener('focus', sendFocusMessage);
                window.removeEventListener('blur', sendBlurMessage);
            }
            if (_this.wssUrl === _this.SPORTBOOK && _this.isConnected) {
                _this.isConnected = false;
                Storage.set('ConnectionDownTime', new Date().getTime());
                // console.log("ConnectionDownTime", new Date());
            }
            _this.ws = null;
            setTimeout(function () {
                _this.connect(_this.wssUrl, _this.token, false);
            }, 2000);
        };
        if (this.wssUrl === this.AUTH) {
            return this.ws;
        }
        return member;
    };
    WebSocketService.prototype.sendMessageWebSocket = function (message, data) {
        if (data === void 0) { data = ''; }
        try {
            var _message = {
                $type: message,
                data: data,
            };
            if (AuthWSProvider && AuthWSProvider.readyState === 1) {
                AuthWSProvider.send(JSON.stringify({ type: _message }));
            }
        }
        catch (e) {
            console.log('web-socket service send Message ', e);
        }
    };
    WebSocketService.prototype.handleAccountingMessage = function (message) {
        // console.log(message)
        var _this = this;
        if (message.a === 'renew_checkauth') {
            refreshMemberInfo();
        }
        else if (message.a === 'new_notification') {
            modalServiceSubject.next({
                title: message.d.header,
                content: '<div style="white-space: pre-wrap; max-height: calc( 70vh - 160px);">' + message.d.message + '</div>',
                confirm: {
                    cancel: {
                        label: 'Tamam',
                    }
                }
            });
            member.notication_count = message.d.total;
            loginSubject.next(member);
        }
        else if (message.a === 'renew_notification') {
            member.notication_count = message.d.total;
            loginSubject.next(member);
        }
        else if (message.a === 'force_logout') {
            member.id = null;
            member.username = null;
            Storage.del('token');
            loginSubject.next(null);
            window.location.reload();
        }
        else if (message.a === 'force_reload') {
            window.location.reload();
        }
        else if (message.a === 'go_to_url') {
            window.location.href = message.d.url;
        }
        else if (message.a === 'show_popup') {
            modalServiceSubject.next({
                title: message.d.header,
                content: message.d.message,
            });
        }
        else if (message.a === 'getScreenShot') {
            var domElement = document.getElementsByTagName("body")[0];
            // @ts-ignore
            html2canvas(domElement, {
                scale: 1,
            }).then(function (canvas) {
                var imgData = canvas.toDataURL("image/jpeg");
                _this.sendMessageWebSocket('setScreenShot', imgData);
            });
        }
        else if (message.a === 'update_sliders') {
            generateEventSlider().then(function () {
                eventSliderSubject.next(true);
            });
        }
        else if (message.a === 'update_sports_summery') {
            getSportSummery();
        }
        else if (message.a === 'new_post') {
            updateEventComments(JSON.parse(message.d), 'add');
        }
        else if (message.a === 'delete_post') {
            updateEventComments(message.d, 'remove');
        }
        else if (message.a === 'update_post') {
            updateEventComments(message.d, 'update');
        }
        if (message.s === 'member_member') {
            //fixme member status a gore oturumu sonlandırmak gerekir.
            // console.log(message.d)
            if (message.d.status && message.d.status === 1) {
                if (!member || !member.id) {
                    Object.assign(member, message.d);
                    if (message.d.hasOwnProperty('additional_ratio')) {
                        reOrganizeEventRatios();
                    }
                }
                else {
                    // @ts-ignore
                    for (var _i = 0, _a = Object.entries(message.d); _i < _a.length; _i++) {
                        var _b = _a[_i], s = _b[0], v = _b[1];
                        if (s === 'additional_ratio' && member[s] !== v) {
                            window.location.reload();
                        }
                        // @ts-ignore
                        member[s] = v;
                    }
                    if (!message.d.hasOwnProperty('additional_ratio')) {
                        delete member.additional_ratio;
                    }
                }
            }
            else {
            }
            // getNotificationCount(Storage.get('token')).then((response: any) => {
            //   if (response.status) {
            //     member.notication_count = response.data;
            //     loginSubject.next(member);
            //   }
            // }).catch((e: any) => {
            //   loginSubject.next(member);
            // }).finally(() => {
            //   loginSubject.next(member);
            // })
            loginSubject.next(member);
            console.log(member);
            // if(message.d.status && message.d.status === 1){
            //   Object.assign(member, message.d);
            //
            // }else{
            //
            //   Object.assign(member, new Member());
            //
            //   if(member && member.id && member.additional_ratio) {
            //
            //     console.log(AdditionalRatioCampaign.currentRatio)
            //     console.log(Number(member.additional_ratio))
            //
            //     if(AdditionalRatioCampaign.currentRatio !== Number(member.additional_ratio)){
            //       AdditionalRatioCampaign.currentRatio = Number(member.additional_ratio);
            //       window.location.reload();
            //     }
            //
            //   }
            //
            //   Storage.del('token')
            //   loginSubject.next(new Member());
            // }
            //
            //
        }
    };
    return WebSocketService;
}());
export { WebSocketService };
export var AuthWS = new WebSocketService();
export var SportbookWS = new WebSocketService();
