import { Subject } from 'rxjs';
import { BetSlipOptions } from "../models/betslip.model";
import { createContext, useContext } from "react";
export var BetSlipList = [];
export var BetSlipOption = new BetSlipOptions();
export var betSlipActionSubject = new Subject();
export var betSlipVisibleSubject = new Subject();
export var BetSlipContext = createContext({
    betSlipContext: {},
    setBetSlipContext: function (c) {
        console.log("setIsAuthenticated>>", c);
    }
});
export var useBetSlipContext = function () { return useContext(BetSlipContext); };
