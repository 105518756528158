import { ApiService } from '../../lib/http.service';
export var Api = new ApiService();
export var NotificationsApi = (function () {
    return {
        get_notification: function (request) {
            return Api.start('post', 'https://notification.1xwon.com/get_notification/', request, true);
        },
        delete_notification: function (request) {
            return Api.start('post', 'https://api.1xwon.com/misc/api/mark-deleted-notification/', request, true);
        },
    };
})();
