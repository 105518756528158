var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { LiveScoreStatusType } from "../../definitions/constants/event-status-type.constant";
import "../../assets/css/betradar.css";
import { ServerDate, useForceUpdate } from "../../lib/misc.functions";
import { marketCategories, marketNamesMappingData, } from "../../definitions/constants/market-categories.constant";
import { eventDetailSubject, eventUpdateSubject, marketMapping, statisticsMap } from "../../store/sportsbook.store";
import { Odd } from "./components/odd";
import _ from "lodash";
import { commentUpdateSubject, getPostComment, sharePost } from "../../services/societyWon.services";
// import {getLiveStreamUrl} from "../../services/sportsbook.services";
import { member } from "../../store/login.store";
import { modalServiceSubject } from "../../components/modal/modal";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { inProgress, isMobileView } from "../../app";
import { betSlipActionSubject } from "../../store/betslip.store";
import { isAndroid, isIOS, isMacOs, isWindows, osVersion } from "react-device-detect";
import { EventDetailHeader } from "./widgets/eventdetail.header";
import { useTranslation } from "react-i18next";
export var eventDetailVisiblity = { visible: false };
// get Bombay time
var event = false;
var liveStreamPlayer;
var updateTimer;
export function EventDetail(props) {
    var marketGroups = [];
    var eventMarkets = [];
    var eventMarketMapping = {};
    var statisticUrl;
    var closeEventDetail = props.closeEventDetail;
    var eventType = props.eventType;
    var sportSlug = props.sportSlug;
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    var _a = useState(false), showMarketGroup = _a[0], setShowMarketGroup = _a[1];
    var _b = useState(1), showTabs = _b[0], setShowTabs = _b[1];
    var i18n = useTranslation().i18n;
    var base = '/' + i18n.resolvedLanguage;
    useEffect(function () {
        var betSlipActionSubject_subscription = betSlipActionSubject.subscribe(function (events) {
            if (typeof events === 'object' && event) {
                if (['remove', 'toggle'].includes(events.action)) {
                    if (event && events.betSlip.sportEvent && events.betSlip.sportEvent.eventId === event.eventId) {
                        forceUpdate();
                    }
                }
            }
        });
        var eventUpdateSubject_subscription = eventUpdateSubject.subscribe(function (events) {
            if (event && events.includes(event.eventId)) {
                clearTimeout(updateTimer);
                updateTimer = setTimeout(function () {
                    forceUpdate();
                }, 300);
            }
        });
        return function () {
            betSlipActionSubject_subscription.unsubscribe();
            eventUpdateSubject_subscription.unsubscribe();
            clearTimeout(updateTimer);
        };
    }, []);
    useEffect(function () {
        var eventDetailSubject_subscription = eventDetailSubject.subscribe(function (res) {
            event = res;
            console.log(res);
            if (res && res !== 'toggle') {
                console.log("Event Data >> ", res);
                console.log("Market Data >> ", marketMapping[res.eventId]);
                try {
                    var allOfIt = document.getElementsByClassName('multi-column-content');
                    for (var _i = 0, allOfIt_1 = allOfIt; _i < allOfIt_1.length; _i++) {
                        var allOfItElement = allOfIt_1[_i];
                        allOfItElement.classList.remove('active');
                    }
                    var listItem = document.getElementById("eventId_" + event.eventId);
                    if (listItem) {
                        listItem.classList.add('active');
                    }
                    //@ts-ignore
                    document.getElementsByClassName("game-details-section")[0].scrollTo(0, 0);
                }
                catch (e) {
                }
                setShowTabs(1);
                setShowMarketGroup('Tümü');
                eventDetailVisiblity.visible = true;
                forceUpdate();
            }
            else {
                event = null;
                eventDetailVisiblity.visible = false;
                forceUpdate();
            }
            // forceUpdate();
        });
        return function () {
            eventDetailSubject_subscription.unsubscribe();
            event = null;
        };
    }, []);
    if (event) {
        // fixme burada neden sadece futbol var
        var _islive = event.isLiveEvent ? event.sportSlug !== 'futbol' : event.bettingPhase;
        // console.log(event,'event')
        // statisticUrl = env.website_url + '/assets/new-widgets/' + event.sportSlug + '.html?matchId=' + event.broadageId + '&team1=' + event.eventParticipant[0].broadageId + '&isLive=' + _islive + '&team2=' + event.eventParticipant[1].broadageId;
        statisticUrl = '/assets/new-widgets/widget.html' + '?sportId=' + event.sportId + '&matchId=' + event.theSportsId;
        if (event.liveScoreStatus && event.matchPlayTime && event.currentScore) {
            var time_statuses = ["520", "521", "522", "523", "700"];
            if (time_statuses.includes(event.liveScoreStatus.toString())) {
                event.minute = event.matchPlayTime + '.dk';
            }
            else {
                event.minute = LiveScoreStatusType[Number(event.liveScoreStatus)];
            }
        }
        // eventMarkets = marketMapping[event.eventId].filter((el: EventMarket) =>{
        //   console.log(new Date(el.sellEndDate) , ServerDate() , new Date(el.sellBeginDate) ,  ServerDate())
        //   return el
        //   }
        // )
        eventMarkets = marketMapping[event.eventId].filter(function (el) {
            return (Number(el.channelWeb) === 1 && el.marketStatus < 2 && el.marketStatus !== 0 && new Date(el.sellEndDate) > ServerDate() && new Date(el.sellBeginDate) < ServerDate());
        });
        marketGroups = marketCategories[event.sportSlug];
        for (var _i = 0, eventMarkets_1 = eventMarkets; _i < eventMarkets_1.length; _i++) {
            var market = eventMarkets_1[_i];
            if (!eventMarketMapping[market.marketType + '-' + market.marketSubType]) {
                eventMarketMapping[market.marketType + '-' + market.marketSubType] = [];
            }
            eventMarketMapping[market.marketType + '-' + market.marketSubType].push(market);
        }
    }
    var isFirstMarketForOddsValue;
    var setFirst = function (market) {
        if (marketNamesMappingData[market.marketType + '-' + market.marketSubType] === undefined) {
            isFirstMarketForOddsValue = market.name;
        }
        else {
            isFirstMarketForOddsValue = market.marketType + '-' + market.marketSubType + (['2-7', '2-622'].includes(market.marketType + '-' + market.marketSubType) ? market.specialOddsValue : '');
        }
    };
    var getOdds = function (marketsList) {
        var availableMarkets = eventMarkets.filter(function (market) { return (marketsList.length === 0 || (marketsList.length > 0 && marketsList.includes(market.marketType + '-' + market.marketSubType))) && market.marketStatus < 2; });
        availableMarkets.map(function (market) {
            market.specialOddsValue = Number(market.specialOddsValue);
        });
        var sortedMarketList = _.orderBy(availableMarkets, ['marketTypePriority', 'specialOddsValue']);
        return sortedMarketList.map(function (market, marketKey) {
            return (_jsxs("div", __assign({ className: "sgm-market-g" }, { children: [isFirstMarketForOddsValue !== market.marketType + '-' + market.marketSubType ?
                        _jsxs("div", __assign({ className: "sgm-market-g-head-bc " }, { children: [_jsxs("div", __assign({ className: "sgm-market-head-info" }, { children: [_jsx("i", { className: "sgm-market-g-h-fav-icon-bc bc-i-favorite " }, void 0), _jsxs("p", __assign({ className: "sgm-market-g-h-title-bc " }, { children: [marketNamesMappingData[market.marketType + '-' + market.marketSubType] ? marketNamesMappingData[market.marketType + '-' + market.marketSubType].name : market.marketName, setFirst(market), ['2-7', '2-622'].includes(market.marketType + '-' + market.marketSubType) ?
                                                    _jsxs("span", { children: [" - ", market.specialOddsValue] }, void 0) : null] }), void 0)] }), void 0), _jsx("div", __assign({ className: "sgm-market-head-icons" }, { children: _jsx("i", { className: "sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up" }, void 0) }), void 0)] }), void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", __assign({ className: "sgm-market-g-item-bc column-".concat(market.marketOutcome.length > 3 ? '2' : '3') }, { children: _jsx(Odd, { event: event, showOutcomeName: true, market: market }, void 0) }), void 0)] }), "marketKey-" + marketKey));
        });
    };
    var _c = useForm(), register = _c.register, handleSubmit = _c.handleSubmit, reset = _c.reset;
    var onSubmit = function (data) {
        if (!inProgress.state) {
            inProgress.state = true;
            data['obj_id'] = event.eventId;
            sharePost(data).then(function (response) {
                inProgress.state = false;
                if (response.status) {
                    response.data.post.member = {
                        member_id: member.id,
                        photo: member.photo,
                        nick_name: member.nick_name,
                    };
                    event.comments.unshift(response.data.post);
                    commentUpdateSubject.next(Number(event.eventId));
                    reset({ comment: "" });
                }
                else {
                    modalServiceSubject.next({
                        title: 'Bilgilendirme!',
                        content: response.message,
                        confirm: {
                            sure: {
                                label: 'Giriş Yap',
                                class: 'btn-warning',
                                action: function () {
                                    navigate('/giris-yap');
                                },
                            },
                            cancel: {
                                label: 'Tamam'
                            }
                        }
                    });
                }
            }).catch(function (e) {
                console.log(e);
                inProgress.state = false;
            });
        }
    };
    var _d = useState(false), postCommentModal = _d[0], setPostCommentModal = _d[1];
    var openPostCommentModal = function (post) {
        if (!inProgress.state) {
            inProgress.state = true;
            getPostComment(post.post_id).then(function (comments) {
                inProgress.state = false;
                setPostCommentModal({ post: post, postComments: comments, });
            });
            inProgress.state = false;
        }
    };
    /*  const getStreamPlayer = async () => {
          const liveStreamContainer = document.getElementById('live-stream-container')
          return await getLiveStreamUrl(event).then((res: any) => {
              if (event.streamUrl && event.streamUrl.url || (res && res.hasOwnProperty('url'))) {
                  // @ts-ignore
                  liveStreamPlayer = new avvpl.setupPlayer({
                      id: "live-match-video-container",
                      autoplay: false,
                      allowFullScreen: false,
                      currentQuality: 50,
                  });
                  liveStreamPlayer.remove()

                  let config = {
                      id: "live-match-video-container",
                      autoplay: false,
                      allowFullScreen: innerWidth < 968 ? true : false,
                      currentQuality: 50,
                      streamUrl: event.streamUrl.url
                  };
                  // @ts-ignore
                  liveStreamPlayer = new avvpl.setupPlayer(config);
                  if (liveStreamContainer) {
                      liveStreamContainer.classList.remove('hide')
                      liveStreamContainer.classList.add('show')
                  }

                  return liveStreamPlayer

              } else if (res && res.error) {
                  try {
                      modalServiceSubject.next({
                          title: 'Hata!',
                          content: res.error.message,
                          confirm: {
                              cancel: {
                                  label: 'Tamam',
                              }
                          }
                      })
                  } catch (e: any) {
                      console.log(e)
                  }
              }
          })
      }
    */
    var closeDetail = function () {
        if ((isAndroid && Number(osVersion.split('.')[0]) >= 9) || isMacOs || isWindows ||
            (isIOS && Number(osVersion.split('.')[0]) >= 15)) {
            if (event) {
                if (sportSlug === 'duello') {
                    navigate(base + '/sports/duello/' + eventType + '/');
                }
                else {
                    navigate(base + '/sports/' + event.sportSlug + '/' + eventType + '/');
                }
            }
        }
        event = null;
        eventDetailVisiblity.visible = false;
        forceUpdate();
        closeEventDetail();
    };
    var statistic = statisticsMap[event === null || event === void 0 ? void 0 : event.eventId];
    // console.log(event);
    if (!event) {
        return _jsx(React.Fragment, {}, void 0);
    }
    if (isMobileView) {
        return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "live-page-sections-bc" }, { children: _jsxs("div", __assign({ className: "back-nav-bc", onClick: function () {
                            if (eventType == 'canli') {
                                navigate(base + '/sports/' + event.sportSlug + '/' + eventType + '/');
                            }
                            else {
                                navigate(base + '/sports/' + event.sportSlug + '/' + eventType + '/' + event.competitionId + '/');
                            }
                        } }, { children: [_jsx("i", { className: "back-nav-icon-bc bc-i-round-arrow-left" }, void 0), _jsx("span", __assign({ className: "back-nav-title-bc ellipsis" }, { children: event.competitionName }), void 0)] }), void 0) }), void 0), _jsxs("div", __assign({ className: "live-page-sections-bc" }, { children: [_jsx(EventDetailHeader, { event: event, navigate: navigate, closeDetail: closeDetail }, void 0), _jsx("div", __assign({ className: "sgm-market-search  " }, { children: _jsx("div", __assign({ className: "horizontal-sl-tab-container", "data-scroll-lock-scrollable": "" }, { children: _jsx("div", __assign({ className: "horizontal-sl-tab " }, { children: marketGroups.map(function (group, groupKey) {
                                        var marketCount = group.markets.filter(function (el) { return eventMarketMapping[el]; });
                                        if (group.name === 'Tümü') {
                                            return _jsx("div", __assign({ className: "horizontal-sl-tab-bc selected-underline ".concat(showMarketGroup === group.name ? 'active' : '', " "), "data-count": Object.entries(eventMarketMapping).length, onClick: function () {
                                                    setShowMarketGroup('Tümü');
                                                }, "data-badge": "", "data-id": "-1" }, { children: group.name }), groupKey);
                                        }
                                        else {
                                            return marketCount.length > 0 ? (_jsx("div", __assign({ className: "horizontal-sl-tab-bc selected-underline ".concat(showMarketGroup === group.name ? 'active' : '', " "), "data-count": marketCount.length, "data-badge": "", onClick: function () {
                                                    setShowMarketGroup(group.name);
                                                }, "data-id": "8" }, { children: group.name }), groupKey)) : _jsx("div", { style: { display: "none" } }, groupKey);
                                        }
                                    }) }), void 0) }), void 0) }), void 0), _jsxs("div", __assign({ className: "sgm-body-bc" }, { children: [_jsxs("div", __assign({ className: "sgm-markets-head-bc " }, { children: [_jsx("span", __assign({ className: "sgm-m-h-title-bc ellipsis" }, { children: "Marketler" }), void 0), _jsx("i", { className: "sgm-m-h-toggle-icon-bc bc-i-small-arrow-up" }, void 0)] }), void 0), _jsx("div", __assign({ style: { position: "relative" } }, { children: _jsx("div", __assign({ style: { width: "100%", height: "100%", position: "absolute", top: "0px" } }, { children: _jsx("div", __assign({ style: {
                                                boxSizing: "border-box",
                                                paddingTop: "0px",
                                                marginTop: "0px",
                                                paddingBottom: "75px"
                                            } }, { children: marketGroups.map(function (group, groupKey) {
                                                if (group.name === showMarketGroup) {
                                                    return _jsx("div", __assign({ style: { overflowAnchor: "none" } }, { children: getOdds(group.markets) }), "groupKey-" + groupKey);
                                                }
                                                else {
                                                    return _jsx("div", { style: { display: "none" } }, "groupKey-" + groupKey);
                                                }
                                            }) }), void 0) }), void 0) }), void 0)] }), void 0)] }), void 0)] }, void 0));
    }
    else {
        return (_jsx("div", __assign({ className: 'game-details-section' }, { children: _jsxs("div", __assign({ className: "live-page-sections-bc" }, { children: [_jsx(EventDetailHeader, { event: event, navigate: navigate, closeDetail: closeDetail }, void 0), _jsx("div", __assign({ className: "sgm-market-search  " }, { children: _jsxs("div", __assign({ tabIndex: 0, className: "horizontal-sl-tab" }, { children: [_jsx("i", { className: "horizontal-sl-arrow bc-i-small-arrow-left" }, void 0), _jsx("div", __assign({ className: "horizontal-sl-wheel", style: { transform: "translateX(0px)" } }, { children: marketGroups.map(function (group, groupKey) {
                                        var marketCount = group.markets.filter(function (el) { return eventMarketMapping[el]; });
                                        if (group.name === 'Tümü') {
                                            return _jsx("div", __assign({ className: "horizontal-sl-tab-bc selected-underline ".concat(showMarketGroup === group.name ? 'active' : '', " "), "data-count": Object.entries(eventMarketMapping).length, onClick: function () {
                                                    setShowMarketGroup('Tümü');
                                                }, "data-badge": "", "data-id": "-1" }, { children: group.name }), groupKey);
                                        }
                                        else {
                                            return marketCount.length > 0 ? (_jsx("div", __assign({ className: "horizontal-sl-tab-bc selected-underline ".concat(showMarketGroup === group.name ? 'active' : '', " "), "data-count": marketCount.length, "data-badge": "", onClick: function () {
                                                    setShowMarketGroup(group.name);
                                                }, "data-id": "8" }, { children: group.name }), groupKey)) : _jsx("div", { style: { display: "none" } }, groupKey);
                                        }
                                    }) }), void 0), _jsx("i", { className: "horizontal-sl-arrow bc-i-small-arrow-right" }, void 0)] }), void 0) }), void 0), _jsxs("div", __assign({ className: "sgm-body-bc" }, { children: [_jsxs("div", __assign({ className: "sgm-markets-head-bc " }, { children: [_jsx("span", __assign({ className: "sgm-m-h-title-bc ellipsis" }, { children: "Marketler" }), void 0), _jsx("i", { className: "sgm-m-h-toggle-icon-bc bc-i-small-arrow-up" }, void 0)] }), void 0), _jsx("div", __assign({ className: "" }, { children: _jsx("div", __assign({ style: { position: "relative" } }, { children: _jsx("div", __assign({ style: { width: "100%", height: "100%", position: "absolute", top: "0px" } }, { children: _jsx("div", __assign({ style: {
                                                boxSizing: "border-box",
                                                paddingTop: "0px",
                                                marginTop: "0px"
                                            } }, { children: marketGroups.map(function (group, groupKey) {
                                                if (group.name === showMarketGroup) {
                                                    return _jsx("div", __assign({ style: { overflowAnchor: "none" } }, { children: getOdds(group.markets) }), "groupKey-" + groupKey);
                                                }
                                                else {
                                                    return _jsx("div", { style: { display: "none" } }, "groupKey-" + groupKey);
                                                }
                                            }) }), void 0) }), void 0) }), void 0) }), void 0)] }), void 0)] }), void 0) }), void 0));
    }
}
function secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    var obj = {
        "h": String(hours).padStart(2, '0'),
        "m": String(minutes).padStart(2, '0'),
        "s": String(seconds).padStart(2, '0')
    };
    return obj;
}
var countDownTimer;
export function CountDown(props) {
    var _a = useState(), liveStreamCountDown = _a[0], setLiveStreamCountDown = _a[1];
    var today = ServerDate();
    var eventDate = new Date(props.eventDate);
    var getStreamPlayer = props.getStreamPlayer;
    var secoundsLeft = (eventDate.getTime() - today.getTime()) / 1000;
    useEffect(function () {
        setLiveStreamCountDown(secondsToTime(secoundsLeft));
        return function () {
            clearTimeout(countDownTimer);
            setLiveStreamCountDown(null);
            clearTimeout(countDownTimer);
        };
    }, [props.eventDate]);
    clearTimeout(countDownTimer);
    countDownTimer = setTimeout(function () {
        if (secoundsLeft <= 0) {
            clearTimeout(countDownTimer);
            setLiveStreamCountDown(null);
            getStreamPlayer();
        }
        setLiveStreamCountDown(secondsToTime(secoundsLeft));
    }, 1000);
    if (!liveStreamCountDown) {
        return _jsx(React.Fragment, {}, void 0);
    }
    return (_jsxs("div", __assign({ className: "count-down" }, { children: ["Canl\u0131 Ma\u00E7 Yay\u0131n\u0131 Heyecan\u0131na Kalan S\u00FCre", _jsxs("span", { children: [liveStreamCountDown.h, ":", liveStreamCountDown.m, ":", liveStreamCountDown.s] }, void 0)] }), void 0));
}
