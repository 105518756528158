export var HTTP_STATUS = {
    TOO_MANY_REQUEST: 429,
    LOOP_DETECTED: 508
};
export var Languages = {
    tr: 'TUR',
    en: 'ENG',
};
export var Flag = {
    tr: 'turkey',
    en: 'unitedkingdom',
};
